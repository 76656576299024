<div class='container'>
 <!--  how to use spinner in a html page: -->
  <!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-pulse" [fullScreen]="true"></ngx-spinner> -->

  <div class="row">
    <p class="exhi-artist">{{firstname}} {{lastname}}</p>
  </div>

  <div class="row">
    <p class="exhi-title">{{title_exhi}}</p>
  </div> 

  <div class="row row-cols-1 row-cols-md-2 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5">
    <div *ngFor="let item of exhibition_workofart_items">
      <app-worksofart-card 
        [workofart_item]="item" 
        [workofart_items]="exhibition_workofart_items"
        [isBookmarkVisible]="isBookmarkVisible" 
        [isRatethisVisible]="isRatethisVisible"
        [isRatebuttonVisible]="isRatebuttonVisible" 
        [isVerticallineVisible]="isVerticallineVisible"
        [isCheckboxVisible]="isCheckboxVisible" 
        [isSettings1Visible]="isSettings1Visible"
        [isSettings2Visible]="isSettings2Visible" 
        [isWorkStatusVisible]="isWorkStatusVisible"
        [isStatus1Visible]="isStatus1Visible" 
        [isStatus2Visible]="isStatus2Visible"
        [isStatus3Visible]="isStatus3Visible" 
        [isBookMarkChecked]="item.bookmark_selected"
        [isUsersOwnProfile_Rating2]="isUsersOwnProfile_Rating2"
        (RatingUpdate)=RatingUpdate($event) 
        (ModalUpdated)=ModalCompleted($event)
        (BookmarkUpdated)=BookmarkUpdated($event)>
      </app-worksofart-card>
    </div>
  </div>

  <div class="row row_museum_row">
      <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <button [routerLink]="['/e-exhibitions']" class="exhibition_btn_back" type="button">{{'Back to digital exhibitions' | translate}}</button>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <button *ngIf="authenticated_user" (click)="goToProfile()" class="exhibition_btn_back_profile" type="button">{{'Back to my profile' | translate}}</button>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <button *ngIf="isThisExhib" (click)="ExhibitionVR()" class="exhibition_btn_web_museum" type="button">{{'WEB 3D MUSEUM' | translate}}</button>
        <button *ngIf="isThisExhib2" (click)="ExhibitionVR2()" class="exhibition_btn_web_museum" type="button">{{'WEB 3D MUSEUM' | translate}}</button>
        <button *ngIf="isThisExhib3" (click)="ExhibitionVR3()" class="exhibition_btn_web_museum" type="button">{{'WEB 3D MUSEUM' | translate}}</button>
      </div>
  </div>

  <!-- <div class="row museum_row">
    <button (click)="ExhibitionVR()" class="exhibition_btn_web_museum ml-auto" type="button">{{'WEB 3D MUSEUM' | translate}}</button>
  </div> -->
</div>