<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet">
<!-- <link rel="stylesheet" href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" /> -->

<div class='container'>
    <div class="row" *ngIf="isUsersOwnProfile">
        <div class="column">
            <button class="add_an_artwork_profile" [routerLink]="['/addanartwork']"><i class="fa fa-plus"></i>  {{'Add an artwork' | translate}}</button>
            <button *ngIf="isForAdd" class="create_e_exhibition_profile" [routerLink]="['/set-exhibition']"><i class="fa fa-plus"></i>  {{'Create digital exhibition' | translate}}</button>
            <button class="delete_profile" (click)="openModalConfirmDelete()">{{'Account deletion' | translate}}</button>
        </div>
    </div>
    <div class="artist-name">
        <p class="name">{{firstname}} {{lastname}}</p>  
    </div>
    <div class="worksofart">
        <h3 class="titles">{{'Artworks' | translate}}</h3>
    </div>
     <br>
     <div class="row row-cols-1 row-cols-md-2 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5"> <!-- row row-cols-1 row-cols-md-5 g-3 -->
        <div *ngFor="let item of profile_workofart_items">
          <app-worksofart-card 
            [workofart_item]="item"
            [workofart_items]="profile_workofart_items"
            [isBookmarkVisible]= "isBookmarkVisible"
            [isRatethisVisible]="isRatethisVisible"
            [isRatebuttonVisible]="isRatebuttonVisible"
            [isVerticallineVisible]="isVerticallineVisible" 
            [isCheckboxVisible]="isCheckboxVisible"
            [isSettings1Visible]="isSettings1Visible"
            [isSettings2Visible]="isSettings2Visible" 
            (RatingUpdate)= RatingUpdate($event)
            (BookmarkUpdated)=BookmarkUpdated($event)
            [isWorkStatusVisible]="isWorkStatusVisible"
            [isBookMarkChecked]="item.bookmark_selected"
            [isStatus1Visible]="isStatus1Visible"
            [isStatus2Visible]="isStatus2Visible"
            [isStatus3Visible]="isStatus3Visible"
            [isUsersOwnProfile_Rating]="isUsersOwnProfile_Rating"
            [isUsersOwnProfile_Rating2]="isUsersOwnProfile_Rating2"
            [isPreview1Visible]="isPreview1Visible"
            [isPreview3Visible]="isPreview3Visible"
            ></app-worksofart-card>
        </div>
    </div>
    <div class="e-exhibition">
        <h3 class="titles">{{'Digital Exhibition' | translate}}</h3>
    </div>
    <br>
    <div class="row row-cols-1 row-cols-md-3 g-2">
        <div *ngFor="let item of exhibition_items">
          <app-e-exhibitions-card [exhibition_item]="item"
          [isSettings1Visible]="isSettings1Visible"
          [isSettings2VisibleExhib]="isSettings2VisibleExhib"
          [isStatusExhibition1Visible]="isStatusExhibition1Visible"
          [isStatusExhibition2Visible]="isStatusExhibition2Visible"
          [isStatusExhibition3Visible]="isStatusExhibition3Visible"></app-e-exhibitions-card>
        </div>
    </div>
    <div class="favourites">
        <h3 class="titles" *ngIf="isUsersOwnProfile">{{'Favourites' | translate}}</h3>
    </div>
    <br>
    <div class="row row-cols-1 row-cols-md-2 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5">
        <div *ngFor="let item of favourite_workofart">
          <app-worksofart-card  style="margin-right:20px;" 
                                [workofart_item]="item"
                                [workofart_items]="favourite_workofart"
                                [isSettings2Visible]="isSettings2Visible"
                                [isStatus1Visible]="isStatus1Visible"
                                [isStatus2Visible]="isStatus2Visible"
                                [isStatus3Visible]="isStatus3Visible"
                                [isStatus3Visible]="isStatus3Visible"
                                [isRatethisVisible]="isRatethisVisible" 
                                [isRatebuttonVisible]="isRatebuttonVisible" 
                                [isVerticallineVisible]="isVerticallineVisible" 
                                [isCheckboxVisible]="isCheckboxVisible" 
                                [isBookMarkChecked]="item.bookmark_selected"
                                [isUsersOwnProfile_Rating]="isUsersOwnProfile_Rating"
                                [isPreview1Visible]="isPreview1Visible"
                                [isPreview3Visible]="isPreview3Visible"
                                (SelectedFavouriteId)= onCheckboxSelectedFavourite($event)
        ></app-worksofart-card>
        </div>
    </div>
</div>



