<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div class='container'>
    <div class="row">
        <div class="col">
            <button class="add_an_artwork" [routerLink]="['/addanartwork']"><i class="fa fa-plus"></i> {{'Add an artwork' | translate}}</button>
            <button class="create_e_exhibition"><i class="fa fa-plus"></i> {{'Create digital exhibition' | translate}}</button>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <p class="name">{{firstname}} {{lastname}}</p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <h3 class="header" *ngIf="isForAdd">{{'Create your digital exhibition' | translate}}</h3>
            <h3 class="header" *ngIf="isForEdit">{{'Edit your digital exhibition' | translate }}</h3>
        </div>
    </div>

    <div class="row">
        <p class="exhi-title">{{'Title:' | translate}} {{exhibition_obj.title}}</p>
    </div>

    <div class="row">
        <p class="create_exhibition_steps"><span style="font-weight: bold;">{{'Step 6' | translate}}</span> {{'of 6: Digital exhibition VR' | translate}}</p>
    </div>

    <div class="row">
        <div class="column column_image">
            <p class="paragaraph_info">{{'In the image below you see the floor plan of the room for the digital exhibition.' | translate}}</p>
        </div>
    </div>

    <div class="image_katopsi">
        <img class="photovr" src="assets/images/katopsi_signed.jpg"> <!-- src="assets/images/katopsi3.jpg" -->
    </div>

    <div class="row">
        <div class="column column_indicate">
            <p class="paragaraph_info"><a style="font-style: italic; color:black">{{'(*Optionally) ' | translate}}</a>{{'Please indicate where you would like your digital exhibition works to be displayed according to the floor plan of the room above.' | translate}}</p>
        </div>
    </div>
    
    <br>
    <br>
    <br>
    <form [formGroup]="exhibitionStepSixForm">
        <div class="row">
            <div class="col">
                <div class="row row-cols-1 row-cols-md-1 g-1">
                    <div *ngFor="let item of workofart_items">
                        <app-worksofart-card [workofart_item]="item"
                        [workofart_items]="workofart_items" 
                        [isCheckboxSelected]="item.checkbox_selected"
                        [isBookmarkVisible]="isBookmarkVisible"
                        [isRatethisVisible]="isRatethisVisible" 
                        [isRatebuttonVisible]="isRatebuttonVisible" 
                        [isAvgRatingVisible]="isAvgRatingVisible" 
                        [isVerticallineVisible]="isVerticallineVisible" 
                        [isSettings1Visible]="isSettings1Visible"
                        [isSettings2Visible]="isSettings2Visible"
                        [isBookMarkChecked]="item.bookmark_selected"
                        [isRatingsCount]="isRatingsCount"
                        [isDateVisible]="isDateVisible"
                        [isTagsVisible]="isTagsVisible"
                        [isNameVisible]="isNameVisible"
                        [isCheckboxVisible]="isCheckboxVisible"
                        (SelectedWorkid)=onCheckboxSelected($event)
                        [isUsersOwnProfile_Rating]="isUsersOwnProfile_Rating"
                        [isUsersOwnProfile_Rating2]="isUsersOwnProfile_Rating2"
                        ></app-worksofart-card>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="row row-cols-1 row-cols-md-1 g-1">
                    <div *ngFor="let item of workofart_items" class="select_css_positions">
                        <p class="select_positions_paragraph">{{'Select position for artwork:' | translate}} <span style="font-weight: bold; font-size: 17px;">{{item.title}}</span></p>
                        <select [(ngModel)]="workofart_positions[item._id]" style="cursor: pointer; width: 40%; border-radius: 5px; height: 35px;" formControlName="positions">
                            <option class="first_option" [ngValue]="" disabled selected hidden>{{'Position:' | translate}}</option>
                            <option class="dropdown-item" [ngValue]="n.code" *ngFor="let n of positionBody">{{n.code}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row_back_next">
            <div class="col col_navigate">
                <button (click)="GoBackToStepFive()" class="exhibition_btn_back" type="button">{{'Back' | translate}}</button>
                <button (click)="openModalConfirm()" class="exhibition_btn_next" type="button" [id]="exhibition._id">{{'Save' | translate}}</button>
            </div>
        </div>

        <div class="row row_cancel">
            <div class="ml-auto">
                <a [routerLink]="['/profile']" class="exhibition_cancel" type="button">{{'Cancel' | translate}}</a>
            </div>
        </div>
        
    </form>

</div>
