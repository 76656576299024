<div class="container">
     <div class="image">
        <img src={{imageUrl}} alt="..." width=100% height=100% class="full-image">
    </div>

    <div class="button">
        <button (click)="OnBack()" class="back">{{'Close' | translate}}</button>
    </div>
</div>

