import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-exhibition-curator',
  templateUrl: './edit-exhibition-curator.component.html',
  styleUrls: ['./edit-exhibition-curator.component.css']
})
export class EditExhibitionCuratorComponent implements OnInit {
  firstname: string = "";
  lastname: string = "";
  exhibition_user_id: string = "";
  exhibition_id: string = "";

  user_id: string = "";

  exhibition: any = {
    title: '',
    status: ''
  };

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: boolean = false;

  constructor(private router: Router,
    private toastr: ToastrService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const exhibitionID = params.get("id");
      this.exhibition_id = exhibitionID;

      this.subscription = this.dataLayerService.currentUser.subscribe(
        user => {
          if (user.length > 0) {
            this.authenticated_user = true;

            this.spinnerService.show();

            this.userService.getSpecificExhibition(this.exhibition_id).pipe().subscribe({
              next: data => {
                this.exhibition.title = data["_items"][0].title;
                this.exhibition.status = data["_items"][0].status;
                this.exhibition_user_id = data["_items"][0].owner_id;
                this.firstname = data["_items"][0].owner_id.firstname;
                this.lastname = data["_items"][0].owner_id.lastname;

                const exhibitionIDVR = data["_items"][0]._id;
                this.exhibition_id = exhibitionIDVR;

                /* console.log(data); */
                /* console.log(this.exhibition.title); */
                /* console.log(this.exhibition.status); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
          }
        });
    });
  }

  onEditExhibitionSubmitApprove(userForm: NgForm) {
    // Request body
    const edit_res = {
      title: this.exhibition.title,
      status: "APPROVED"
    };

    // Edit Work Request
    this.userService.editExhibition(this.exhibition_id, edit_res)
      .pipe()
      .subscribe({
        next: data => {
          /* console.log(edit_res); */
          this.translate.get('Digital Exhibition approved!').subscribe(res => {
            /* console.log(res); */
            this.toastr.success(res);
          });

          this.router.navigate(['/profile-curator']);
          this.dataLayerService.restoreStateOnRefresh();
        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
        }
      });
  }

  onEditExhibitionSubmitReject(userForm: NgForm) {
    // Request body
    const edit_res = {
      title: this.exhibition.title,
      status: "REJECTED"
    };

      // Edit Work Request
      this.userService.editExhibition(this.exhibition_id, edit_res)
        .pipe()
        .subscribe({
          next: data => {
            /* console.log(edit_res); */
            this.translate.get('Digital Exhibition rejected!').subscribe(res => {
              /* console.log(res); */
              this.toastr.success(res);
            });

            this.router.navigate(['/profile-curator']);
            this.dataLayerService.restoreStateOnRefresh();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
  }
}
