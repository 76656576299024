import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-forgot-password2',
  templateUrl: './forgot-password2.component.html',
  styleUrls: ['./forgot-password2.component.css']
})
export class ForgotPassword2Component implements OnInit {
  @Input('artist_email') public artist_email: any = "";

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;

  artist: any = {
    email: '',
    password: '',
    verification_code: ''
  };

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  _id: any;
  role: string = "";

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  constructor(
    private toastr: ToastrService,
    private dataLayerService: DataLayerService,
    public modalService: BsModalService,
    private authService: AuthService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.authenticated_user = true;
        this.firstname = user[0].firstname;
        this.lastname = user[0].lastname;
        this.user_id = user[0]._id;
        this.role = user[0].role;
      }
    });

    /*  console.log("email που περναει στο forgot modal:", this.artist_email); */
  }

  openModalLogin() {
    this.modalRef = this.modalService.show(LoginComponent);
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  onSubmit(userForm: NgForm) {
    // Request body
    const forgotPass_res = {
      email: this.artist.email,
      password: this.artist.password,
      verification_code: this.artist.verification_code
    };

    if (!userForm.form.valid) {
      this.translate.get('Please fill in all fields to continue').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    }
    else {
      // Forgot Password2 Request
      this.authService.forgotPassword2(forgotPass_res)
        .pipe()
        .subscribe({
          next: data => {
            /* console.log("!!!!", forgotPass_res); */
            this.translate.get('Now you can login').subscribe(res => {
              /* console.log(res); */
              this.toastr.success(res);
            });

            this.triggerCloseModalEvent();

            this.openModalLogin();
          },
          error: error => {
            if (error.status === 401) {
              this.translate.get('Invalid email address or verification code').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }

            else if (error.status === 422) {
              this.translate.get('This email address is not unique').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
            else {
              this.translate.get('Something went wrong. Please try again.').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
          }
        });
    }
  }

  /*
 * Triger close event to the parent component
 */
  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }
}
