import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DataLayerService } from '../services/data-layer.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-parent-create-exhibition',
  templateUrl: './parent-create-exhibition.component.html',
  styleUrls: ['./parent-create-exhibition.component.css']
})
export class ParentCreateExhibitionComponent implements OnInit {
  @Input('exhibition_obj') public exhibition_obj: any = [];
  @Input('isForEdit') public isForEdit: boolean = false;

  public current_step: number = 1;

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  _id: any;

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  constructor(
    public authService: AuthService,
    private dataLayerService: DataLayerService,
    public toastr: ToastrService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.firstname = user[0].firstname;
          this.lastname = user[0].lastname;
          this.user_id = user[0]._id;
          this.exhibition_obj.owner_id = this.user_id;
        }
      });
  }

  onPreviousStepSelection(eventData: { step: number }) {
    this.current_step = eventData.step;
  }

  onTitleAdded(eventData: { title: string }) {
    // Got event from child
    this.exhibition_obj.title = eventData.title;

    // Change current step
    this.current_step = this.current_step + 1;
  }

  onWorksAdded(eventData: { works: [] }) {
    let arr_temp = [];

    if (eventData.works.length > 0) {
      for (let work of eventData.works) {
        arr_temp.push(work);
      }
      this.exhibition_obj.works = arr_temp;
    }
    this.current_step = this.current_step + 1;
  }

  onPositionAdded(eventData: { positions: [] }) {
    this.exhibition_obj.positions = eventData.positions;
  }
}
