<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<!-- <link rel="stylesheet" href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" /> -->

<div class='container'>
<div class="curator-name">
    <!-- <p *ngIf="isNameVisible" class="name">{{firstname}} {{lastname}}</p> -->
</div>

<h1 class="curatorsdashboard">{{'Curator’s dashboard' | translate}}</h1>

<h3 class="worksofart">{{'Artworks' | translate}}</h3>

<div class="row row_filter">
    <div class="column">
        <div class="mb-3">
            <select (change)="changeWorkFilter($event)" class="selectsortdate">
                <option [value]="n.name" *ngFor="let n of workBody">{{n.name | translate}}</option>
            </select>
            
            <select (change)="changeWorkStatus($event)" class="selecdiscoverartists" >
                <option [value]="n.name" *ngFor="let n of statusBody">{{n.name | translate}}</option>
            </select>
        </div>
    </div>
</div>
<br>
<div class="row row-cols-1 row-cols-md-2 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5">
    <div *ngFor="let item of workofart_items">
        <app-worksofart-card [workofart_item]="item"
          [workofart_items]="workofart_items"
          [isBookmarkVisible]="isBookmarkVisible"
          [isRatethisVisible]="isRatethisVisible"
          [isRatebuttonVisible]="isRatebuttonVisible"
          [isVerticallineVisible]="isVerticallineVisible"
          [isAvgRatingVisible]="isAvgRatingVisible"
          [isStarRatingVisible]="isStarRatingVisible"
          [isCheckboxVisible]="isCheckboxVisible"
          [isBookMarkChecked]="isBookMarkChecked"
          [isSettings1Visible]="isSettings1Visible"
          [isSettings2Visible]="isSettings2Visible"
          [isWorkStatusVisible]="isWorkStatusVisible"
          [isStatus1Visible]="isStatus1Visible"
          [isStatus2Visible]="isStatus2Visible"
          [isStatus3Visible]="isStatus3Visible"
          [isPreview1Visible]="isPreview1Visible"
          [isPreview4Visible]="isPreview4Visible"
          (UpdateStatusWork)= UpdateStatusWork($event)
          (RatingUpdate)= RatingUpdate($event)
          ></app-worksofart-card>
    </div>
</div>


<h1 class="exhibition">{{'Digital Exhibitions' | translate}}</h1>
<div class="row" style="margin-bottom: 20px;">
    <div class="column">
        <div class="mb-3">
            <select (change)="changeExhibitionFilter($event)" class="selectsortdate">
                <option [value]="n.name" *ngFor="let n of exhibitionBody">{{n.name | translate}}</option>
            </select>
            <select (change)="changeExhibitionStatus($event)" class="selecdiscoverartists">
                <option [value]="n.name" *ngFor="let n of statusBody">{{n.name | translate}}</option>
            </select>
        </div>
    </div>
</div>
<br>
  <div class="row row-cols-1 row-cols-md-2 g-3 row-cols-sm-1 row-cols-lg-2 row-cols-xl-3">
    <div *ngFor="let item of exhibition_items">
      <app-e-exhibitions-card
      [exhibition_item]="item"
      [isSettings1Visible]="isSettings1Visible"
      [isSettings2VisibleExhib]="isSettings2VisibleExhib"
      [isStatusExhibition1Visible]="isStatusExhibition1Visible"
      [isStatusExhibition2Visible]="isStatusExhibition2Visible"
      [isStatusExhibition3Visible]="isStatusExhibition3Visible"
      (UpdateStatusExhibition)= UpdateStatusExhibition($event)
      ></app-e-exhibitions-card>
    </div>
  </div>
</div>

