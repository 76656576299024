import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { DataLayerService } from '../services/data-layer.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PreviewWorkofartComponent } from '../preview-workofart/preview-workofart.component';
import { PreviewFavouritesProfileComponent } from '../preview-favourites-profile/preview-favourites-profile.component';
import { PreviewCuratorComponent } from '../preview-curator/preview-curator.component';
import { DeleteWorkComponent } from '../delete-work/delete-work.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DeleteProfileUserComponent } from '../delete-profile-user/delete-profile-user.component';
import { DeleteProfileCuratorComponent } from '../delete-profile-curator/delete-profile-curator.component';

@Component({
  selector: 'app-worksofart-card',
  templateUrl: './worksofart-card.component.html',
  styleUrls: ['./worksofart-card.component.css']
})
export class WorksofartCardComponent implements OnInit {
  @Input('workofart_item') public workofart_item: any = [];
  @Input('workofart_items') public workofart_items: any = [];
  @Input('isBookmarkVisible') public isBookmarkVisible: boolean = true;
  @Input('isRatethisVisible') public isRatethisVisible: boolean = true;
  @Input('isRatebuttonVisible') public isRatebuttonVisible: boolean = true;
  @Input('isVerticallineVisible') public isVerticallineVisible: boolean = true;
  @Input('isAvgRatingVisible') public isAvgRatingVisible: boolean = true;
  @Input('isStarRatingVisible') public isStarRatingVisible: boolean = true;
  @Input('isCheckboxVisible') public isCheckboxVisible: boolean = true;
  @Input('isSettings1Visible') public isSettings1Visible: boolean = false;
  @Input('isSettings2Visible') public isSettings2Visible: boolean = false;
  @Input('isNextVisible') public isNextVisible: boolean = true;
  @Input('isBackVisible') public isBackVisible: boolean = true;
  @Input('isCancelVisible') public isCancelVisible: boolean = true;
  @Input('isStatus1Visible') public isStatus1Visible: boolean = true;
  @Input('isStatus2Visible') public isStatus2Visible: boolean = true;
  @Input('isStatus3Visible') public isStatus3Visible: boolean = true;
  @Input('isWorkStatusVisible') public isWorkStatusVisible: boolean = false;
  @Input('isCheckboxSelected') public isCheckboxSelected: boolean = false;
  @Input('isBookMarkChecked') public isBookMarkChecked: boolean = false;
  @Input('isUsersOwnProfile_Rating') public isUsersOwnProfile_Rating: boolean = false;
  @Input('isUsersOwnProfile_Rating2') public isUsersOwnProfile_Rating2: boolean = true;
  @Input('isRatingsCount') public isRatingsCount: boolean = true;
  @Input('isDateVisible') public isDateVisible: boolean = true;
  @Input('isTagsVisible') public isTagsVisible: boolean = true;
  @Input('isNameVisible') public isNameVisible: boolean = true;
  @Input('isPreview1Visible') public isPreview1Visible: boolean = false;
  @Input('isPreview3Visible') public isPreview3Visible: boolean = true;
  @Input('isPreview4Visible') public isPreview4Visible: boolean = false;

  @Output('SelectedWorkid') public SelectedWorkid = new EventEmitter<{ is_selected: string }>();
  @Output('SelectedFavouriteId') public SelectedFavouriteId = new EventEmitter<{ is_selected_fav: string }>();
  @Output('RemoveWorkId') public RemoveWorkId = new EventEmitter<{ remove_work: string }>();
  @Output('UpdateStatusWork') public UpdateStatusWork = new EventEmitter<{ status_update: any }>();
  @Output('RatingUpdate') public RatingUpdate = new EventEmitter<{ rating_update: any }>();
  @Output('ModalUpdated') public ModalUpdated = new EventEmitter<{ modal_updated: boolean }>();
  @Output('BookmarkUpdated') public BookmarkUpdated = new EventEmitter<{ bookmark_updated: any }>();

  public event: EventEmitter<any> = new EventEmitter();

  public imageUrl: string;

  public upload_date: string = "";

  work: any = {
    owner_id: '',
    _id: ''
  };

  modalRef?: BsModalRef;
  bsModalRef: BsModalRef;

  firstname: string = "";
  lastname: string = "";

  user_id: string = "";
  _id: string = "";
  role: string = "";

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: boolean = false;
  public approved_works_info: any = [];
  public check_rate: boolean = false;

  rating: number;

  favourite_id: string;

  public ratingStarsForm: FormGroup;
  public checkboxForm: FormGroup;
  public bookmarkForm: FormGroup;

  public isapproved: boolean = false;
  public isrejected: boolean = false;
  public isaccountdelete: boolean = false;

  constructor(private router: Router,
    private toastr: ToastrService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private fb: FormBuilder,
    public translate: TranslateService,
    public modalService: BsModalService) { }

  ngOnInit(): void {
    this.imageUrl = 'data:image/gif;base64,' + this.workofart_item.thumbnail;

    const created_dt = new Date(this.workofart_item._created);
    this.upload_date = created_dt.toLocaleDateString();

    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.authenticated_user = true;
        this.firstname = user[0].firstname;
        this.lastname = user[0].lastname;
        this.user_id = user[0]._id;
        this.work.owner_id = this.user_id;
        this.approved_works_info = user[0].approved_works;
        this.role = user[0].role;

        if (user[0]['role'] === 'curator') {
          this.isUsersOwnProfile_Rating2 = false;
          this.isSettings2Visible = true;
          this.isBookmarkVisible = false;
          this.isaccountdelete = true;
        }

        if (this.workofart_item.status === 'PENDING') {
          this.isStatus1Visible = true;
        }
        else if (this.workofart_item.status === 'REJECTED') {
          this.isStatus3Visible = true;
        }
        else {
          this.isStatus2Visible = true;
        }
      }

      this.rating = 0;
      this.ratingStarsForm = this.fb.group({
        rating: [Math.floor(this.workofart_item.avg_rating), Validators.compose([Validators.required])]
      });

      this.checkboxForm = this.fb.group({
        work_selected: [this.isCheckboxSelected, Validators.compose([Validators.required])]
      });

      this.checkboxForm.valueChanges.subscribe(val => {
        /* console.log("checkbox : ", this.workofart_item._id); */
        this.SelectedWorkid.emit({ is_selected: this.workofart_item._id });
      })


      this.bookmarkForm = this.fb.group({
        bookmark: [this.workofart_item.bookmark_selected, Validators.compose([Validators.required])]
      });

      this.bookmarkForm.valueChanges.subscribe(value => {
        if (value.bookmark) {
          this.putInFavourites()
        }
        else {
          this.userService.getFavouriteId(this.user_id, this.workofart_item._id).pipe().subscribe({
            next: data => {
              /* console.log("Successful response Favourite: ", data); */

              const favourite_id = data["_items"][0]._id;
              const work_id = data["_items"][0].work;

              /* console.log("Successful response Favourite ID: ", favourite_id); */
              this.removeFavourite(favourite_id, work_id);
            },
            error: error => {
              /* console.log("Handle Error: ", error); */
              // Handle Error Here
            }
          });
        }

      });

      if (this.workofart_item.status === "APPROVED") {
        this.isapproved = false
        this.isrejected = false
      }
      else if (this.workofart_item.status === "REJECTED") {
        this.isrejected = false
        this.isapproved = false
      }
      else {
        this.isrejected = true
        this.isapproved = true
      }

      /* console.log("all works of arts in each card: ", this.workofart_items); */

    });
  }

  putInFavourites() {
    const favouriteBody = {
      owner_id: this.user_id,
      work: this.workofart_item._id
    }
    this.userService.postFavourites(favouriteBody).pipe().subscribe({
      next: data => {
        /* console.log("Successful response: ", data); */
        this.BookmarkUpdated.emit({ bookmark_updated: favouriteBody });

        this.translate.get('Added to favourites!').subscribe(res => {
          /* console.log(res); */
          this.toastr.success(res);
        });
      },
      error: error => {
        /*   console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    })
  }

  removeFavourite(favourite_id: string, work_id: string) {
    // Remove from back-end
    this.userService.deleteFavourite(favourite_id).pipe().subscribe({
      next: data => {
        this.SelectedFavouriteId.emit({ is_selected_fav: work_id });
        this.BookmarkUpdated.emit({ bookmark_updated: work_id });

        this.translate.get('Removed from favourites!').subscribe(res => {
          /* console.log(res); */
          this.toastr.success(res);
        });
      },
      error: error => {
        /*  console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    })
  }

  // REMOVE WORK CALL at delete-work component
  /*  removeWork(work_id: string) {
     this.userService.deleteWork(work_id).pipe().subscribe({
       next: data => {
         console.log("Successful response: ", data);
         this.RemoveWorkId.emit({ remove_work: work_id });
 
         this.translate.get('Artwork removed!').subscribe(res => {
           console.log(res);
           this.toastr.success(res);
         });
       },
       error: error => {
         console.log("Handle Error: ", error);
         // Handle Error Here
       }
     });
   } */

  openModalConfirmDelete() {
    const initialState = {
      workofart_item: this.workofart_item,
      workofart_item_id: this.workofart_item._id
    };
    this.modalRef = this.modalService.show(DeleteWorkComponent, { initialState });
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  openModalConfirmDeleteAccount() {
    const initialState = {
      owner_id_user: this.workofart_item.owner_id._id
    };

    if (this.role === 'artist') {
      this.modalRef = this.modalService.show(DeleteProfileUserComponent, { initialState });
      this.event.emit('CLOSE');

      this.modalRef.content.event.subscribe(res => {
        this.modalRef.hide();
      });
    }
    if (this.role === 'curator') {
      this.modalRef = this.modalService.show(DeleteProfileCuratorComponent, { initialState });
      this.event.emit('CLOSE');

      this.modalRef.content.event.subscribe(res => {
        this.modalRef.hide();
      });
    }
  }

  previewWorkEdit() {
    this.router.navigate(['/edit-work-curator/' + this.workofart_item._id]);
  }

  approveWork(work_id: string) {
    const statusBody = {
      status: "APPROVED"
    }

    this.userService.statusWork(work_id, statusBody).pipe().subscribe({
      next: data => {
        /* console.log("Successful response: ", data); */
        this.UpdateStatusWork.emit({ status_update: statusBody });

        this.translate.get('Artwork approved!').subscribe(res => {
          /* console.log(res); */
          this.toastr.success(res);
        });
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });
  }

  rejectWork(work_id: string) {
    const statusBody = {
      status: "REJECTED"
    }

    this.userService.statusWork(work_id, statusBody).pipe().subscribe({
      next: data => {
        /* console.log("Successful response: ", data); */
        this.UpdateStatusWork.emit({ status_update: statusBody });

        this.translate.get('Artwork rejected!').subscribe(res => {
          /* console.log(res); */
          this.toastr.success(res);
        });
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });
  }

  submitRating() {
    const ratingBodyUsers = {
      work: this.workofart_item._id,
      rating: this.ratingStarsForm.value.rating,
      owner_id: this.user_id
    }

    const ratingBody = {
      work: this.workofart_item._id,
      rating: this.ratingStarsForm.value.rating
    }

    // submit rating for connected users
    if (this.authenticated_user && this.role === 'artist') {
      if (this.approved_works_info.includes(ratingBodyUsers.work)) {
        this.check_rate = true;
      }

      if (!this.check_rate) {
        this.userService.postRatingUsers(ratingBodyUsers).pipe().subscribe({
          next: data => {
            /* console.log("Successful response: ", data); */
            this.RatingUpdate.emit({ rating_update: ratingBodyUsers });

            this.translate.get('Artwork was rated!').subscribe(res => {
              /* console.log(res); */
              this.toastr.success(res);
            });

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
            if (error.status === 422 && !this.check_rate) {
              this.translate.get('You have already rated this work').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
          }
        });
      }

      if (this.check_rate) {
        this.translate.get('Υou cannot rate your own artwork').subscribe(res => {
          /* console.log(res); */
          this.toastr.error(res);
        });
      }
    }

    // submit rating for curator
    else if (this.authenticated_user && this.role === 'curator') {
      this.userService.postRatingUsers(ratingBodyUsers).pipe().subscribe({
        next: data => {
          /* console.log("Successful response: ", data); */
          this.RatingUpdate.emit({ rating_update: ratingBodyUsers });

          this.translate.get('Artwork was rated!').subscribe(res => {
            /*  console.log(res); */
            this.toastr.success(res);
          });

        },
        error: error => {
          /*  console.log("Handle Error: ", error); */
          // Handle Error Here
          if (error.status === 422 && !this.check_rate) {
            this.translate.get('You have already rated this work').subscribe(res => {
              /* console.log(res); */
              this.toastr.error(res);
            });
          }
        }
      });
    }

    // submit rating for anonymous users
    else {
      this.userService.postRating(ratingBody).pipe().subscribe({
        next: data => {
          /* console.log("Successful response: ", data); */
          this.RatingUpdate.emit({ rating_update: ratingBody });

          this.translate.get('Artwork was rated!').subscribe(res => {
            /* console.log(res); */
            this.toastr.success(res);
          });
        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
          if (error.status === 422) {
            this.translate.get('You have already rated this work').subscribe(res => {
              /* console.log(res); */
              this.toastr.error(res);
            });
          }
        }
      });
    }
  }

  openModalPreview() {
    /*  this.router.navigate(['/worksofart/' + this.workofart_item._id]); */

    const initialState = {
      workofart_item: this.workofart_item,
      workofart_items: this.workofart_items,
      isBookMarkChecked: this.isBookMarkChecked,
      isAvgRatingVisible: this.isAvgRatingVisible,
      isStarRatingVisible: this.isStarRatingVisible,
      isVerticallineVisible: this.isVerticallineVisible,
      isRatebuttonVisible: this.isRatebuttonVisible,
      isRatethisVisible: this.isRatethisVisible,
      isBookmarkVisible: this.isBookmarkVisible
    };

    let modal = this.bsModalRef = this.modalService.show(PreviewWorkofartComponent, { class: 'modal-dialog-centered', initialState });

    modal.setClass('modal-custom-style');

    this.bsModalRef.content.event.subscribe(res => {

      // Close modal
      this.modalService.hide();

      //stelnv piso ena event modal complited
      this.ModalUpdated.emit({ modal_updated: true });

    });
  }

  openModalPreview3() {
    const initialState = {
      workofart_item: this.workofart_item,
      workofart_items: this.workofart_items,
      isBookMarkChecked: this.isBookMarkChecked,
      isAvgRatingVisible: this.isAvgRatingVisible,
      isStarRatingVisible: this.isStarRatingVisible,
      isVerticallineVisible: this.isVerticallineVisible,
      isRatebuttonVisible: this.isRatebuttonVisible,
      isRatethisVisible: this.isRatethisVisible,
      isBookmarkVisible: this.isBookmarkVisible
    };

    let modal = this.bsModalRef = this.modalService.show(PreviewFavouritesProfileComponent, { class: 'modal-dialog-centered', initialState });

    modal.setClass('modal-custom-style');

    this.bsModalRef.content.event.subscribe(res => {

      // Close modal
      this.modalService.hide();

      //stelnv piso ena event modal complited
      this.ModalUpdated.emit({ modal_updated: true });

    });
  }

  openModalPreview4() {
    const initialState = {
      workofart_item: this.workofart_item,
      workofart_items: this.workofart_items,
      isBookMarkChecked: this.isBookMarkChecked,
      isAvgRatingVisible: this.isAvgRatingVisible,
      isStarRatingVisible: this.isStarRatingVisible,
      isVerticallineVisible: this.isVerticallineVisible,
      isRatebuttonVisible: this.isRatebuttonVisible,
      isRatethisVisible: this.isRatethisVisible,
      isBookmarkVisible: this.isBookmarkVisible
    };

    let modal = this.bsModalRef = this.modalService.show(PreviewCuratorComponent, { class: 'modal-dialog-centered', initialState });

    modal.setClass('modal-custom-style');

    this.bsModalRef.content.event.subscribe(res => {

      // Close modal
      this.modalService.hide();

      //stelnv piso ena event modal complited
      this.ModalUpdated.emit({ modal_updated: true });

    });
  }

  /*
  * Navigate to user's profile
  */
  openUserProfile() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/profile/' + this.workofart_item.owner_id._id]);
  }
}
