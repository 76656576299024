import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '../services/data-layer.service';
import { Subscription } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SignupComponent } from '../signup/signup.component';
import { LoginComponent } from '../login/login.component';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  modalRef?: BsModalRef;

  public subscription: Subscription;
  public user_info: any = [];

  constructor(
    public modalService: BsModalService,
    private dataLayerService: DataLayerService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.dataLayerService.currentUser.subscribe(user => this.user_info = user);
  }

  openModalSignup() {
    this.modalRef = this.modalService.show(SignupComponent);

    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
      this.openModalLogin();
    });
  }

  openModalLogin() {
    this.modalRef = this.modalService.show(LoginComponent);

    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }
}
