export const environment = {
  production: true,
  apiUrl: "https://2gether-museum.gr/api/prod",
  apiUrlImg: "https://2gether-museum.gr"
};

// First url (start project)
/* apiUrl: "http://160.40.53.126/api",
   apiUrlImg: "http://160.40.53.126" 
*/

// Second url (test server)
/* apiUrl: "http://2gether-museum.gr/api/dev",
  apiUrlImg: "http://2gether-museum.gr" 
*/

// Third (production server)
/* apiUrl: "http://2gether-museum.gr/api/prod",
  apiUrlImg: "http://2gether-museum.gr" 
*/