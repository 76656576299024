<div class="container">
    <h1 *ngIf="isForAdd" class="title">{{'Αre you sure you want to proceed with the final submission of the digital exhibition?' | translate}}</h1>
    <h1 *ngIf="isForEdit" class="title">{{'Αre you sure you want to proceed with the final edit of the digital exhibition?' | translate}}</h1>
    <br>
    <br>
    <br>
    <div class="row justify-content-center">
        <div class="col question">
            <button type="submit" class="button_yes"
                (click)="onSubmit()">{{'Final submission' | translate}}</button>
        </div>
        <div class="col question">
            <button type="submit" class="button_no"
            (click)="onConfirmSubmitNo()">{{'No' | translate}}</button>
        </div>
    </div>
</div>
