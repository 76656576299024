import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-worksofart',
  templateUrl: './worksofart.component.html',
  styleUrls: ['./worksofart.component.css']
})
export class WorksofartComponent implements OnInit {
  work: any = {
    owner_id: ''
  };

  orderBody: any = [
    { name: 'Sort: Date (new first)' },
    { name: 'Sort: Date (elder first)' },
    { name: 'Sort: Rating (max first)' },
    { name: 'Sort: Title (alphabetical order)' }
  ];

  artistsBody: any = [];

  selectedOrder: string = 'Sort: Date (new first)';

  selectedArtist: string = 'Discover artists';

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  _id: any;

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  public isBookmarkVisible: boolean = true;
  public isRatethisVisible: boolean = true;
  public isRatebuttonVisible: boolean = true;
  public isVerticallineVisible: boolean = true;
  public isCheckboxVisible: boolean = true;
  public isSettings1Visible: boolean = true;
  public isSettings2Visible: boolean = true;
  public isWorkStatusVisible: boolean = true;
  public isStatus1Visible: boolean = true;
  public isStatus2Visible: boolean = true;
  public isStatus3Visible: boolean = true;

  public isUsersOwnProfile_Rating2: boolean = false;

  public workofart_items: any = [];
  public workofart_items_backup: any = [];
  public artist_names: any = [];

  constructor(
    private dataLayerService: DataLayerService,
    private spinnerService: NgxSpinnerService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.firstname = user[0].firstname;
          this.lastname = user[0].lastname;
          this.user_id = user[0]._id;
          this.work.owner_id = this.user_id;

          this.isCheckboxVisible = false;
          this.isSettings1Visible = false;
          this.isSettings2Visible = false;
          this.isWorkStatusVisible = false;
          this.isStatus1Visible = false;
          this.isStatus2Visible = false;
          this.isStatus3Visible = false;

          this.isUsersOwnProfile_Rating2 = false;

          if (user[0]['role'] === 'artist') {
            this.isRatebuttonVisible = true;
            this.isRatethisVisible = true;
            this.isVerticallineVisible = true;
            this.isBookmarkVisible = true;
          }
          if (user[0]['role'] === 'curator') {
            this.isBookmarkVisible = false;
            this.isRatebuttonVisible = true;
            this.isRatethisVisible = true;
            this.isVerticallineVisible = true;
          }

          this.getWorksOfArt();
        }

        else {
          this.isCheckboxVisible = false;
          this.isSettings1Visible = false;
          this.isSettings2Visible = false;
          this.isBookmarkVisible = false;
          this.isWorkStatusVisible = false;
          this.isStatus1Visible = false;
          this.isStatus2Visible = false;
          this.isStatus3Visible = false;

          this.spinnerService.show();

          this.userService.getWork()
            .pipe()
            .subscribe({
              next: data => {
                this.workofart_items = data["_items"];

                // Sort based on artwork created(new first)
                this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
                  return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                });

                /* console.log("Works of art:", this.workofart_items);*/
                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

          this.getWorksOfArt();
        }
      });
  }

  RatingUpdate(e: { rating_update: any }) {
    this.getWorksOfArt();
  }

  BookmarkUpdated(e: { bookmark_updated: any }) {
    this.getWorksOfArt();
  }

  //synarthsh gia handle event modal completed
  ModalCompleted(e: { modal_updated: any }) {
    /* console.log("modal completed event", e); */
    this.getWorksOfArt();
  }

  getWorksOfArt() {
    this.spinnerService.show();

    if (!this.workofart_items) this.workofart_items = [];

    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.userService.getWork()
            .pipe()
            .subscribe({
              next: data => {
                let workofart_items_tmp = data["_items"];

                this.handleArtistNameFiltering(workofart_items_tmp);

                // Get favourites of user
                this.userService.getFavouritesOfArtist(this.user_id)
                  .pipe()
                  .subscribe({
                    next: data => {

                      // create favourites_ids
                      let favourites_ids = [];
                      const artist_favourites_items = data['_items'];

                      // Add work ids in favourites
                      for (let j = 0; j < artist_favourites_items.length; j++) {
                        if (artist_favourites_items[j].work) {
                          favourites_ids.push(artist_favourites_items[j].work._id);
                        }
                      }

                      // Set bookmark for favourites
                      for (let i = 0; i < workofart_items_tmp.length; i++) {
                        if (favourites_ids.includes(workofart_items_tmp[i]._id)) {
                          workofart_items_tmp[i].bookmark_selected = true;
                        } 
                        else {
                          workofart_items_tmp[i].bookmark_selected = false;
                        }
                      }

                      this.workofart_items = workofart_items_tmp;

                      // Sort based on artwork created(new first)
                      this.workofart_items = workofart_items_tmp.sort((obj1, obj2) => {
                        return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                      });

                      /* console.log("Works of art:", this.workofart_items); */

                      // Create workofart items array backup
                      this.workofart_items_backup = this.workofart_items;

                      // Hide spinner
                      this.spinnerService.hide();
                    },
                    error: error => {
                      /* console.log("Handle Error: ", error); */
                      // Handle Error Here
                    }
                  });

              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
        }

        else {
          this.spinnerService.show();

          this.userService.getWork()
            .pipe()
            .subscribe({
              next: data => {
                this.workofart_items = data["_items"];

                this.handleArtistNameFiltering(this.workofart_items);

                // Sort based on artwork created(new first)
                this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
                  return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                });

                // Create workofart items array backup
                this.workofart_items_backup = this.workofart_items;

                /*  console.log("Works of art:", this.workofart_items); */
                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
        }
      });
  }

  /*
  * Modify array with artist full names used for filtering
  * Create option list for dropdown select
  */
  handleArtistNameFiltering(workofart_items_arr: Array<any>) {
    this.artist_names = [];
    this.artistsBody = [];

    for (let i = 0; i < workofart_items_arr.length; i++) {
      if (workofart_items_arr[i].owner_id) {
        const owner_profile = workofart_items_arr[i].owner_id;
        const artist_full_name = owner_profile['firstname'] + ' ' + owner_profile['lastname'];
        if (!this.artist_names.includes(artist_full_name)) {
          this.artist_names.push(artist_full_name);
        }
      }
    }

    for (let artist_full_name of this.artist_names) {
      this.artistsBody.push(
        {
          label: artist_full_name,
          value: artist_full_name
        }
      )
    }
  }

  /*
  * Catch event for changing order filter
  */
  changeOrderFilter(e) {
    this.selectedOrder = e.target.value;
    this.filterArtists();
  }

  /*
  * Catch event when changing artist filter
  */
  changeArtistList(e) {
    this.selectedArtist = e.target.value;
    this.filterArtists();
  }

  /*
  * Filter artists based on selected filters
  */
  filterArtists() {
    this.workofart_items = []
    
    // Filter based on artist
    if (this.selectedArtist !== 'Discover artists') {
      /* console.log(this.selectedArtist); */
      this.workofart_items = this.workofart_items_backup.filter((obj) => {
        const artist_lastname = this.selectedArtist.split(' ')[1];
        const artist_firstname = this.selectedArtist.split(' ')[0];
        return (obj.owner_id.lastname === artist_lastname && obj.owner_id.firstname === artist_firstname);
      });
    }
    else {
      this.workofart_items = this.workofart_items_backup;

      // Order array
      if (this.selectedOrder === 'Sort: Date (new first)') {
        this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
          return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
        });
      }
      else if (this.selectedOrder === 'Sort: Date (elder first)') {
        this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
          return new Date(obj1._created).getTime() - new Date(obj2._created).getTime();
        });

      }
      else if (this.selectedOrder === 'Sort: Rating (max first)') {
        this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
          return obj2.avg_rating - obj1.avg_rating;
        });
      }
      else {
        this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
          return obj1.title.localeCompare(obj2.title);
        });
      }
    }

    // Order array
    if (this.selectedOrder === 'Sort: Date (new first)') {
      this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
        return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
      });
    }
    else if (this.selectedOrder === 'Sort: Date (elder first)') {
      this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
        return new Date(obj1._created).getTime() - new Date(obj2._created).getTime();
      });

    }
    else if (this.selectedOrder === 'Sort: Rating (max first)') {
      this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
        return obj2.avg_rating - obj1.avg_rating;
      });
    }
    else {
      this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
        return obj1.title.localeCompare(obj2.title);
      });
    }
  }
}
