<div class="container">
    <h1 class="title">{{'Please enter your email to receive the verification code' | translate}}</h1>
    <form #userForm="ngForm" (NgSubmit)="userForm.valid && onSubmit(userForm)">
    <div class="row justify-content-center">
        <div class="mb-4">
            <label for="exampleInputEmail1" class="form-label"></label>
            <input type="email" class="form-control email-input" id="exampleInputEmail1"
            aria-describedby="emailHelp" placeholder="{{ 'Email address' | translate }}" name="email" [(ngModel)]="artist.email"
            #email="ngModel" email required>
            <small class="smallclass" *ngIf="email.touched && !email.valid">{{'Enter your email address!' | translate}}</small>
            <br>
            <small class="smallclass" *ngIf="email.touched && !email.valid">{{'Must be a valid email address.' | translate}}</small>
        </div>
    </div>
    <div class="mb-4">
        <div class="d-flex justify-content-center">
            <button type="submit" class="button_next"
            (click)="onSubmit(userForm)">{{'Next' | translate}}</button>
        </div>
    </div>
    </form>
</div>
