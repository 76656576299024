import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataLayerService } from '../services/data-layer.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-exhibition',
  templateUrl: './create-exhibition.component.html',
  styleUrls: ['./create-exhibition.component.css']
})
export class CreateExhibitionComponent implements OnInit {
  @Input('exhibition_obj') public exhibition_obj: any = [];
  @Output() exhibitionTitleCreated = new EventEmitter<{ title: string }>();

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  public exhibitionStepOneForm: FormGroup;

  public exhibition_items: any = [];

  public isForAdd: boolean = false;
  public isForEdit: boolean = false;

  constructor(
    public authService: AuthService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private spinnerService: NgxSpinnerService,
    public toastr: ToastrService,
    public translate: TranslateService,
    private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.exhibitionStepOneForm = this.fb.group({
      title: [this.exhibition_obj.title, Validators.compose([Validators.required])]
    });

    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.firstname = user[0].firstname;
          this.lastname = user[0].lastname;
          this.user_id = user[0]._id;

          //check if for upload exhibition or for edit
          this.spinnerService.show();

          this.userService.getExhibitionOfArtist(this.user_id)
            .pipe()
            .subscribe({
              next: data => {
                this.exhibition_items = data["_items"];
                /* console.log('My exhibition:', this.exhibition_items); */

                if (this.exhibition_items.length < 1) {
                  this.isForAdd = true;
                  this.isForEdit = false;
                }
                else {
                  this.isForAdd = false;
                  this.isForEdit = true;
                }

                /* console.log("ADD: ", this.isForAdd);
                console.log("EDDIT: ", this.isForEdit); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
           /*  console.log(this.exhibition_obj.title); */
        }
      });
  }

  titleSubmit() {
    const exhibition_title = this.exhibitionStepOneForm.value.title;
    /* console.log(exhibition_title); */

    if (exhibition_title === '') {
      // Send event to parent
      this.translate.get('Digital Exhibition').subscribe(res => {
        this.exhibitionTitleCreated.emit({ title: res + ' ' + this.firstname + ' ' + this.lastname });
      });
    }
    else if (exhibition_title.length > 100) {
      this.translate.get('You must enter a shorter title').subscribe(res => {
        this.toastr.error(res);
      });
    }
    else {
      // Send event to parent
      this.exhibitionTitleCreated.emit({ title: exhibition_title });
    }
  }

}
