import { Component, OnInit, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PhotoService } from '../services/photo.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html',
  styleUrls: ['./confirm-message.component.css']
})
export class ConfirmMessageComponent implements OnInit {
  @Input('title') public title: any = "";
  @Input('width') public width: any = "";
  @Input('height') public height: any = "";
  @Input('description') public description: any = "";
  @Input('tags') public tags: any = "";
  @Input('image') public image: any = "";
  @Input('day') public day: any = "";
  @Input('month') public month: any = "";
  @Input('year') public year: any = "";
  @Input('category') public category: any = "";

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";

  work_upload: any = {
    owner_id: '',
  };

  public isForAddWork: boolean = false;

  constructor(private router: Router,
    private toastr: ToastrService,
    private dataLayerService: DataLayerService,
    public modalService: BsModalService,
    private spinnerService: NgxSpinnerService,
    private userService: UserService,
    private photoService: PhotoService,
    public translate: TranslateService) { }


  ngOnInit(): void {
    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.firstname = user[0].firstname;
        this.lastname = user[0].lastname;
        this.user_id = user[0]._id;
        this.work_upload.owner_id = this.user_id;

        this.spinnerService.show();

        this.userService.getWorkOfArtist(this.user_id).pipe().subscribe({
          next: data => {
            /* console.log(data); */
            /* console.log(data["_items"].length); */

            if (data["_items"].length === 20) {
              this.isForAddWork = true;
            }

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
      }
    });

   /*  console.log("title που περναει στο confirm modal:", this.title);
    console.log("width που περναει στο confirm modal:", this.width);
    console.log("height που περναει στο confirm modal:", this.height);
    console.log("description που περναει στο confirm modal:", this.description);
    console.log("tags που περναει στο confirm modal:", this.tags);
    console.log("image που περναει στο confirm modal:", this.image);
    console.log("day που περναει στο confirm modal:", this.day);
    console.log("month που περναει στο confirm modal:", this.month);
    console.log("year που περναει στο confirm modal:", this.year);
    console.log("category που περναει στο confirm modal:", this.category);

    console.log("???:", this.isForAddWork); */
  }

  onConfirmSubmitNo() {
    this.triggerCloseModalEvent();
  }

  /*
 * Triger close event to the parent component
 */
  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }

  onSubmit() {
    this.work_upload.title = this.title;
    this.work_upload.description = this.description;
    this.work_upload.category = this.category;
    this.work_upload.tags = this.tags;
    this.work_upload.width = this.width;
    this.work_upload.height = this.height;

    if (!this.isForAddWork) {

      this.spinnerService.show();

      this.photoService.createWork(this.work_upload)
        .pipe()
        .subscribe({
          next: data => {
            const work_id = data._id

            this.photoService.updatePhotoWork(this.image, work_id)
              .pipe()
              .subscribe({
                next: data => {
                  this.translate.get('Artwork uploaded successfully!').subscribe(res => {
                    /* console.log(res); */
                    this.toastr.success(res);
                  });
                  this.router.navigate(['/profile']);
                },
                error: error => {
                  /* console.log("Handle Error: ", error); */
                  // Handle Error Here
                  this.translate.get('Something went wrong. Please try again.').subscribe(res => {
                    console.log(res);
                    this.toastr.error(res);
                  });
                }
              });

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
            this.translate.get('Something went wrong. Please try again.').subscribe(res => {
              /* console.log(res); */
              this.toastr.error(res);
            });
          }
        });
      this.triggerCloseModalEvent();

    }

    else {
      this.translate.get('You have exceeded the limit of your artworks.(20 artworks per artist)').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
      this.triggerCloseModalEvent();
    }
  }
}
