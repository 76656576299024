<div class='container'>
    <div class="row">
        <div class="col">
            <h3 class="edit-artwork">{{'Edit artwork' | translate}}</h3>
        </div>
    </div>
    
    <div class="row">
        <div class="col">
            <p class="name-artist-edit">{{firstname}} {{lastname}}</p>
        </div>
    </div>
    
    <form #userForm="ngForm" (NgSubmit)="userForm.form.valid">
    
        <div class="row">
            <div class="col-md-12 col-sm-12 col-lg-6">
                <img src={{imageUrl}} alt="..." class="image-edit-artwork">
            </div>
            <div class="col-md-12 col-sm-12 col-lg-6">
                <h6 class="tag_instructions">{{'Artwork title:' | translate}}</h6>
                <input type="text" class="input_artwork_title form-control" id="title" [maxlength]="80" name="title" [(ngModel)]="work.title" #title="ngModel"
                placeholder="{{'Artwork title (80 characters maximum) *' | translate}}" required><br>
                
                <h6 class="tag_instructions">{{'Tags (Please separate each tag with one space not with comma):' | translate}}</h6>
                <input type="text" class="input_artwork_tags" [maxlength]="80" [(ngModel)]="work.tag_str" #tags="ngModel" id="tags" name="tags"
                 placeholder="{{'(5 tags maximum) *' | translate}}" required><br>

                <h6 class="tag_instructions extra-class">{{'Artwork description:' | translate}}</h6>
                <input type="textarea" class="input_artwork_description form-control" [maxlength]="600" [(ngModel)]="work.description" #description="ngModel" 
                    id="artwork_description" name="artwork_description" placeholder="{{'Artwork description (600 characters maximum) *' | translate}}" required><br>
            </div>
        </div>

        <div class="row" style="margin-top: 15px;text-align: center;margin-bottom: 25px;padding-bottom: 20px;">
            <div class="col-md-12 col-sm-12 col-lg-4 col-xl-4">
                <button (click)="onEditWorkSubmitApprove(userForm)" class="approve_artwork">{{'Approve Artwork' | translate}}</button>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-4 col-xl-4">
                <button (click)="onEditWorkSubmitReject(userForm)" class="reject_artwork">{{'Reject Artwork' | translate}}</button>
            </div>
            <div class="col-md-12 col-sm-12 col-lg-4 col-xl-4">
                <button [routerLink]="['/profile-curator']" class="cancel_artwork">{{'Cancel' | translate}}</button>
            </div>
        </div>
        
    
    </form>
</div>