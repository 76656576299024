import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { DataLayerService } from '../services/data-layer.service';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DeleteExhibitionComponent } from '../delete-exhibition/delete-exhibition.component';
import { DeleteProfileUserComponent } from '../delete-profile-user/delete-profile-user.component';
import { DeleteProfileCuratorComponent } from '../delete-profile-curator/delete-profile-curator.component';

@Component({
  selector: 'app-e-exhibitions-card',
  templateUrl: './e-exhibitions-card.component.html',
  styleUrls: ['./e-exhibitions-card.component.css']
})
export class EExhibitionsCardComponent implements OnInit {
  @Input('exhibition_item') public exhibition_item: any = [];
  @Input('isSettings1Visible') public isSettings1Visible: boolean = true;
  @Input('isSettings2VisibleExhib') public isSettings2VisibleExhib: boolean = true;
  @Input('isStatusExhibition1Visible') public isStatusExhibition1Visible: boolean = false;
  @Input('isStatusExhibition2Visible') public isStatusExhibition2Visible: boolean = false;
  @Input('isStatusExhibition3Visible') public isStatusExhibition3Visible: boolean = false;
  @Input('isImage2Visible') public isImage2Visible: boolean = false;
  @Input('isImage3Visible') public isImage3Visible: boolean = false;
  @Input('isImage4Visible') public isImage4Visible: boolean = false;
  @Input('isImage5Visible') public isImage5Visible: boolean = false;
  @Input('isImage6Visible') public isImage6Visible: boolean = false;

  @Output('RemoveExhibitionId') public RemoveExhibitionId = new EventEmitter<{ remove_exhibition: string }>();
  @Output('UpdateStatusExhibition') public UpdateStatusExhibition = new EventEmitter<{ status_update: any }>();

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;
  bsModalRef: BsModalRef;

  public imageUrl1: string;
  public imageUrl2: string;
  public imageUrl3: string;
  public imageUrl4: string;
  public imageUrl5: string;
  public imageUrl6: string;

  public upload_date: string = '';

  exhibition: any = {
    _id: ''
  }

  work: any = {
    owner_id: '',
    _id: ''
  };

  firstname: string = "";
  lastname: string = "";

  user_id: string = "";
  _id: string = "";
  role: string = "";

  public isapproved: boolean = false;
  public isrejected: boolean = false;
  public isaccountdelete: boolean = false;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public modalService: BsModalService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    const created_dt = new Date(this.exhibition_item._created);
    this.upload_date = created_dt.toLocaleDateString();

    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.firstname = user[0].firstname;
        this.lastname = user[0].lastname;
        this.user_id = user[0]._id;
        this.work.owner_id = this.user_id;
        this.role = user[0].role;

        if (user[0]['role'] === 'curator') {
          this.isSettings2VisibleExhib = true;
          this.isaccountdelete = true;
        }

        if (this.exhibition_item.works[1]) {
          this.isImage2Visible = true;
          this.imageUrl2 = 'data:image/gif;base64,' + this.exhibition_item.works[1].thumbnail;
        }
        if (this.exhibition_item.works[2]) {
          this.isImage3Visible = true;
          this.imageUrl3 = 'data:image/gif;base64,' + this.exhibition_item.works[2].thumbnail;
        }
        if (this.exhibition_item.works[3]) {
          this.isImage4Visible = true;
          this.imageUrl4 = 'data:image/gif;base64,' + this.exhibition_item.works[3].thumbnail;
        }
        if (this.exhibition_item.works[4]) {
          this.isImage5Visible = true;
          this.imageUrl5 = 'data:image/gif;base64,' + this.exhibition_item.works[4].thumbnail;
        }
        if (this.exhibition_item.works[5]) {
          this.isImage6Visible = true;
          this.imageUrl6 = 'data:image/gif;base64,' + this.exhibition_item.works[5].thumbnail;
        }

        if (this.exhibition_item.status === "APPROVED") {
          this.isapproved = false;
          this.isrejected = false;
        }
        else if (this.exhibition_item.status === "REJECTED") {
          this.isrejected = false;
          this.isapproved = false;
        }
        else {
          this.isrejected = true;
          this.isapproved = true;
        }
      }

      else {
        if (this.exhibition_item.works[1]) {
          this.isImage2Visible = true;
          this.imageUrl2 = 'data:image/gif;base64,' + this.exhibition_item.works[1].thumbnail;
        }
        if (this.exhibition_item.works[2]) {
          this.isImage3Visible = true;
          this.imageUrl3 = 'data:image/gif;base64,' + this.exhibition_item.works[2].thumbnail;
        }
        if (this.exhibition_item.works[3]) {
          this.isImage4Visible = true;
          this.imageUrl4 = 'data:image/gif;base64,' + this.exhibition_item.works[3].thumbnail;
        }
        if (this.exhibition_item.works[4]) {
          this.isImage5Visible = true;
          this.imageUrl5 = 'data:image/gif;base64,' + this.exhibition_item.works[4].thumbnail;
        }
        if (this.exhibition_item.works[5]) {
          this.isImage6Visible = true;
          this.imageUrl6 = 'data:image/gif;base64,' + this.exhibition_item.works[5].thumbnail;
        }
      }

      this.imageUrl1 = 'data:image/gif;base64,' + this.exhibition_item.works[0].thumbnail;
    });
  }

  // REMOVE EXHIBITION CALL at delete-exhibition component
  /*  removeExhibition(exhibition_id: string) {
     this.userService.deleteExhibition(exhibition_id).pipe().subscribe({
       next: data => {
         console.log("Successful response: ", data);
         this.RemoveExhibitionId.emit({ remove_exhibition: exhibition_id });
 
         this.translate.get('Exhibition removed!').subscribe(res => {
           console.log(res);
           this.toastr.success(res);
         });
       },
       error: error => {
         console.log("Handle Error: ", error)
         // Handle Error Here
       }
     });
   } */

  previewExhibitionEdit() {
    this.router.navigate(['/edit-exhibition-curator/' + this.exhibition_item._id]);
  }

  openModalConfirmDelete() {
    const initialState = {
      exhibition_item: this.exhibition_item,
      exhibition_item_id: this.exhibition_item._id
    };

    this.modalRef = this.modalService.show(DeleteExhibitionComponent, { initialState });
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  openModalConfirmDeleteAccount() {
    const initialState = {
      owner_id_user: this.exhibition_item.owner_id._id
    };

    if (this.role === 'artist') {
      this.modalRef = this.modalService.show(DeleteProfileUserComponent, { initialState });
      this.event.emit('CLOSE');

      this.modalRef.content.event.subscribe(res => {
        this.modalRef.hide();
      });
    }

    if (this.role === 'curator') {
      this.modalRef = this.modalService.show(DeleteProfileCuratorComponent, { initialState });
      this.event.emit('CLOSE');

      this.modalRef.content.event.subscribe(res => {
        this.modalRef.hide();
      });
    }
  }

  approveExhibition(exhibition_id: string) {
    const statusBody = {
      status: "APPROVED"
    }

    this.userService.statusExhibition(exhibition_id, statusBody).pipe().subscribe({
      next: data => {
        /* console.log("Successful response: ", data); */
        this.UpdateStatusExhibition.emit({ status_update: statusBody });

        this.translate.get('Digital Exhibition approved!').subscribe(res => {
          /* console.log(res); */
          this.toastr.success(res);
        });
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });
  }

  rejectExhibition(exhibition_id: string) {
    const statusBody = {
      status: "REJECTED"
    }

    this.userService.statusExhibition(exhibition_id, statusBody).pipe().subscribe({
      next: data => {
        /* console.log("Successful response: ", data); */
        this.UpdateStatusExhibition.emit({ status_update: statusBody });

        this.translate.get('Digital Exhibition rejected!').subscribe(res => {
          /* console.log(res); */
          this.toastr.success(res);
        });
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });
  }

  /*
  * Navigate to user's profile
  */
  openUserProfile() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/profile/' + this.exhibition_item.owner_id._id]);
  }

  /*
  * Navigate to user's exhibition
  */
  previewExhibition() {
    this.router.navigate(['/preview-exhibition/' + this.exhibition_item._id]);
  }

  /*
 * Navigate to user's exhibition for edit
 */
  editExhibition(exhibition_id: string) {
    exhibition_id = this.exhibition_item._id;
    this.router.navigate(['/edit-exhibition/' + exhibition_id]);
  }
}
