import { Component, OnInit, } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-set-exhibition',
  templateUrl: './set-exhibition.component.html',
  styleUrls: ['./set-exhibition.component.css']
})
export class SetExhibitionComponent implements OnInit {
   public exhibition_obj: any = {
    owner_id: '',
    title: '',
    works: []
  };

  public isForEdit: boolean = false;

  constructor(
    public authService: AuthService,
    public toastr: ToastrService,
    public translate: TranslateService) { }

  ngOnInit(): void {
  }

}
