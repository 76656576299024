import { Component, OnInit, EventEmitter } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ForgotPassword2Component } from '../forgot-password2/forgot-password2.component';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();

  artist: any = {
    email: ''
  };

  modalRef?: BsModalRef;
  bsModalRef: BsModalRef;

  constructor(private authService: AuthService,
    private toastr: ToastrService,
    public modalService: BsModalService,
    public translate: TranslateService) { }

  ngOnInit(): void {
  }

  openModalForgot2() {
    this.modalRef = this.modalService.show(ForgotPassword2Component);
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  onSubmit(userForm: NgForm) {
    /* console.log("email που περναει στο forgot modal:", this.artist.email); */

    // Request body
    const forgotPass_res = {
      email: this.artist.email
    };

    if (!userForm.form.valid) {
      this.translate.get('Please enter your email to continue').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    }

    else {
      // Forgot Password1 Request
      this.authService.forgotPassword(forgotPass_res)
        .pipe()
        .subscribe({
          next: data => {
            this.translate.get('Your verification code has been sent to your email').subscribe(res => {
              /* console.log(res); */
              this.toastr.success(res);
            });

            this.triggerCloseModalEvent();

            this.openModalForgot2();
          },
          error: error => {
            if (error.status === 401) {
              this.translate.get('Invalid email address').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }

            else if (error.status === 422) {
              this.translate.get('This email address is not unique').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
            else {
              this.translate.get('Something went wrong. Please try again.').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
          }
        });
    }
  }

  /*
 * Triger close event to the parent component
 */
  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }
}
