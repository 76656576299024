<div class="container">
    <h1 class="title">{{'Are you sure you want to delete your account? All your data will be deleted along with it.' | translate}}</h1>
    <br>
    <br>
    <br>
    <div class="row justify-content-center">
        <div class="col confirm">
            <button type="submit" class="button_yes"
                (click)="onSubmit()">{{'Final deletion' | translate}}</button>
        </div>
        <div class="col confirm">
            <button type="submit" class="button_no"
            (click)="onConfirmSubmitNo()">{{'No' | translate}}</button>
        </div>
    </div>
</div>
