import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from 'rxjs';
import { DataLayerService } from '../services/data-layer.service';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {
  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;
  public isLoadingCompleted: boolean = false;

  public isUsersOwnProfile: boolean = false;

  public profileID: string | null = null;
  public authenticatedUser: any = {};

  constructor(
    public activatedRoute: ActivatedRoute,
    private dataLayerService: DataLayerService,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {

      this.profileID = params.get("id");
      const isUserLoggedIn = !this.authService.notLoggedIn();

      // Check if path contains profile id
      if (this.profileID) {

        if (isUserLoggedIn) {

          const user_id = this.authService.getUserIdFromToken();

          if (user_id === this.profileID) {
            // show user's own profile
            this.subscription = this.dataLayerService.currentUser.subscribe(
              user => {
                if (user.length > 0) {
                  this.handleAuthenticatedUser(user);
                }
              });

          }

          else {
            // get user by user id
            this.userService.getProfile(this.profileID)
              .pipe()
              .subscribe({
                next: res => {
                  this.user_info = res;

                  this.subscription = this.dataLayerService.currentUser.subscribe(
                    user => {
                      if (user.length > 0) {
                        this.authenticatedUser = user[0];
                        this.isLoadingCompleted = true;
                      }
                    });
                },
                error: error => {
                  if (error.status == 401) {
                  }
                }
              });
          }
        }

        else {
          this.userService.getProfileForAnonymous(this.profileID)
            .pipe()
            .subscribe({
              next: res => {
                this.user_info = res;
                this.isLoadingCompleted = true;
              },
              error: error => {
                if (error.status === 401) {
                }
              }
            });
        }
      }

      else {
        if (isUserLoggedIn) {
          const user_id = this.authService.getUserIdFromToken()
          this.router.navigate(['/profile/' + user_id]);
        } 
        else {
          this.router.navigate(['/worksofart']);
        }
      }
    });
  }

  handleAuthenticatedUser(user: any) {
    this.user_info = user[0];

    const user_id = this.user_info._id;

    if (user_id === this.profileID) {
      // User can edit own profile
      this.isUsersOwnProfile = true;
    }

    this.isLoadingCompleted = true;
  }
}