import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-work-curator',
  templateUrl: './edit-work-curator.component.html',
  styleUrls: ['./edit-work-curator.component.css']
})
export class EditWorkCuratorComponent implements OnInit {
  firstname: string = "";
  lastname: string = "";
  work_user_id: string = "";
  work_id: string = "";
  user_id: string = "";

  work: any = {
    pic: "",
    title: '',
    description: '',
    password: '',
    tags: ["tag1", "tag2", "tag3", "tag4", "tag5"],
    tags_str: '',
    status: ''
  };

  public imageUrl: string;

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: boolean = false;

  constructor(private router: Router,
    private toastr: ToastrService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const workID = params.get("id");
      this.work_id = workID;

      this.subscription = this.dataLayerService.currentUser.subscribe(
        user => {
          if (user.length > 0) {
            this.authenticated_user = true;

            this.spinnerService.show();

            this.userService.getSpecificWork(this.work_id).pipe().subscribe({
              next: data => {
                this.work.title = data["_items"][0].title;
                this.work.description = data["_items"][0].description;
                this.work.pic = data["_items"][0].pic;

                this.work.tags = data["_items"][0].tags;
                this.work.tag_str = this.work.tags.join(" ");

                this.work.status = data["_items"][0].status;
                this.work_user_id = data["_items"][0].owner_id;
                this.firstname = data["_items"][0].owner_id.firstname;
                this.lastname = data["_items"][0].owner_id.lastname;

                this.imageUrl = 'data:image/gif;base64,' + data["_items"][0].thumbnail;

                const workIDVR = data["_items"][0]._id;
                this.work_id = workIDVR;

                /*  console.log(data);
                 console.log(this.work.description);
                 console.log(this.work.title);
                 console.log(this.work.pic);
                 console.log(this.work.tags);
                 console.log(this.work.status); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
          }
        });
    });
  }

  onEditWorkSubmitApprove(userForm: NgForm) {
    // Request body
    const edit_res = {
      tags: this.work.tag_str.split(' '),
      title: this.work.title,
      description: this.work.description,
      status: "APPROVED"
    };

    if (this.work.tag_str.split(' ').length > 5) {
      this.translate.get('Please enter 5 tags maximum').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    }
    else {
      // Edit Work Request
      this.userService.editWork(this.work_id, edit_res)
        .pipe()
        .subscribe({
          next: data => {
            /* console.log(edit_res); */
            this.translate.get('Artwork approved!').subscribe(res => {
              /* console.log(res); */
              this.toastr.success(res);
            });

            this.router.navigate(['/profile-curator']);
            this.dataLayerService.restoreStateOnRefresh();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }
  }

  onEditWorkSubmitReject(userForm: NgForm) {
    // Request body
    const edit_res = {
      tags: this.work.tag_str.split(' '),
      title: this.work.title,
      description: this.work.description,
      status: "REJECTED"
    };

    if (this.work.tag_str.split(' ').length > 5) {
      this.translate.get('Please enter 5 tags maximum').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    }
    else {
      // Edit Work Request
      this.userService.editWork(this.work_id, edit_res)
        .pipe()
        .subscribe({
          next: data => {
            /* console.log(edit_res); */
            this.translate.get('Artwork rejected!').subscribe(res => {
              /* console.log(res); */
              this.toastr.success(res);
            });

            this.router.navigate(['/profile-curator']);
            this.dataLayerService.restoreStateOnRefresh();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }
  }
}
