<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<!-- <link rel="stylesheet"
  href="https://fonts.sandbox.google.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0" /> -->

<div class='container'>
  <div class="row">
    <div class="col">
      <p class="discover">{{'Explore...' | translate}}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <p class="works_after_discover">{{'Digital Exhibitions' | translate}}
      </p>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <p class="worksofart-text">{{"Would you like to 'travel' new paths in the world of art? Create your own digital exhibition with artworks from the platform, express yourself through your artistic approach and invite the public to explore your own alternate reality!" | translate}}<p>
    </div>
  </div>

  <div class="row filter_row">
    <div class="col">
      <div class="mb-3">
        <select (change)="changeExhibitionFilter($event)" class="selectsortdate">
          <option [value]="n.name" *ngFor="let n of exhibitionBody">{{n.name | translate}}</option>
      </select>
      </div>
    </div>
  </div>
  <br>
  <div class="row row-cols-1 row-cols-md-2 g-3 row-cols-sm-1 row-cols-lg-2 row-cols-xl-3"> <!-- row row-cols-1 row-cols-md-3 g-2 -->
    <div *ngFor="let item of exhibition_items">
      <app-e-exhibitions-card [exhibition_item]="item" 
      [isSettings1Visible]="isSettings1Visible"
      [isSettings2VisibleExhib]="isSettings2VisibleExhib"></app-e-exhibitions-card>
    </div>
  </div>
</div>