import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-e-exhibitions',
  templateUrl: './e-exhibitions.component.html',
  styleUrls: ['./e-exhibitions.component.css']
})
export class EExhibitionsComponent implements OnInit {
  work: any = {
    owner_id: ''
  }

  exhibitionBody: any = [{ name: 'Sort: Date (new first)' }, { name: 'Sort: Date (elder first)' }, { name: 'Sort: Title (alphabetical order)' }];

  selected: string = 'Sort: Date (new first)';

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";

  public subscription: Subscription;
  public user_info: any = []
  public authenticated_user: any

  public exhibition_items: any = [];
  public exhibition_items_all: any = [];

  public isSettings1Visible: boolean = true;
  public isSettings2VisibleExhib: boolean = true;

  constructor(
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.firstname = user[0].firstname;
          this.lastname = user[0].lastname;
          this.user_id = user[0]._id;
          this.work.owner_id = this.user_id;

          this.isSettings1Visible = false;
          this.isSettings2VisibleExhib = false;

          this.spinnerService.show();

          this.userService.getExhibition()
            .pipe()
            .subscribe({
              next: data => {
                this.exhibition_items_all = data["_items"];

                // Sort based on exhibition created(new first)
                this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
                  return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                });

                /* console.log("Exhibitions: ", this.exhibition_items); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
        }

        else {
          this.isSettings1Visible = false;
          this.isSettings2VisibleExhib = false;

          this.spinnerService.show();

          this.userService.getExhibition()
            .pipe()
            .subscribe({
              next: data => {
                this.exhibition_items_all = data["_items"];

                // Sort based on exhibition created(new first)
                this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
                  return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                });

                /* console.log("Exhibitions: ", this.exhibition_items); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
        }
      });
  }

  changeExhibitionFilter(e) {
    /* console.log(e.target.value); */
    this.selected = e.target.value;
    /* console.log(this.selected); */

    this.isSettings1Visible = false;
    this.isSettings2VisibleExhib = false;

    if (this.selected === 'Sort: Date (new first)') {
      this.spinnerService.show();

      this.userService.getExhibition()
        .pipe()
        .subscribe({
          next: data => {
            this.exhibition_items_all = data["_items"];

            // Sort based on exhibition created(new first)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
            });

            /* console.log("Exhibitions: ", this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }
    else if (this.selected === 'Sort: Date (elder first)') {
      this.spinnerService.show();

      this.userService.getExhibition()
        .pipe()
        .subscribe({
          next: data => {
            this.exhibition_items_all = data["_items"];

            this.exhibition_items = this.exhibition_items_all

            // Custom sort based on exhibition created(elder first)
            /* console.log("Exhibitions: ", this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }
    else {
      this.spinnerService.show();

      this.userService.getExhibition()
        .pipe()
        .subscribe({
          next: data => {
            this.exhibition_items_all = data["_items"];

            // Sort based on exhibition alphabetical order(title)
            this.exhibition_items = this.exhibition_items_all.sort((obj1, obj2) => {
              return obj1.title.localeCompare(obj2.title);
            });

            /* console.log("Exhibitions: ", this.exhibition_items); */

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
    }
  }
}
