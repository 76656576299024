import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataLayerService } from '../services/data-layer.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-exhibition3',
  templateUrl: './create-exhibition3.component.html',
  styleUrls: ['./create-exhibition3.component.css']
})
export class CreateExhibition3Component implements OnInit {
  @Output() goToPreviousStep = new EventEmitter<{ step: number }>();
  @Output() exhibitionWorksCreated = new EventEmitter<{ works: [] }>();
  @Output() exhibitionTitleCreated = new EventEmitter<{ title: string }>();
  @Input('exhibition_obj') public exhibition_obj: any = [];

  public work_ids_arr: any = [];

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  _id: any;

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  public isBookmarkVisible: boolean = true;
  public isRatethisVisible: boolean = true;
  public isRatebuttonVisible: boolean = true;
  public isVerticallineVisible: boolean = true;
  public isSettings1Visible: boolean = true;
  public isSettings2Visible: boolean = true;
  public isParagraphForFavourites: boolean = false;

  public isUsersOwnProfile_Rating: boolean = true;
  public isUsersOwnProfile_Rating2: boolean = true;

  public isPreview1Visible: boolean = false;
  public isPreview3Visible: boolean = false;

  public SelectedWorkid: string;

  public exhibition_items: any = [];
  public favourite_workofart: any = [];
  public artist_favourites_items: any = [];
  public favourites_ids: any = [];
  public SelectedFavouriteId: string;

  public exhibitionStepThreeForm: FormGroup;

  public isForAdd: boolean = false;
  public isForEdit: boolean = false;

  constructor(
    public authService: AuthService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    // Array must include all the previously selected work ids
    this.work_ids_arr = this.exhibition_obj.works;

    this.exhibitionStepThreeForm = this.fb.group({
      works: [this.exhibition_obj.works, Validators.compose([Validators.required])]
    });

    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.firstname = user[0].firstname;
          this.lastname = user[0].lastname;
          this.user_id = user[0]._id;

          //check if for upload exhibition or for edit
          this.spinnerService.show();

          this.userService.getExhibitionOfArtist(this.user_id)
            .pipe()
            .subscribe({
              next: data => {
                this.exhibition_items = data["_items"];
                /* console.log('My exhibition:', this.exhibition_items); */

                if (this.exhibition_items.length < 1) {
                  this.isForAdd = true;
                  this.isForEdit = false;
                }
                else {
                  this.isForAdd = false;
                  this.isForEdit = true;
                }
                /* console.log("ADD: ", this.isForAdd);
                console.log("EDDIT: ", this.isForEdit); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                console.log("Handle Error: ", error);
                // Handle Error Here
              }
            });

          this.isRatethisVisible = false;
          this.isRatebuttonVisible = false;
          this.isVerticallineVisible = false;
          this.isSettings1Visible = false;
          this.isSettings2Visible = false;
          this.isUsersOwnProfile_Rating2 = true;
          this.isUsersOwnProfile_Rating = true;
          this.isPreview1Visible = true;

          this.artist_favourites_items = [];
          this.favourite_workofart = [];

          this.spinnerService.show();

          this.userService.getFavouritesOfArtist(this.user_id)
            .pipe()
            .subscribe({
              next: data => {
                this.isPreview1Visible = true;
                this.isUsersOwnProfile_Rating2 = true;
                const artist_favourites_items = data['_items'];
                this.artist_favourites_items = artist_favourites_items;

                for (let j = 0; j < artist_favourites_items.length; j++) {

                  if (artist_favourites_items[j].work) {
                    // Set a new variable bookmark_selected as true
                    artist_favourites_items[j].work.bookmark_selected = true;

                    this.favourite_workofart.push(artist_favourites_items[j].work);

                    // Filter based on favourites artwork status
                    this.favourite_workofart = this.favourite_workofart.filter((obj) => {
                      return obj.status === "APPROVED";
                    });

                  }

                }

                // Set true/false for checkbox
                for (let i = 0; i < this.favourite_workofart.length; i++) {
                  if (this.exhibition_obj.works.includes(this.favourite_workofart[i]._id)) {
                    this.favourite_workofart[i].checkbox_selected = true;
                  }
                }

                /* console.log('My favourites:', this.favourite_workofart); */

                if (this.favourite_workofart.length < 1) {
                  this.isParagraphForFavourites = true;
                }

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

        }
      });
  }

  StepthreeSubmit() {
    this.exhibitionWorksCreated.emit({ works: this.work_ids_arr });
  }

  GoBackToStepTwo() {
    this.goToPreviousStep.emit({ step: 2 });
  }

  onCheckboxSelected(e: { is_selected: string }) {
    if (this.work_ids_arr.includes(e.is_selected)) {
      this.work_ids_arr = this.work_ids_arr.filter(
        (work_id: string) => work_id !== e.is_selected
      );
    }
    else {
      this.work_ids_arr.push(e.is_selected);
    }
  }
}
