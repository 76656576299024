<div class="container">
    <h1 class="title">{{'Please enter your new password' | translate}}</h1>
    <form #userForm="ngForm" (NgSubmit)="userForm.valid && onSubmit(userForm)">
        <div class="row" style="text-align: center;">
            <div class="col">
                <label for="exampleInputEmail1" class="form-label"></label>
                <input type="email" class="form-control email-input" id="exampleInputEmail1"
                aria-describedby="emailHelp" placeholder="{{ 'Email address' | translate }}" name="email" [(ngModel)]="artist.email"
                #email="ngModel" email required>
                <small class="smallclass" *ngIf="email.touched && !email.valid">{{'Enter your email address!' | translate}}</small>
                <br>
                <small class="smallclass" *ngIf="email.touched && !email.valid">{{'Must be a valid email address.' | translate}}</small>
            </div>
        </div>
        <div class="row" style="text-align: center;">
            <div class="col">
                <label for="exampleInputVerificationcode1" class="form-label"></label>
                <input type="text" onkeypress="return /[0-9]/i.test(event.key)" class="form-control verification-input" id="exampleInputVerificationcode1" placeholder="{{ 'Verification code (via email)' | translate }}"
                    name="verification_code" [(ngModel)]="artist.verification_code" #verification_code="ngModel" minlength="6" maxlength="6" required>
                <small class="smallclass" *ngIf="verification_code.touched && !verification_code.valid">{{'Enter your verification code!' | translate}}</small>
                <br>
                <small class="smallclass" *ngIf="verification_code.touched && !verification_code.valid">{{'Verification code must be 6 characters long.' | translate}}</small>
            </div>
        </div>
        <div class="row" style="text-align: center;">
            <div class="col">
                <label for="exampleInputPassword1" class="form-label"></label>
                <input type="password" class="form-control pass-input" id="exampleInputPassword1" placeholder="{{ 'New password' | translate }}"
                name="password" [(ngModel)]="artist.password" #password="ngModel" minlength="6" required>
                <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Enter your password!' | translate}}</small>
                <br>
                <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Password should be at least 6 characters long.' | translate}}</small>
            </div>
        </div>
        <div class="row">
            <button type="submit" class="button_next"
            (click)="onSubmit(userForm)">{{'Next' | translate}}</button>
        </div>
    </form>
</div>
