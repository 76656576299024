import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-e-exhibitions-vr2',
  templateUrl: './e-exhibitions-vr2.component.html',
  styleUrls: ['./e-exhibitions-vr2.component.css']
})
export class EExhibitionsVr2Component implements OnInit {
  title_exhi: string = "";
  firstname: string = "";
  lastname: string = "";
  exhi_user_id: string = "";
  exhibition_id: string = "";
  works_exhi: any = [];

  user_id: string = "";

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: boolean = false;

  constructor(private router: Router,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public activatedRoute: ActivatedRoute,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const exhibitionID = params.get("id");
      this.exhibition_id = exhibitionID;

      this.subscription = this.dataLayerService.currentUser.subscribe(
        user => {
          if (user.length > 0) {
            this.authenticated_user = true;
            this.user_id = user[0]._id;

            this.spinnerService.show();

            this.userService.getSpecificExhibition(this.exhibition_id).pipe().subscribe({
              next: data => {
                this.title_exhi = data["_items"][0].title;
                this.exhi_user_id = data["_items"][0].owner_id;
                this.firstname = data["_items"][0].owner_id.firstname;
                this.lastname = data["_items"][0].owner_id.lastname;
                this.works_exhi = data["_items"][0].works;

                const exhibitionIDVR = data["_items"][0]._id;
                this.exhibition_id = exhibitionIDVR;

                /* console.log(data); */
                /* console.log(this.works_exhi); */
                /* console.log(this.exhibition_id); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
          }

          else {
            this.spinnerService.show();

            this.userService.getSpecificExhibition(this.exhibition_id).pipe().subscribe({
              next: data => {
                this.title_exhi = data["_items"][0].title;
                this.exhi_user_id = data["_items"][0].owner_id;
                this.firstname = data["_items"][0].owner_id.firstname;
                this.lastname = data["_items"][0].owner_id.lastname;
                this.works_exhi = data["_items"][0].works;

                const exhibitionIDVR = data["_items"][0]._id;
                this.exhibition_id = exhibitionIDVR;

                /* console.log(data); */
                /* console.log(this.works_exhi); */
                /* console.log(this.exhibition_id); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
          }
        });
    });
  }
}
