import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-confirm-exhibition',
  templateUrl: './confirm-exhibition.component.html',
  styleUrls: ['./confirm-exhibition.component.css']
})
export class ConfirmExhibitionComponent implements OnInit {
  @Input('title') public title: any = "";
  @Input('works') public works: any = "";
  @Input('positions') public positions: any = "";
  @Input('id_exhibition') public id_exhibition: any = "";
  @Input('isForEdit') public isForEdit: boolean = false;
  @Input('isForAdd') public isForAdd: boolean = false;

  public event: EventEmitter<any> = new EventEmitter();
  public exhibition_items: any = [];

  modalRef?: BsModalRef;

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  exhibition_id: string = "";

  exhibition: any = {
    owner_id: '',
    works: [],
    positions: [],
    _id: ''
  };

  constructor(private router: Router,
    private toastr: ToastrService,
    private dataLayerService: DataLayerService,
    public modalService: BsModalService,
    private userService: UserService,
    private spinnerService: NgxSpinnerService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.firstname = user[0].firstname;
        this.lastname = user[0].lastname;
        this.user_id = user[0]._id;
        this.exhibition.owner_id = this.user_id;

        //check if for upload exhibition or for edit
        this.spinnerService.show();

        this.userService.getExhibitionOfArtist(this.user_id)
          .pipe()
          .subscribe({
            next: data => {
              this.exhibition_items = data["_items"];
              /* console.log('My exhibition:', this.exhibition_items); */

              if (this.exhibition_items.length === 0) {
                this.isForAdd = true;
                this.isForEdit = false;
              }
              else {
                this.exhibition_id = data["_items"][0]._id;
                /* console.log('My exhibition:', this.exhibition_items); */
                /* console.log('Exhibition _id:', this.exhibition_id); */
                this.isForAdd = false;
                this.isForEdit = true;
              }

              /* console.log("ADD: ", this.isForAdd); */
              /*  console.log("EDDIT: ", this.isForEdit); */

              // Hide spinner
              this.spinnerService.hide();
            },
            error: error => {
              /*  console.log("Handle Error: ", error); */
              // Handle Error Here
            }
          });

        /*  console.log("title που περναει στο confirm modal:", this.title);
         console.log("works που περναει στο confirm modal:", this.works);
         console.log("positions που περναει στο confirm modal:", this.positions);
         console.log("ownerId που περναει στο confirm modal:", this.user_id);
         console.log("_id που περναει στο confirm modal:", this.id_exhibition);
         console.log("isForEdit που περναει στο confirm modal:", this.isForEdit);
         console.log("isForAdd που περναει στο confirm modal:", this.isForAdd); */
      }
    });
  }

  onSubmit() {
    this.exhibition.title = this.title;
    this.exhibition.works = this.works;
    this.exhibition.positions = this.positions;
    this.exhibition.owner_id = this.user_id;

    /* console.log("title upload:", this.exhibition.title);
    console.log("works upload:", this.exhibition.works);
    console.log("positions upload:", this.exhibition.positions);
    console.log("ownerId upload:", this.exhibition.owner_id); */

    const exhibitionBody = {
      title: this.exhibition.title,
      works: this.exhibition.works,
      positions: this.exhibition.positions,
      owner_id: this.exhibition.owner_id
    }

    if (this.isForEdit === false) {
      this.userService.postExhibition(exhibitionBody).pipe().subscribe({
        next: data => {
          /* console.log("Successful response: ", data); */
          this.translate.get('Digital Exhibition uploaded successfully!').subscribe(res => {
            /* console.log(res); */
            this.toastr.success(res);
          });
          this.router.navigate(['/profile']);
        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          this.translate.get('You cannot have more than one active exhibitions').subscribe(res => {
            /* console.log(res); */
            this.toastr.error(res);
          });
        }
      });
      this.triggerCloseModalEvent();
    }

    else {
      this.exhibition._id = this.id_exhibition;
      /*  console.log("_id upload:", this.exhibition._id); */

      this.userService.editExhibition(this.exhibition._id, exhibitionBody).pipe().subscribe({
        next: data => {
          /* console.log("Successful response: ", data); */
          this.translate.get('Digital Exhibition updated successfully!').subscribe(res => {
            /* console.log(res); */
            this.toastr.success(res);
          });
          this.router.navigate(['/profile']);
        },
        error: error => {
          /*  console.log("Handle Error: ", error); */
          this.translate.get('You must enter a title for exhibition edit. Go to the first step and enter a title').subscribe(res => {
            /* console.log(res); */
            this.toastr.error(res);
          });
        }
      });
      this.triggerCloseModalEvent();
    }
  }

  onConfirmSubmitNo() {
    this.triggerCloseModalEvent();
  }

  /*
 * Triger close event to the parent component
 */
  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }
}
