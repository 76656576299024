import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WorksofartComponent } from './worksofart/worksofart.component';
import { EExhibitionsComponent } from './e-exhibitions/e-exhibitions.component';
import { VrpageComponent } from './vrpage/vrpage.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ForgotPassword2Component } from './forgot-password2/forgot-password2.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileCuratorComponent } from './profile-curator/profile-curator.component';
import { AddanartworkComponent } from './addanartwork/addanartwork.component';
import { AuthGuard } from './helpers/auth.guard';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { ParentCreateExhibitionComponent } from './parent-create-exhibition/parent-create-exhibition.component';
import { PreviewWorkofartComponent } from './preview-workofart/preview-workofart.component';
import { PreviewExhibitionComponent } from './preview-exhibition/preview-exhibition.component';
import { PreviewFavouritesProfileComponent } from './preview-favourites-profile/preview-favourites-profile.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { EditExhibitionComponent } from './edit-exhibition/edit-exhibition.component';
import { SetExhibitionComponent } from './set-exhibition/set-exhibition.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { EExhibitionsVrComponent } from './e-exhibitions-vr/e-exhibitions-vr.component';
import { EExhibitionsVr2Component } from './e-exhibitions-vr2/e-exhibitions-vr2.component';
import { EExhibitionsVrCustomComponent } from './e-exhibitions-vr-custom/e-exhibitions-vr-custom.component';
import { ConfirmMessageComponent } from './confirm-message/confirm-message.component';
import { ConfirmExhibitionComponent } from './confirm-exhibition/confirm-exhibition.component';
import { DeleteWorkComponent } from './delete-work/delete-work.component';
import { DeleteExhibitionComponent } from './delete-exhibition/delete-exhibition.component';
import { DeleteProfileUserComponent } from './delete-profile-user/delete-profile-user.component';
import { DeleteProfileCuratorComponent } from './delete-profile-curator/delete-profile-curator.component';
import { PreviewCuratorComponent } from './preview-curator/preview-curator.component';
import { EditWorkCuratorComponent } from './edit-work-curator/edit-work-curator.component';
import { EditExhibitionCuratorComponent } from './edit-exhibition-curator/edit-exhibition-curator.component';
import { PreviewFullImageComponent } from './preview-full-image/preview-full-image.component';

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: 'worksofart', component: WorksofartComponent },
  { path: 'e-exhibitions', component: EExhibitionsComponent },
  { path: 'vrpage', component: VrpageComponent },
  { path: 'footer', component: FooterComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'forgot-password2', component: ForgotPassword2Component },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'profile-curator', component: ProfileCuratorComponent, canActivate: [AuthGuard] },
  { path: 'addanartwork', component: AddanartworkComponent, canActivate: [AuthGuard] },
  { path: 'create-exhibition', component: ParentCreateExhibitionComponent, canActivate: [AuthGuard] },
  { path: 'terms-of-use', component: TermsOfUseComponent },
  { path: 'preview-workofart', component: PreviewWorkofartComponent },
  { path: 'preview-favourites-profile', component: PreviewFavouritesProfileComponent, canActivate: [AuthGuard] },
  { path: 'preview-curator', component: PreviewCuratorComponent, canActivate: [AuthGuard] },
  { path: 'profile/:id', component: ProfileComponent },
  { path: 'profile-curator/:id', component: ProfileCuratorComponent, canActivate: [AuthGuard] },
  { path: 'preview-exhibition/:id', component: PreviewExhibitionComponent },
  { path: 'search-results', component: SearchResultsComponent },
  { path: 'edit-exhibition/:id', component: EditExhibitionComponent, canActivate: [AuthGuard]  },
  { path: 'set-exhibition', component: SetExhibitionComponent, canActivate: [AuthGuard]  },
  { path: 'edit-profile', component: EditProfileComponent, canActivate: [AuthGuard] },
  { path: 'confirm-message', component: ConfirmMessageComponent, canActivate: [AuthGuard] },
  { path: 'confirm-exhibition', component: ConfirmExhibitionComponent, canActivate: [AuthGuard] },
  { path: 'delete-work', component: DeleteWorkComponent, canActivate: [AuthGuard] },
  { path: 'delete-exhibition', component: DeleteExhibitionComponent, canActivate: [AuthGuard] },
  { path: 'delete-profile-user', component: DeleteProfileUserComponent, canActivate: [AuthGuard] },
  { path: 'delete-profile-curator', component: DeleteProfileCuratorComponent, canActivate: [AuthGuard] },
  { path: 'e-exhibitions-vr/:id', component: EExhibitionsVrComponent },
  { path: 'e-exhibitions-vr2/:id', component: EExhibitionsVr2Component },
  { path: 'e-exhibitions-vr-custom/:id', component: EExhibitionsVrCustomComponent },
  { path: 'preview-full-image/:id', component: PreviewFullImageComponent },
  { path: 'edit-exhibition-curator/:id', component: EditExhibitionCuratorComponent, canActivate: [AuthGuard] },
  { path: 'edit-work-curator/:id', component: EditWorkCuratorComponent, canActivate: [AuthGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
