import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { DataLayerService } from '../services/data-layer.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SignupComponent } from '../signup/signup.component';
import { LoginComponent } from '../login/login.component';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';
import { SearchComponent } from '../search/search.component';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit {
  public subscription: Subscription;
  public authenticated_user: any;

  public firstname: any;
  public lastname: any;
  public approved_works_info: any = [];
  user_id: string = "";
  role: string = "";

  modalRef?: BsModalRef;

  public isArtist: boolean = false;
  public isCurator: boolean = false;

  public isEdited: boolean = false;

  public navbarCollapsed = true;

  public NavLink1: boolean = false;
  public NavLink2: boolean = false;
  public NavLink3: boolean = false;
  public NavLink4: boolean = false;

  public Link1: boolean = false;
  public Link2: boolean = false;

  @ViewChild('navbarToggler') navbarToggler: ElementRef;

  constructor(
    public authService: AuthService,
    public toastr: ToastrService,
    public modalService: BsModalService,
    public userService: UserService,
    public dataLayerService: DataLayerService,
    private spinnerService: NgxSpinnerService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.user_id = user[0]._id;
        this.role = user[0].role;
        if (this.role === 'artist') {
          this.firstname = user[0].firstname;
          this.lastname = user[0].lastname;
          this.isArtist = true;
          this.isCurator = false;

          //update profile details if they changed at edit profile component
          this.spinnerService.show();

          this.userService.getSpecificArtist(this.user_id).pipe().subscribe({
            next: data => {
              this.firstname = data["_items"][0].firstname;
              this.lastname = data["_items"][0].lastname;

              // Hide spinner
              this.spinnerService.hide();
            },
            error: error => {
              /*  console.log("Handle Error: ", error); */
              // Handle Error Here
            }
          });
        }
        if (this.role === 'curator') {
          this.isCurator = true;
          this.isArtist = false;
        }
      }
    });

    this.NavLink1 = true;
    this.NavLink2 = false;
    this.NavLink3 = false;
    this.NavLink4 = true;

    this.Link1 = true;
    this.Link2 = false;

  }

  openModalSignup() {
    this.modalRef = this.modalService.show(SignupComponent);

    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  openModalLogin() {
    this.modalRef = this.modalService.show(LoginComponent);

    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  openModalSearch() {
    this.modalRef = this.modalService.show(SearchComponent);

    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.content.event.subscribe(value => {
      this.modalRef.hide();
    });
  }

  openModalEditProfile() {
    this.modalRef = this.modalService.show(EditProfileComponent);

    this.modalRef.content.closeBtnName = 'Close';

    this.modalRef.content.event.subscribe(value => {
      this.modalRef.hide();
    });
  }

  logoutUser() {
    this.authService.logout();
    this.dataLayerService.removeUser();
  }

  /* getNotification(e) {
    console.log(e)
  } */

  useLanguage(language: string) {
    this.translate.use(language);

    if (language === 'el') {
      this.NavLink1 = true;
      this.NavLink2 = false;
      this.NavLink3 = false;
      this.NavLink4 = true;

      this.Link1 = true;
      this.Link2 = false;
    }
    else {
      this.NavLink1 = false;
      this.NavLink2 = true;
      this.NavLink3 = true;
      this.NavLink4 = false;

      this.Link2 = true;
      this.Link1 = false;
    }
  }

  toggleNavbarCollapsing() {
    this.navbarCollapsed = !this.navbarCollapsed;
    /* console.log("navbarCollapsed boolean timhhh:", this.navbarCollapsed); */
  }

  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }

  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }
}
