import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EventEmitter } from '@angular/core';
import { LoginComponent } from '../login/login.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  artist: any = {
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    born: { "day": 23, "month": 10, "year": 2001 }
  };

  dayRange: Array<Number> = []
  monthRange: Array<Number> = []
  yearRange: Array<Number> = []

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;

  constructor(private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private dataLayerService: DataLayerService,
    public modalService: BsModalService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.dayRange = this.dataLayerService.day_range;
    this.monthRange = this.dataLayerService.month_range;
    this.yearRange = this.dataLayerService.year_range;
  }

  openModalLogin() {
    this.modalRef = this.modalService.show(LoginComponent);
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  onSignupSubmit(userForm: NgForm) {
    const born_to_dt = new Date(this.artist.born.year + '-' + this.artist.born.month + '-' + this.artist.born.day);
    const born_to_UTC_string = born_to_dt.toUTCString();

    // Request body
    const signup_res = {
      firstname: this.artist.firstname,
      lastname: this.artist.lastname,
      email: this.artist.email,
      password: this.artist.password,
      born: born_to_UTC_string
    };

    // Signup Request
    this.authService.registerUser(signup_res)
      .pipe()
      .subscribe({
        next: data => {
          /* console.log(this.artist); */
          this.translate.get('A registration confirmation email has been sent to you. Please check your inbox and follow the confirmation process').subscribe(res => {
            /* console.log(res); */
            this.toastr.success(res);
          });

          this.triggerCloseModalEvent();
          this.openModalLogin();
        },
        error: error => {
          if (error.status === 401) {
            this.translate.get('Invalid email address or password').subscribe(res => {
              /* console.log(res); */
              this.toastr.error(res);
            });
          }
          else if (error.status === 422) {
            this.translate.get('This email address is not unique').subscribe(res => {
              /* console.log(res); */
              this.toastr.error(res);
            });
          }
          else {
            this.translate.get('Something went wrong. Please try again.').subscribe(res => {
              /* console.log(res); */
              this.toastr.error(res);
            });
          }
        }
      });
  }

  /*
  * Triger close event to the parent component
  */
  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }

  onTerms() {
    this.triggerCloseModalEvent();
    this.router.navigate(['/terms-of-use']);
  }
}
