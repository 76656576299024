<img class="photo-cover" src="assets/images/home-photo-new-cover-png.jpg">
<div class="container">
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-body text-center">
                    <h3 class="title1 card-title">{{'2gether' | translate}}</h3>
                    <h1 class="title2" style="font-weight: bold;">{{'Becoming part of the art together!' | translate}}</h1>
                    <h6 class="paragraph1">{{'Upload your works, create your own digital exhibition' | translate}}<br>{{'and share your artistic side with the whole world.' | translate}}</h6>
                    <div class="row" style="text-align: left;">
                        <button *ngIf="authService.notLoggedIn()" class="buttonlogin" type="submit" (click)="openModalLogin()">{{'Log in' | translate}}</button>
                        <button *ngIf="authService.notLoggedIn()" class="buttonsignup" type="submit" (click)="openModalSignup()">{{'Sign up' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row row_two_cards" style="margin-bottom: 50px;">
        <div class="col-md-12 col-sm-12 col-lg-6" style="padding-left: 0px; padding-right: 0px;">
            <div class="card2">
                <div class="text-center">
                    <h3 class="title3">{{'Discover...' | translate}}</h3>
                    <h1 class="title4">{{'Artworks' | translate}}</h1>
                    <img class="photo2" src="assets/images/fasianos.jpg">
                    <h6 class="paragraph2">{{'Art and technology join forces, shaping a field of artistic expression for everyone! Discover works of art and dare to share your own creations with the public.' | translate}}</h6>
                    <button class="buttondiscover" type="submit" [routerLink]="['/worksofart']" [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{exact:true}">{{'Discover them all' | translate}}</button>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6 card-ekthesis" style="padding-left: 0px; padding-right: 0px;">
            <div class="card2">
                <div class="text-center">
                    <h3 class="title5">{{'Explore...' | translate}}</h3>
                    <h1 class="title6">{{'Digital Exhibitions' | translate}}</h1>
                    <img class="photo3" src="assets/images/Screenshot_1.jpg">
                    <h6 class="paragraph3">{{'Wondering what it’s like to curate art exhibitions? Now, you have the opportunity to create your own digital 3D exhibition with your own artworks and/or with the works of other artists and users of the platform. Browse through the digital rooms and see art from another point of view!' | translate}}</h6>
                    <button class="buttonexplore" type="submit" [routerLink]="['/e-exhibitions']" [routerLinkActive]="['active']"
                    [routerLinkActiveOptions]="{exact:true}">{{'Explore them all' | translate}}</button>
                </div>
            </div>
        </div>
    </div>

</div>
    <br>
    <div class="row row_vr" style="margin-top: 80px; margin-bottom: 30px; padding-bottom: 25px; width: 100%; margin-left: 0px;">
        <div class="col-md-12 col-sm-12 col-lg-6"> <!-- col-6 --> 
            <div class="card5" style="margin-top: 25px;">
                <h3 class="title9">{{'Enjoy' | translate}}</h3>
                <h1 class="title10">{{'MOMus VR' | translate}}</h1>
                <p class="paragraph6">{{'Don’t miss the chance to live the ultimate VR experience at MOMus! Visit the MOMus-Museum of Contemporary Art and watch famous artworks come to life with the help of virtual reality technology. Having as companions our own digital guides, you will travel back in time, following an educational yet entertaining journey through the history of modern and contemporary art.' | translate}}</p>
                <div>
                    <button class="buttonbooktickets" type="submit" [routerLink]="['/vrpage']">{{'Learn more' | translate}}</button>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-6" style="text-align: center;"> <!-- col-6 --> 
            <img class="photovr" src="assets/images/maria_edit_web.jpg">
        </div>
    </div>

    <div style="margin-top: 50px;">
        <video class="video" poster="assets/images/video_preview.png" controls>
            <source src="assets/images/Video.mp4" type="video/mp4">
        </video>
    </div>
    <br>

    <div class="row" style="margin-top: 50px; width: 100%;margin-left: 0px;margin-right: 0px;">
        <div class="col-md-12 col-sm-12 col-lg-4" style="padding-left: 0px; padding-right: 0px;">
            <div class="card4">
                <h3 class="title7">{{'Learn...' | translate}}</h3>
                <h1 class="title8">{{'about' | translate}}<br>{{'2gether' | translate}}</h1>
                <p class="paragraph4">{{'Technological possibilities in the digital age create a new field of opportunity for cultural institutions to reach the public, but also to interact with them in a creative and innovative way. 2gether gives a new meaning to the way art is perceived, through the use of a state-of-the-art technological-' | translate}}</p>
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-4" style="padding-right: 0px; padding-left: 0px;">
            <div class="firstimg" style="text-align: center; margin-top: 20px;">
                <img class="photo4" src="assets/images/curious1.jpg" width="292" height="219">
            </div>
            <div class="secondimg" style="text-align: center; margin-top: 20px;">
                <img class="photo5" src="assets/images/front_page.JPG" width="292" height="219">
            </div>
        </div>
        <div class="col-md-12 col-sm-12 col-lg-4" style="padding-left: 0px; padding-right: 0px;">
            <div class="card4">
                <div class="transform_last">
                    <p class="paragraph5">{{'conceptual framework divided into two axes: the digital experience of a crowdsourcing platform and the on-site visit to MOMus’ VR exhibition. 2gether reactivates the possibility for stakeholders to be active members of the art community and allows us to reinvent the art world in the 21st century by engaging artists and art lovers alike, despite geographical barriers.' | translate}}</p>
                    <a class="buttonlearnmore" type="button" href="http://www.2gether-project.gr/" target="_blank" style="text-decoration: none;">{{'Learn more' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
    <br>
    <br>
    <br>



