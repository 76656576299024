import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-profile-user',
  templateUrl: './delete-profile-user.component.html',
  styleUrls: ['./delete-profile-user.component.css']
})
export class DeleteProfileUserComponent implements OnInit {
  @Input('owner_id_user') public owner_id_user: string = "";

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  _id: any;
  role: string = "";

  work: any = {
    owner_id: '',
  };

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  constructor(private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private dataLayerService: DataLayerService,
    public modalService: BsModalService,
    private spinnerService: NgxSpinnerService,
    private userService: UserService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.authenticated_user = true;
        this.firstname = user[0].firstname;
        this.lastname = user[0].lastname;
        this.user_id = user[0]._id;
        this.work.owner_id = this.user_id;
        this.role = user[0].role;
      }
    });

    /* console.log("owner_id που περναει στο confirm modal:", this.owner_id_user); */
  }

  onConfirmSubmitNo() {
    this.triggerCloseModalEvent();
  }

  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }

  onSubmit() {
    const owner_id_artist = this.user_id;

    if (this.authenticated_user && this.role === 'artist') {
      this.spinnerService.show();

      this.userService.deleteAccount(owner_id_artist).pipe().subscribe({
        next: data => {
          this.translate.get('Your account has been deleted!').subscribe(res => {
            /*  console.log(res); */
            this.toastr.success(res);
          });
          this.authService.logout();
          this.dataLayerService.removeUser();
          this.router.navigate(['/']);

          // Hide spinner
          this.spinnerService.hide();
        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
        }
      });
      this.triggerCloseModalEvent();
    }
  }
}
