<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div class='container'>
    <div class="row">
        <div class="col">
            <button class="add_an_artwork" [routerLink]="['/addanartwork']"><i class="fa fa-plus"></i> {{'Add an artwork' | translate}}</button>
            <button class="create_e_exhibition"><i class="fa fa-plus"></i> {{'Create digital exhibition' | translate}}</button>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <p class="name">{{firstname}} {{lastname}}</p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <h3 class="header" *ngIf="isForAdd">{{'Create your digital exhibition' | translate }}</h3>
            <h3 class="header" *ngIf="isForEdit">{{'Edit your digital exhibition' | translate }}</h3>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <p class="paragraph_warn">{{'* You cannot have more than one active digital exhibition' | translate}}</p>
        </div>
    </div>

    <div class="row">
        <p class="create_exhibition_steps"><span style="font-weight: bold;">{{'Step 1' | translate}}</span> {{'of 6: Digital exhibition naming' | translate}}</p>
    </div>

    <!-- form start -->
    <form [formGroup]="exhibitionStepOneForm">
        <div class="row row_input">
            <div class="column column_input">
                <input type="text" class="no-outline title_exhibition form-control"
                    id="title-exhibition" [maxlength]="100" [(ngModel)]="exhibition_obj.title" name="title-exhibition" formControlName="title" placeholder="{{'Add a title' | translate}}"
                    required>
                <hr style="margin-bottom: 0px; margin-top: 5px; width: 75%;">
            </div>
            <p class="insertwordsmax">{{'(insert 100 characters max)' | translate}}</p>
            <p *ngIf="exhibition_obj.title !== undefined" class="characters_remaining">{{100 - exhibition_obj.title.length }} {{'/100 Characters remaining' | translate}}</p>
        </div>

        <br>
        <br>

        <div class="row row_next">
            <button (click)="titleSubmit()" class="exhibition_btn_next ml-auto" type="button">{{'Next' | translate}}</button>
        </div>

        <div class="row row_cancel">
            <div class="ml-auto">
                <a [routerLink]="['/profile']" class="exhibition_cancel" type="button">{{'Cancel' | translate}}</a>
            </div>
        </div>
    </form>
    <!-- form end -->

</div>