import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  day_range: Array<Number> = [];
  month_range: Array<Number> = [];
  year_range: Array<Number> = [];

  day_range_now: Array<Number> = [];
  month_range_now: Array<Number> = [];
  year_range_now: Array<Number> = [];

  private user_info = new BehaviorSubject(
    []
  );

  public currentUser = this.user_info.asObservable();

  constructor(
    private authService: AuthService,
    private userService: UserService
  ) {
    this.initializeDates();
    this.restoreStateOnRefresh();
  }

  /*
  * Set authenticated user
  */
  public addUser(layer: any) {
    this.user_info.next(
      [...this.user_info.getValue(), layer]
    );
  }

  /*
  * Return user
  */
  public getUser() {
    return this.user_info;
  }

  /*
  * Get user's firstname
  */
  public getUserFirstName() {
    return this.user_info.value[0]['firstname'];
  }

  /*
  * Get user's lastname
  */
  public getUserLastName() {
    return this.user_info.value[0]['lastname'];
  }

  /*
  * Remove user
  */
  public removeUser() {
    this.user_info.next([]);
  }

  /*
  * Check if user is logged in and restore user subject
  */
  public restoreStateOnRefresh() {
    // Keep state on page refresh
    const isNotLoggedIn = this.authService.notLoggedIn();
    if (!isNotLoggedIn) {
      const role = this.authService.getUserRoleFromToken();
      if (role === 'curator') {
        this.userService.getProfileCurator()
          .pipe()
          .subscribe({
            next: res => {
              this.addUser(res);
            },
            error: error => {
              if (error.status == 401) {
              }
            }
          });
      }
      else {
        this.userService.getProfile()
          .pipe()
          .subscribe({
            next: res => {
              this.addUser(res);
            },
            error: error => {
              if (error.status == 401) {
              }
            }
          });
      }
    }
  }

  /*
  * Initialize date objects
  */
  public initializeDates() {
    // initialize day range
    for (let i = 1; i <= 31; i++) {
      this.day_range.push(i);
    }

    // initialize month range
    for (let i = 1; i <= 12; i++) {
      this.month_range.push(i);
    }

    // initialize year range
    for (let i = 1942; i <= 2003; i++) {
      this.year_range.push(i);
    }

    const dateObj = new Date();
    const month_now = dateObj.getUTCMonth() + 1; //months from 1-12
    const day_now = dateObj.getUTCDate();
    const year_now = dateObj.getUTCFullYear();

    this.day_range_now.push(day_now);
    this.month_range_now.push(month_now);
    this.year_range_now.push(year_now);
  }
}
