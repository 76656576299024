<div class="container">
    <h1 class="search_results">{{'Search results:' | translate}}</h1>
    <br>
    <p *ngIf="isSearchCompleted" class="paragraph">{{'No results were found for your search' | translate}}</p>
    <br>
    <div class="row row-cols-1 row-cols-md-2 g-3 row-cols-sm-2 row-cols-lg-3 row-cols-xl-5">
        <div *ngFor="let item of workofart_items">
            <app-worksofart-card [workofart_item]="item"
                [workofart_items]="workofart_items"
                [isBookmarkVisible]="isBookmarkVisible"
                [isRatethisVisible]="isRatethisVisible" 
                [isRatebuttonVisible]="isRatebuttonVisible" 
                [isVerticallineVisible]="isVerticallineVisible"
                [isCheckboxVisible]="isCheckboxVisible"
                [isSettings1Visible]="isSettings1Visible"
                [isSettings2Visible]="isSettings2Visible"
                [isWorkStatusVisible]="isWorkStatusVisible"
                [isStatus1Visible]="isStatus1Visible"
                [isStatus2Visible]="isStatus2Visible"
                [isStatus3Visible]="isStatus3Visible"
                [isBookMarkChecked]="item.bookmark_selected"
                [isUsersOwnProfile_Rating2]="isUsersOwnProfile_Rating2"
                (RatingUpdate)=RatingUpdate($event) 
                (ModalUpdated)=ModalCompleted($event)
                (BookmarkUpdated)=BookmarkUpdated($event) 
                ></app-worksofart-card>
        </div>
    </div>
</div>
