import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmMessageComponent } from '../confirm-message/confirm-message.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-addanartwork',
  templateUrl: './addanartwork.component.html',
  styleUrls: ['./addanartwork.component.css']
})
export class AddanartworkComponent implements OnInit {
  work: any = {
    owner_id: '',
  };

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;
  bsModalRef: BsModalRef;

  @ViewChild('file') attachment: any;

  public addanartworkForm: FormGroup;
  public imagePath: any;

  /* imgURL: any = ""; */
  imgURL: any = "assets/images/empty-img.png";

  first_name: string = "";
  last_name: string = "";
  user_id: string = "";
  _id: any;

  dayRange: Array<Number> = [];
  monthRange: Array<Number> = [];
  yearRange: Array<Number> = [];

  img_width: any = 'img_width';
  img_height: any = 'img_height';

  public disableSelect: string = "false";
  public disableSelectDate: string = "false";

  public isForAdd: boolean = false;
  public exhibition_items: any = [];

  constructor(
    private toastr: ToastrService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public translate: TranslateService,
    public modalService: BsModalService,
    private spinnerService: NgxSpinnerService,
    private fb: FormBuilder) { }


  ngOnInit(): void {
    this.dayRange = this.dataLayerService.day_range_now;
    this.monthRange = this.dataLayerService.month_range_now;
    this.yearRange = this.dataLayerService.year_range_now;

    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.first_name = user[0].firstname;
        this.last_name = user[0].lastname;
        this.user_id = user[0]._id;
        this.work.owner_id = this.user_id;

        this.spinnerService.show();

        this.userService.getWorkOfArtist(this.user_id).pipe().subscribe({
          next: data => {
            /* console.log(data); */
            if (data["_items"].length === 20) {
              this.translate.get('You have exceeded the limit of your artworks.(20 artworks per artist)').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });

        this.spinnerService.show();

        this.userService.getExhibitionOfArtist(this.user_id)
          .pipe()
          .subscribe({
            next: data => {
              this.exhibition_items = data["_items"];
              /* console.log('My exhibition:', this.exhibition_items); */

              if (this.exhibition_items.length < 1) {
                this.isForAdd = true;
              }
              else {
                this.isForAdd = false;
              }

              // Hide spinner
              this.spinnerService.hide();
            },
            error: error => {
              /* console.log("Handle Error: ", error); */
              // Handle Error Here
            }
          });
      }
    });

    this.addanartworkForm = this.fb.group({
      title: "",
      description: "",
      category: "category 1",
      width: "", /* {{img_width}} */
      height: "",
      tags: ["", "", "", "", ""] /* tags: ["tag1", "tag2", "tag3", "tag4", "tag5"] */
    });
  }

  preview(files: any) {
    if (files.length === 0)
      return;

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      /* console.log(this.imgURL); */
    }
    this.attachment.nativeElement.value = '';
  }

  fileChangeEvent(fileInput: any) {
    const max_size = 12100000;
    /* const max_size = 121000; example for us*/

    if (fileInput.target.files[0].size > max_size) {
      this.translate.get('Maximum size allowed is 12.100 Mb').subscribe(res => {
        /*  console.log(res);
         console.log("THE SIZEEEEEEE IS", fileInput.target.files[0].size); */
        this.toastr.error(res);
      });
      this.imgURL = "assets/images/empty-img.png";
      this.img_width = 'img_width';
      this.img_height = 'img_height';
    }

    else {
      if (fileInput.target.files && fileInput.target.files[0]) {

        const reader = new FileReader();
        reader.onload = (e: any) => {
          const image = new Image();
          image.src = e.target.result;
          /* console.log(image.src); */
          image.onload = rs => {
            /* console.log(rs.currentTarget); */
            const img_height = rs.currentTarget['height'];
            const img_width = rs.currentTarget['width'];

            /* console.log(img_height);
            console.log(img_width); */

            this.img_width = img_width;
            this.img_height = img_height;

            this.imgURL = image.src;
            /* console.log(this.imgURL); */
          };
        };

        reader.readAsDataURL(fileInput.target.files[0]);
        this.attachment.nativeElement.value = '';
      }
    }

    /* if (fileInput.target.files && fileInput.target.files[0]) {

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        console.log(image.src);
        image.onload = rs => {
          console.log(rs.currentTarget);
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          
          console.log(img_height);
          console.log(img_width);

          this.img_width = img_width;
          this.img_height = img_height;

          this.imgURL = image.src;
          console.log(this.imgURL);
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
      this.attachment.nativeElement.value = '';
    } */
  }

  clearInputMethod2() {
    this.addanartworkForm.setValue(
      {
        title: '',
        description: ''
      });
  }

  deleteImage(imgURL: any) {
    this.imgURL = "assets/images/empty-img.png";
    this.img_width = 'img_width';
    this.img_height = 'img_height';
  }

  openModalConfirm() {
    const initialState = {
      title: this.addanartworkForm.value.title,
      width: this.img_width,
      height: this.img_height,
      description: this.addanartworkForm.value.description,
      tags: this.addanartworkForm.value.tags.split(" "),
      image: this.imgURL,
      category: this.addanartworkForm.value.category,
      day: this.dataLayerService.day_range_now,
      month: this.dataLayerService.month_range_now,
      year: this.dataLayerService.year_range_now
    };

    /* console.log(this.addanartworkForm.value.tags.split(" ").length); */

    if (this.imgURL === 'assets/images/empty-img.png' || this.addanartworkForm.value.title === '' || this.addanartworkForm.value.description === '') {
      this.translate.get('Please add an image and fill in all fields').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    }

    else if (this.addanartworkForm.value.tags.split(" ").length > 5) {
      this.translate.get('Please enter 5 tags maximum').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    }

    else {
      this.modalRef = this.modalService.show(ConfirmMessageComponent, { initialState });
      this.event.emit('CLOSE');

      this.modalRef.content.event.subscribe(res => {
        this.modalRef.hide();
      });
    }
  }
}