<div class="container">
    <h1 class="title">{{'Create your account' | translate}}</h1>
    <form #userForm="ngForm" (NgSubmit)="userForm.form.valid && onSignupSubmit(userForm)">
        <div class="row justify-content-center">
            <div class="mb-4">
                <label for="exampleInputFirstName1" class="form-label"></label>
                <input type="text" name="firstname" pattern="^\S+$" [(ngModel)]="artist.firstname" #firstname="ngModel"
                    class="form-control firstname-input" id="exampleInputFirstName1" placeholder="{{ 'First name' | translate }}" required>
                <small class="smallclass" *ngIf="firstname.touched && !firstname.valid">{{'Enter your first name!' | translate}}</small>
                <br>
                <small class="smallclass" *ngIf="firstname.touched && !firstname.valid">{{'Without using spaces.' | translate}}</small>
            </div>
            <div class="mb-4">
                <label for="exampleInputLastName1" class="form-label"></label>
                <input type="text" name="lastname" pattern="^\S+$" [(ngModel)]="artist.lastname" #lastname="ngModel"
                    class="form-control lastname-input" id="exampleInputLastName1" placeholder="{{ 'Last name' | translate }}" required>
                <small class="smallclass" *ngIf="lastname.touched && !lastname.valid">{{'Enter your last name!' | translate}}</small>
                <br>
                <small class="smallclass" *ngIf="lastname.touched && !lastname.valid">{{'Without using spaces.' | translate}}</small>
            </div>
            <div class="mb-4">
                <label for="exampleInputEmail1" class="form-label"></label>
                <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="email" [(ngModel)]="artist.email" #email="ngModel" email
                    class="form-control email-input" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="{{ 'Email address' | translate }}" email required>
                    <small class="smallclass" *ngIf="email.touched && !email.valid">{{'Enter your email address!' | translate}}</small>
                    <br>
                    <small class="smallclass" *ngIf="email.touched && !email.valid">{{'Must be a valid email address.' | translate}}</small>
            </div>
            <div class="mb-4">
                <label for="exampleInputPassword1" class="form-label"></label>
                <input type="password" name="password" [(ngModel)]="artist.password" #password="ngModel" minlength="6"
                    class="form-control pass-input" id="exampleInputPassword1" placeholder="{{ 'Password' | translate }}" required>
                <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Enter your password!' | translate}}</small>
                <br>
                <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Password should be at least 6 characters long.' | translate}}</small>
            </div>
           
        </div>

        <h6 class="birthday">{{'Birthday:' | translate}}</h6><br>
        <div class="mb-4">
            <select [(ngModel)]="artist.born.day" #born.day="ngModel" name="day" type="date" class="selectday" required>
                <option *ngFor="let day of dayRange" value={{day}}>
                    {{day}}
                </option>
            </select>
            <select [(ngModel)]="artist.born.month" #born.month="ngModel" name="month" type="date" class="selectmonth"
                required>
                <option *ngFor="let month of monthRange" value={{month}}>
                    {{month}}
                </option>
            </select>
            <select [(ngModel)]="artist.born.year" #born.year="ngModel" name="year" type="date" class="selectyear"
                required>
                <option *ngFor="let year of yearRange" value={{year}}>
                    {{year}}
                </option>
            </select>
        </div>

        <div class="mb-4" style="text-align: center;margin-right: 68px;margin-left: 66px;">
            <label class="label_checkbox" for="mycheckboxid">
                <input class="class_checkbox" style="accent-color: black;" type="checkbox" name="mycheckboxname"
                    id="mycheckboxid" ngModel required>
                {{'I declare that I am over 18 years old and agree to the' | translate}}&nbsp;<a
                    (click)="onTerms()" style="color: green; cursor: pointer; text-decoration: underline;"
                    class="terms_and_conditions">{{'terms of use'
                    | translate}}</a>
            </label>
        </div>

        <div class="mb-4" style="text-align: center;margin-right: 68px;margin-left: 66px;">
            <p>{{'*Immediately after registration, you will receive a confirmation email to the email you have registered. Please check your inbox so you can log in to your account.' | translate}}</p>
        </div>

        <div class="mb-4">
            <div class="d-flex justify-content-center">
                <button type="submit" class="btn btn-black" [disabled]="!userForm.valid"
                    (click)="onSignupSubmit(userForm)">{{'CREATE ACCOUNT' | translate}}</button>
            </div>
        </div>
    </form>
   <!--  <div class="mb-4">
        <div class="d-flex justify-content-center">
            <a [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}"
                class="alreadyhaveanaccount" (click)="openModalLogin()">{{'Already have an account? Click to login!' | translate}}</a>
            <br>
            <br>
        </div>
    </div> -->
    <p class="paragraph-proteceted">
        {{'Protected by reCAPTCHA and subject to the Google Privacy' | translate}} <br> {{'Policy and Terms of Service' | translate}}</p>
</div>