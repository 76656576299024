import { Component, OnInit } from '@angular/core';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-preview-exhibition',
  templateUrl: './preview-exhibition.component.html',
  styleUrls: ['./preview-exhibition.component.css']
})
export class PreviewExhibitionComponent implements OnInit {
  title_exhi: string = "";
  firstname: string = "";
  lastname: string = "";
  exhi_user_id: string = "";
  exhibition_id: string = "";

  user_id: string = "";
  role: string = "";

  /* isUser = false; */

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: boolean = false;

  public isBookmarkVisible: boolean = true;
  public isRatethisVisible: boolean = true;
  public isRatebuttonVisible: boolean = true;
  public isVerticallineVisible: boolean = true;
  public isCheckboxVisible: boolean = true;
  public isSettings1Visible: boolean = true;
  public isSettings2Visible: boolean = true;
  public isWorkStatusVisible: boolean = true;
  public isStatus1Visible: boolean = true;
  public isStatus2Visible: boolean = true;
  public isStatus3Visible: boolean = true;

  public isUsersOwnProfile_Rating2: boolean = false;

  public exhibition_workofart_items: any = [];

  public isThisExhib: boolean = false;
  public isThisExhib2: boolean = false;
  public isThisExhib3: boolean = false;

  constructor(
    private router: Router,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public activatedRoute: ActivatedRoute,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const exhibitionID = params.get("id");
      this.exhibition_id = exhibitionID;

      this.subscription = this.dataLayerService.currentUser.subscribe(
        user => {
          if (user.length > 0) {
            this.authenticated_user = true;
            this.user_id = user[0]._id;
            this.role = user[0].role;

            this.isCheckboxVisible = false;
            this.isSettings1Visible = false;
            this.isSettings2Visible = false;
            this.isWorkStatusVisible = false;
            this.isStatus1Visible = false;
            this.isStatus2Visible = false;
            this.isStatus3Visible = false;
            this.isUsersOwnProfile_Rating2 = false;

            if (user[0]['role'] === 'artist') {
              this.isRatebuttonVisible = true;
              this.isRatethisVisible = true;
              this.isVerticallineVisible = true;
              this.isBookmarkVisible = true;
            }
            if (user[0]['role'] === 'curator') {
              this.isBookmarkVisible = false;
              this.isRatebuttonVisible = false;
              this.isRatethisVisible = false;
              this.isVerticallineVisible = false;
            }

            this.getWorksOfArtOfExhibition();
          }

          else {
            this.authenticated_user = false;

            this.isCheckboxVisible = false;
            this.isSettings1Visible = false;
            this.isSettings2Visible = false;
            this.isBookmarkVisible = false;
            this.isWorkStatusVisible = false;
            this.isStatus1Visible = false;
            this.isStatus2Visible = false;
            this.isStatus3Visible = false;
            this.isRatebuttonVisible = true;
            this.isRatethisVisible = true;
            this.isVerticallineVisible = true;

            this.getWorksOfArtOfExhibition();
          }
        });
    });
  }

  RatingUpdate(e: { rating_update: any }) {
    this.getWorksOfArtOfExhibition();
  }

  BookmarkUpdated(e: { bookmark_updated: any }) {
    this.getWorksOfArtOfExhibition();
  }

  //synarthsh gia handle event modal completed
  ModalCompleted(e: { modal_updated: any }) {
    this.getWorksOfArtOfExhibition();
  }

  getWorksOfArtOfExhibition() {
    this.exhibition_workofart_items = [];

    this.spinnerService.show();

    this.userService.getSpecificExhibition(this.exhibition_id).pipe().subscribe({
      next: data => {
        this.title_exhi = data["_items"][0].title;
        this.exhi_user_id = data["_items"][0].owner_id;
        let workofart_items_tmp = data["_items"][0].works;
        this.firstname = data["_items"][0].owner_id.firstname;
        /* console.log(this.firstname); */
        this.lastname = data["_items"][0].owner_id.lastname;

        if (this.firstname === 'Alkistis') {
          this.isThisExhib = true;
        }
       /*  console.log(this.isThisExhib); */

        if (this.firstname === 'Sofia') {
          this.isThisExhib2 = true;
        }
        /* console.log(this.isThisExhib2); */

        if (this.firstname !== 'Alkistis' && this.firstname !== 'Sofia') {
          this.isThisExhib3 = true;
        }
        /* console.log(this.isThisExhib3); */

        // If authenticated, get user's favourites
        if (this.authenticated_user) {
          this.userService.getFavouritesOfArtist(this.user_id)
            .pipe()
            .subscribe({
              next: data => {

                // create favourites_ids
                let favourites_ids = [];
                const artist_favourites_items = data['_items'];

                // Add work ids in favourites
                for (let j = 0; j < artist_favourites_items.length; j++) {
                  if (artist_favourites_items[j].work) {
                    favourites_ids.push(artist_favourites_items[j].work._id);
                  }
                }

                // Set bookmark for favourites
                for (let i = 0; i < workofart_items_tmp.length; i++) {
                  if (favourites_ids.includes(workofart_items_tmp[i]._id)) {
                    workofart_items_tmp[i].bookmark_selected = true;
                  }
                  else {
                    workofart_items_tmp[i].bookmark_selected = false;
                  }
                }

                // Set final work of arts array
                this.exhibition_workofart_items = workofart_items_tmp;
                /* console.log("Exhibition's Works of art:", this.exhibition_workofart_items); */

              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

        }

        else {
          // Set final work of arts array
          this.exhibition_workofart_items = workofart_items_tmp;
          /* console.log("Exhibition's Works of art:", this.exhibition_workofart_items); */
        }

        // Hide spinner
        this.spinnerService.hide();
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });
  }

  ExhibitionVR() {
    this.spinnerService.show();

    this.userService.getSpecificExhibition(this.exhibition_id).pipe().subscribe({
      next: data => {
        const exhibitionIDVR = data["_items"][0]._id;
        this.exhibition_id = exhibitionIDVR;

        // Hide spinner
        this.spinnerService.hide();
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });

    this.router.navigate(['/e-exhibitions-vr/' + this.exhibition_id]);
  }

  ExhibitionVR2() {
    this.spinnerService.show();

    this.userService.getSpecificExhibition(this.exhibition_id).pipe().subscribe({
      next: data => {
        const exhibitionIDVR = data["_items"][0]._id;
        this.exhibition_id = exhibitionIDVR;

        // Hide spinner
        this.spinnerService.hide();
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });

    this.router.navigate(['/e-exhibitions-vr2/' + this.exhibition_id]);
  }

  ExhibitionVR3() {
    this.spinnerService.show();

    this.userService.getSpecificExhibition(this.exhibition_id).pipe().subscribe({
      next: data => {
        const exhibitionIDVR = data["_items"][0]._id;
        this.exhibition_id = exhibitionIDVR;

        // Hide spinner
        this.spinnerService.hide();
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });

    this.router.navigate(['/e-exhibitions-vr-custom/' + this.exhibition_id]);
  }

  goToProfile() {
    if (this.role === 'artist') {
      this.router.navigate(['/profile']);
    }
    if (this.role === 'curator') {
      this.router.navigate(['/profile-curator']);
    }
  }

}
