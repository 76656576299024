import { Component, OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  artist: any = {
    firstname: '',
    lastname: '',
    password: '',
    born: { "day": '', "month": '', "year": '' }
  };

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";

  dayRange: Array<Number> = [];
  monthRange: Array<Number> = [];
  yearRange: Array<Number> = [];

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;

  public upload_date: string = '';

  constructor(private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private dataLayerService: DataLayerService,
    public modalService: BsModalService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.dayRange = this.dataLayerService.day_range;
    this.monthRange = this.dataLayerService.month_range;
    this.yearRange = this.dataLayerService.year_range;

    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.firstname = user[0].firstname;
        this.lastname = user[0].lastname;
        this.user_id = user[0]._id;

        this.artist.firstname = user[0].firstname;
        this.artist.lastname = user[0].lastname;
        this.artist.password = user[0].password;

        const created_dt = new Date(user[0].born);
        this.upload_date = created_dt.toLocaleDateString();
        /*  console.log("BORN:", this.upload_date); */
        const splitted = this.upload_date.split(("/"));
        this.artist.born.day = splitted[0];
        this.artist.born.month = splitted[1];
        this.artist.born.year = splitted[2];
      }
    });
  }

  onEditProfileSubmit(userForm: NgForm) {
    const born_to_dt = new Date(this.artist.born.year + '-' + this.artist.born.month + '-' + this.artist.born.day);
    const born_to_UTC_string = born_to_dt.toUTCString();

    // Request body
    const edit_res = {
      firstname: this.artist.firstname,
      lastname: this.artist.lastname,
      password: this.artist.password,
      born: born_to_UTC_string
    };

    // Edit Profile Request
    this.authService.editProfile(this.user_id, edit_res)
      .pipe()
      .subscribe({
        next: data => {
          /* console.log(data); */
         /*  console.log(this.artist); */
          this.translate.get('Your account details have changed').subscribe(res => {
            /* console.log(res); */
            this.toastr.success(res);
          });

          this.triggerCloseModalEvent();
          this.router.navigate(['/profile']);
          this.dataLayerService.restoreStateOnRefresh();
        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
        }
      });
  }

  /*
  * Triger close event to the parent component
  */
  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }
}
