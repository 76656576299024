import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataLayerService } from '../services/data-layer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-exhibition2',
  templateUrl: './create-exhibition2.component.html',
  styleUrls: ['./create-exhibition2.component.css']
})
export class CreateExhibition2Component implements OnInit {
  @Output() goToPreviousStep = new EventEmitter<{ step: number }>();
  @Output() exhibitionWorksCreated = new EventEmitter<{ works: [] }>();
  @Output() exhibitionTitleCreated = new EventEmitter<{ title: string }>();
  @Input('exhibition_obj') public exhibition_obj: any = [];

  public work_ids_arr: any = [];

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  _id: any;

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  public isBookmarkVisible: boolean = true;
  public isRatethisVisible: boolean = true;
  public isRatebuttonVisible: boolean = true;
  public isVerticallineVisible: boolean = true;
  public isSettings1Visible: boolean = true;
  public isSettings2Visible: boolean = true;
  public isNextVisible: boolean = true;
  public isBackVisible: boolean = true;
  public isCancelVisible: boolean = true;
  public isParagraphForApprovedworks: boolean = false;

  public isUsersOwnProfile_Rating: boolean = true;
  public isUsersOwnProfile_Rating2: boolean = true;

  public isPreview1Visible: boolean = false;
  public isPreview3Visible: boolean = false;

  public SelectedWorkid: string;

  public exhibition_items: any = [];
  public workofart_items: any = [];
  public workofart_items_all: any = [];
  public artist_favourites_items: any = [];

  public exhibitionStepTwoForm: FormGroup;

  public isForAdd: boolean = false;
  public isForEdit: boolean = false;

  constructor(
    public authService: AuthService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    public translate: TranslateService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    // Array must include all the previously selected work ids
    this.work_ids_arr = this.exhibition_obj.works;

    this.exhibitionStepTwoForm = this.fb.group({
      works: [this.exhibition_obj.works, Validators.compose([Validators.required])]
    });

    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.firstname = user[0].firstname;
          this.lastname = user[0].lastname;
          this.user_id = user[0]._id;

          //check if for upload exhibition or for edit
          this.spinnerService.show();

          this.userService.getExhibitionOfArtist(this.user_id)
            .pipe()
            .subscribe({
              next: data => {
                this.exhibition_items = data["_items"];
                /* console.log('My exhibition:', this.exhibition_items); */

                if (this.exhibition_items.length < 1) {
                  this.isForAdd = true;
                  this.isForEdit = false;
                }
                else {
                  this.isForAdd = false;
                  this.isForEdit = true;
                }

                /* console.log("ADD: ", this.isForAdd);
                console.log("EDDIT: ", this.isForEdit); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

          this.isBookmarkVisible = true;
          this.isRatethisVisible = false;
          this.isRatebuttonVisible = false;
          this.isVerticallineVisible = false;
          this.isSettings1Visible = false;
          this.isSettings2Visible = false;
          this.isUsersOwnProfile_Rating = true;
          this.isUsersOwnProfile_Rating2 = true;
          this.isPreview1Visible = true;

          this.workofart_items = [];
          this.spinnerService.show();

          this.userService.getWorkOfArtist(this.user_id)
            .pipe()
            .subscribe({
              next: data => {
                this.isUsersOwnProfile_Rating = true;
                this.isPreview1Visible = true;
                let workofart_items_tmp = data["_items"];

                this.userService.getFavouritesOfArtist(this.user_id)
                  .pipe()
                  .subscribe({
                    next: data => {
                      this.workofart_items_all = [];
                      this.artist_favourites_items = [];
                      let favourites_ids = [];
                      const artist_favourites_items = data['_items'];
                      this.artist_favourites_items = artist_favourites_items;

                      /* console.log("artist_favourites_items", this.artist_favourites_items); */

                      // Add work ids in favourites
                      for (let j = 0; j < artist_favourites_items.length; j++) {
                        if (artist_favourites_items[j].work) {
                          favourites_ids.push(artist_favourites_items[j].work._id);
                        }
                      }

                      // Set bookmark for favourites
                      for (let i = 0; i < workofart_items_tmp.length; i++) {
                        if (favourites_ids.includes(workofart_items_tmp[i]._id)) {
                          workofart_items_tmp[i].bookmark_selected = true;
                        } 
                        else {
                          workofart_items_tmp[i].bookmark_selected = false;
                        }
                      }

                      // Set final work of arts array
                      this.workofart_items = workofart_items_tmp;

                      // Filter based on artwork status
                      this.workofart_items = this.workofart_items.filter((obj) => {
                        return obj.status === "APPROVED";
                      });

                      // Sort based on artwork created(new first)
                      this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
                        return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                      });

                      // Set true/false for checkbox
                      for (let i = 0; i < this.workofart_items.length; i++) {
                        if (this.exhibition_obj.works.includes(this.workofart_items[i]._id)) {
                          this.workofart_items[i].checkbox_selected = true;
                        }
                      }

                      /* console.log("Successful response: ", this.workofart_items); */

                      if (this.workofart_items.length < 1) {
                        this.isParagraphForApprovedworks = true;
                      }

                    },
                    error: error => {
                      /* console.log("Handle Error: ", error); */
                      // Handle Error Here
                    }
                  });

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
        }
      });
  }

  SteptwoSubmit() {
    this.exhibitionWorksCreated.emit({ works: this.work_ids_arr });
  }

  GoBackToStepOne() {
    this.goToPreviousStep.emit({ step: 1 });
  }

  onCheckboxSelected(e: { is_selected: string }) {
    if (this.work_ids_arr.includes(e.is_selected)) {
      // Remove id from array
      this.work_ids_arr = this.work_ids_arr.filter(
        (work_id: string) => work_id !== e.is_selected
      );
    }
    else {
      // Add id to array
      this.work_ids_arr.push(e.is_selected);
    }
  }
}
