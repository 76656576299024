import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { UserService } from '../../services/user.service';
import { DataLayerService } from '../../services/data-layer.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DeleteProfileUserComponent } from '../../delete-profile-user/delete-profile-user.component';

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.css']
})
export class ProfileViewComponent implements OnInit {
  @Input('user_item') public user_item: any = [];
  @Input('isUsersOwnProfile') public isUsersOwnProfile: boolean = true;
  @Input('authenticatedUser') public authenticatedUser: any = {};

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;
  bsModalRef: BsModalRef;

  work: any = {
    owner_id: '',
    _id: ''
  };

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  work_id_from_favourites: string = "";

  /* public workofart_items: any = []; */
  // To workofart_items έγινε profile_workofart_items ώστε αυτός ο πίνακας να είναι μόνο για τα προσωπικά έργα του κάθε καλλιτέχνη

  public profile_workofart_items: any = [];
  public favourite_workofart: any = [];
  public exhibition_items: any = [];
  public workofart_items_all: any = [];
  public favourites_ids: any = [];
  public artist_favourites_items: any = [];

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  public isBookmarkVisible: boolean = true;
  public isRatethisVisible: boolean = true;
  public isRatebuttonVisible: boolean = true;
  public isVerticallineVisible: boolean = true;
  public isCheckboxVisible: boolean = true;

  public isSettings1Visible: boolean = true;
  public isSettings2Visible: boolean = true;

  public isSettings2VisibleExhib: boolean = false;

  public isWorkStatusVisible: boolean = true;
  public isStatus1Visible: boolean = true;
  public isStatus2Visible: boolean = true;
  public isStatus3Visible: boolean = true;

  public isStatusExhibition1Visible: boolean = false;
  public isStatusExhibition2Visible: boolean = false;
  public isStatusExhibition3Visible: boolean = false;

  public isUsersOwnProfile_Rating: boolean = true;
  public isUsersOwnProfile_Rating2: boolean = true;

  public isPreview1Visible: boolean = false;
  public isPreview3Visible: boolean = false;

  public SelectedFavouriteId: string;

  public isForAdd: boolean = false;

  constructor(
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private spinnerService: NgxSpinnerService,
    public modalService: BsModalService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.firstname = this.user_item.firstname;
    this.lastname = this.user_item.lastname;
    this.user_id = this.user_item._id;

    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0 && this.isUsersOwnProfile) {
        this.spinnerService.show();

        this.userService.getExhibitionOfArtist(this.user_id)
          .pipe()
          .subscribe({
            next: data => {
              this.exhibition_items = data["_items"];
              /*  console.log('My exhibition:', this.exhibition_items); */

              if (this.exhibition_items.length < 1) {
                this.isForAdd = true;
              }
              else {
                this.isForAdd = false;
              }

              // Hide spinner
              this.spinnerService.hide();
            },
            error: error => {
              /* console.log("Handle Error: ", error); */
              // Handle Error Here
            }
          });

        //update profile details if they changed at edit profile component
        this.spinnerService.show();

        this.userService.getSpecificArtist(this.user_id).pipe().subscribe({
          next: data => {
            this.firstname = data["_items"][0].firstname;
            this.lastname = data["_items"][0].lastname;

            // Hide spinner
            this.spinnerService.hide();
          },
          error: error => {
            /*  console.log("Handle Error: ", error); */
            // Handle Error Here
          }
        });
      }
    });

    this.isBookmarkVisible = true;
    this.isRatethisVisible = false;
    this.isRatebuttonVisible = false;
    this.isVerticallineVisible = false;
    this.isCheckboxVisible = false;

    this.isStatus1Visible = false;
    this.isStatus2Visible = false;
    this.isStatus3Visible = false;
    this.isWorkStatusVisible = true;

    this.isStatusExhibition1Visible = false;
    this.isStatusExhibition2Visible = false;
    this.isStatusExhibition3Visible = false;

    this.isSettings2VisibleExhib = false;
    this.isUsersOwnProfile_Rating2 = true;

    if (!this.isUsersOwnProfile) {
      this.isSettings1Visible = false;
      this.isSettings2Visible = false;
      this.isSettings2VisibleExhib = false;
      this.isUsersOwnProfile_Rating = false;
    }

    this.getWorks();
  }

  /*
  * Get all works
  */
  getWorks() {
    this.workofart_items_all = [];
    this.artist_favourites_items = [];
    this.favourite_workofart = [];
    this.profile_workofart_items = [];
    this.exhibition_items = [];

    this.isStatus1Visible = false;
    this.isStatus2Visible = false;
    this.isStatus3Visible = false;
    this.isWorkStatusVisible = true;

    this.isStatusExhibition1Visible = false;
    this.isStatusExhibition2Visible = false;
    this.isStatusExhibition3Visible = false;

    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0 && this.isUsersOwnProfile) {
          if (user[0]['role'] === 'artist') {
            this.isRatebuttonVisible = false;
            this.isRatethisVisible = false;
            this.isVerticallineVisible = false;
            this.isBookmarkVisible = true;
          }
          if (user[0]['role'] === 'curator') {
            this.isBookmarkVisible = false;
            this.isRatebuttonVisible = false;
            this.isRatethisVisible = false;
            this.isVerticallineVisible = false;
          }

          this.spinnerService.show();

          this.userService.getExhibitionOfArtist(this.user_item._id)
            .pipe()
            .subscribe({
              next: data => {
                this.isSettings2Visible = false;
                this.exhibition_items = data["_items"];
                /* console.log('My exhibition:', this.exhibition_items); */

                if (data["_items"][0]) {
                  if (data["_items"][0]['status'] === 'PENDING') {
                    this.isStatusExhibition1Visible = true;
                  }

                  else if (data["_items"][0]['status'] === 'REJECTED') {
                    this.isStatusExhibition3Visible = true;
                  }

                  else {
                    this.isStatusExhibition2Visible = true;
                  }
                }
                // Hide spinner
                this.spinnerService.hide();

              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

          this.profile_workofart_items = [];
          this.user_id = user[0]._id;

          this.spinnerService.show();

          this.userService.getWorkOfArtist(this.user_item._id)
            .pipe()
            .subscribe({
              next: data => {
                this.isUsersOwnProfile_Rating = true;
                this.isUsersOwnProfile_Rating2 = true;
                this.isPreview1Visible = true;
                this.isWorkStatusVisible = true;
                this.isSettings2Visible = false;
                let workofart_items_tmp = data["_items"];

                /* console.log('All Works of art:', this.workofart_items_all);
                
                // Filter based on user's artworks
                this.profile_workofart_items = this.workofart_items_all.filter((obj) => {
                  return obj.owner_id._id === this.user_id;
                }); */

                // Sort based on artwork created(new first)
                this.profile_workofart_items = this.profile_workofart_items.sort((obj1, obj2) => {
                  return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                });

                this.userService.getFavouritesOfArtist(this.user_id)
                  .pipe()
                  .subscribe({
                    next: data => {
                      this.isPreview3Visible = false;
                      this.workofart_items_all = [];
                      this.artist_favourites_items = [];
                      let favourites_ids = [];
                      const artist_favourites_items = data['_items'];
                      this.artist_favourites_items = artist_favourites_items;

                      /* console.log("artist_favourites_items", this.artist_favourites_items); */

                      // Add work ids in favourites
                      for (let j = 0; j < artist_favourites_items.length; j++) {
                        if (artist_favourites_items[j].work) {
                          favourites_ids.push(artist_favourites_items[j].work._id);
                        }
                      }

                      // Set bookmark for favourites
                      for (let i = 0; i < workofart_items_tmp.length; i++) {
                        if (favourites_ids.includes(workofart_items_tmp[i]._id)) {
                          workofart_items_tmp[i].bookmark_selected = true;
                        }
                        else {
                          workofart_items_tmp[i].bookmark_selected = false;
                        }
                      }

                      // Set final work of arts array
                      this.profile_workofart_items = workofart_items_tmp;

                      // Sort based on artwork created(new first)
                      this.profile_workofart_items = this.profile_workofart_items.sort((obj1, obj2) => {
                        return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                      });

                      /* console.log('My works of art:', this.profile_workofart_items); */

                      if (Object.keys(this.authenticatedUser).length === 0) {
                        this.handleFavouritesOwnUser(artist_favourites_items);
                      }
                    },
                    error: error => {
                      /*  console.log("Handle Error: ", error); */
                      // Handle Error Here
                    }
                  });

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
        }

        else {
          // visit an artist's profile
          this.spinnerService.show();

          this.userService.getExhibitionOfOtherArtists(this.user_item._id)
            .pipe()
            .subscribe({
              next: data => {
                this.isSettings2Visible = false;
                this.isStatusExhibition1Visible = false;
                this.isStatusExhibition2Visible = false;
                this.isStatusExhibition3Visible = false;
                this.exhibition_items = data["_items"];
                /* console.log('Exhibition Of Other artist:', this.exhibition_items); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

          this.profile_workofart_items = [];

          this.spinnerService.show();

          this.userService.getWorkOfOtherArtist(this.user_item._id)
            .pipe()
            .subscribe({
              next: data => {
                this.isUsersOwnProfile_Rating = false;
                this.isUsersOwnProfile_Rating2 = false;
                this.isPreview1Visible = false;
                this.isPreview3Visible = true;
                this.isRatebuttonVisible = true;
                this.isVerticallineVisible = true;
                this.isRatethisVisible = true;
                this.isSettings2Visible = false;
                this.isWorkStatusVisible = false;
                this.profile_workofart_items = data["_items"];

                // Sort based on artwork created(new first)
                this.profile_workofart_items = this.profile_workofart_items.sort((obj1, obj2) => {
                  return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                });

                /* console.log('Works of other artist:', this.profile_workofart_items); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

          if (user.length > 0) {
            this.user_id = user[0]._id;
            this.isBookmarkVisible = true;

            this.profile_workofart_items = [];

            this.spinnerService.show();

            this.userService.getWorkOfOtherArtist(this.user_item._id)
              .pipe()
              .subscribe({
                next: data => {
                  let workofart_items_tmp = data["_items"];

                  this.userService.getFavouritesOfArtist(this.user_id)
                    .pipe()
                    .subscribe({
                      next: data => {
                        // create favourites_ids
                        let favourites_ids = [];
                        const artist_favourites_items = data['_items'];

                        // Add work ids in favourites
                        for (let j = 0; j < artist_favourites_items.length; j++) {
                          if (artist_favourites_items[j].work) {
                            favourites_ids.push(artist_favourites_items[j].work._id);
                          }
                        }

                        // Set bookmark for favourites
                        for (let i = 0; i < workofart_items_tmp.length; i++) {
                          if (favourites_ids.includes(workofart_items_tmp[i]._id)) {
                            workofart_items_tmp[i].bookmark_selected = true;
                          }
                          else {
                            workofart_items_tmp[i].bookmark_selected = false;
                          }
                        }

                        // Set final work of arts array
                        this.profile_workofart_items = workofart_items_tmp;

                        // Sort based on artwork created(new first)
                        this.profile_workofart_items = this.profile_workofart_items.sort((obj1, obj2) => {
                          return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                        });

                        /* console.log("Works of other artist::", this.profile_workofart_items); */

                      },
                      error: error => {
                        /*  console.log("Handle Error: ", error); */
                        // Handle Error Here
                      }
                    });

                  // Hide spinner
                  this.spinnerService.hide();
                },
                error: error => {
                  /* console.log("Handle Error: ", error); */
                  // Handle Error Here
                }
              });
          }

          else {
            this.isBookmarkVisible = false;
          }
        }
      });
  }

  /*
  * Update favourites array
  */
  onCheckboxSelectedFavourite(e: { is_selected_fav: string }) {
    if (this.isUsersOwnProfile) {
      this.favourite_workofart = this.favourite_workofart.filter(
        (obj: any) => obj._id != e.is_selected_fav
      );
    }
  }

  RatingUpdate(e: { rating_update: any }) {
    this.getWorks();
  }

  BookmarkUpdated(e: { bookmark_updated: any }) {
    this.getWorks();
  }

  openModalConfirmDelete() {
    const initialState = {
      owner_id_user: this.user_id
    };

    this.modalRef = this.modalService.show(DeleteProfileUserComponent, { initialState });
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }


  /*
  * Show favourites based on authenticated user's favourites
  * Case: User sees another user's profile
  */
  // δεν θέλουμε να μπορούμε βλέπουμε τα αγαπημένα άλλων καλλιτεχνών αλλά κράταμε τη συνάρτηση( handleFavouritesNotOwnUser) για να δούμε πως γίνεται να το κάνουμε αυτό.

  handleFavouritesNotOwnUser(artist_favourites_items: any) {
    this.userService.getFavouritesOfArtist(this.authenticatedUser._id)
      .pipe()
      .subscribe({
        next: data => {
          const authenticated_user_favourites_items = data['_items'];
          let authenticated_user_favourites_items_ids = [];
          for (let item of authenticated_user_favourites_items) {
            if (item.work) {
              authenticated_user_favourites_items_ids.push(item.work._id);
            }
          }

          for (let j = 0; j < artist_favourites_items.length; j++) {

            if (artist_favourites_items[j].work) {
              if (authenticated_user_favourites_items_ids.includes(artist_favourites_items[j].work._id)) {
                artist_favourites_items[j].work.bookmark_selected = true;
              }
              else {
                artist_favourites_items[j].work.bookmark_selected = false;
              }
              this.favourite_workofart.push(artist_favourites_items[j].work);
            }

          }

        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
        }
      });
  }


  /*
  * Show favourites based on authenticated user's favourites
  * Case: User sees his/her own profile
  */
  handleFavouritesOwnUser(artist_favourites_items: any) {
    this.favourite_workofart = [];

    for (let j = 0; j < artist_favourites_items.length; j++) {
      if (artist_favourites_items[j].work) {
        // Set a new variable bookmark_selected as true
        artist_favourites_items[j].work.bookmark_selected = true;

        this.favourite_workofart.push(artist_favourites_items[j].work);

        /* console.log('My favourites:', this.favourite_workofart); */
      }
    }
  }
}
