<div class="col" style="padding-bottom: 50px; padding-right: 0px; padding-left: 0px;">
    <div class="card h-100" style="width: 340px; border: none;">
        <img src={{imageUrl1}} class="card-img-top" alt="..."
            style="border-radius: 10px; height: 230px; width: 340px; object-fit: contain;" />
        <ul class="images">
            <div *ngIf="isImage2Visible" class="image-col">
                <li class="image-li">
                    <img src={{imageUrl2}} class="card-img-top" alt="..."
                        style="border-radius: 10px; object-fit: contain; height: 55px; width: 60px;" />
                </li>
            </div>
            <div *ngIf="isImage3Visible" class="image-col">
                <li class="image-li">
                    <img src={{imageUrl3}} class="card-img-top" alt="..."
                        style="border-radius: 10px; object-fit: contain; height: 55px; width: 60px;" />
                </li>
            </div>
            <div *ngIf="isImage4Visible" class="image-col">
                <li class="image-li">
                    <img src={{imageUrl4}} class="card-img-top" alt="..."
                        style="border-radius: 10px; object-fit: contain; height: 55px; width: 60px;" />
                </li>
            </div>
            <div *ngIf="isImage5Visible" class="image-col">
                <li class="image-li">
                    <img src={{imageUrl5}} class="card-img-top" alt="..."
                        style="border-radius: 10px; object-fit: contain; height: 55px; width: 60px;" />
                </li>
            </div>
            <div *ngIf="isImage6Visible" class="image-col">
                <li class="image-li">
                    <img src={{imageUrl6}} class="card-img-top" alt="..."
                        style="border-radius: 10px; object-fit: contain; height: 55px; width: 60px;" />
                </li>
            </div>
        </ul>
        <div class="card-body" style="padding: 15px 0px 0px 0px; margin-top: -40px;">
            <div class="row">
                <div class="col-8">
                    <a class="card-title" style="color: #000; cursor: pointer; font-size: 20px;"
                        (click)="previewExhibition()">{{exhibition_item.title}}</a>
                </div>
                <div class="col-4" style="text-align: right; margin-left: -3px;">
                    <div class="dropdown" *ngIf="isSettings1Visible">
                        <button class="class_settings" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="width: 17px; height: 20px; color: #8e8c84; font-size: 15px; font-weight: 500; border: none; background: none; cursor: pointer;">
                            <span class="material-symbols-outlined"
                                style="font-size: 22px; margin-left: -6px; margin-top: 2px;">
                                settings
                            </span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" style="cursor: pointer;"
                                    (click)="editExhibition(exhibition_item._id)">{{'Edit Digital Exhibition' | translate}}</a>
                            </li>
                            <li><a class="dropdown-item" style="cursor: pointer;"
                                    (click)="openModalConfirmDelete()">{{'Delete Digital Exhibition' |
                                    translate}}</a></li>
                        </ul>
                    </div>
                    <div class="dropdown" *ngIf="isSettings2VisibleExhib">
                        <button class="class_settings" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style="width: 17px; height: 20px; color: #8e8c84; font-size: 15px; font-weight: 500; border: none; background: none; cursor: pointer;">
                            <span class="material-symbols-outlined"
                                style="font-size: 22px; margin-left: -6px; margin-top: 2px;">
                                settings
                            </span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" style="cursor: pointer;" (click)="openModalConfirmDelete()">{{'Delete Digital Exhibition' | translate}}</a></li>
                            <li><a *ngIf="isapproved" class="dropdown-item" (click)="previewExhibitionEdit()" style="cursor: pointer;">{{'Edit Digital Exhibition' | translate}}</a></li>
                            <li><a *ngIf="isrejected" class="dropdown-item" style="cursor: pointer;"
                                    (click)="approveExhibition(exhibition_item._id)">{{'APPROVE DIGITAL EXHIBITION'
                                    |translate}}</a></li>
                            <li><a *ngIf="isapproved" class="dropdown-item" style="cursor: pointer;"
                                    (click)="rejectExhibition(exhibition_item._id)">{{'REJECT DIGITAL EXHIBITION' |
                                    translate}}</a></li>
                            <li><a *ngIf="isaccountdelete" class="dropdown-item" style="cursor: pointer;" (click)="openModalConfirmDeleteAccount()">{{'ACCOUNT DELETION' | translate}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-8">
                    <p class="card-text" style="color: #8e8c84;font-size: 12px; margin-left: 0px; margin-top: 3px;">
                        {{exhibition_item.works.length}} {{'Artworks' | translate}}</p>
                </div>
                <div class="col-8">
                    <li class="profile-item" style="display: block; margin-top: 4px;">
                        <a class="card-text" (click)="openUserProfile()"
                            style="color: #3e3f3a; margin-top: 2px; color: #3e3f3a; cursor: pointer; margin-left: 0px;">{{exhibition_item.owner_id.firstname}}
                            {{exhibition_item.owner_id.lastname}}</a>
                    </li>
                </div>
                <div class="col-8">
                    <p class="card-subtitle mb-2" style="color: #3e3f3a; margin-left: 0px; margin-top: 0px;">
                        {{upload_date}}
                    </p>
                </div>
                <div class="col-4" style="padding: 0px;">

                    <div class="col" style="padding: 0px;text-align: right;">
                        <div *ngIf="isStatusExhibition1Visible">
                            <button class="status-pending" data-toggle="tooltip" data-placement="bottom" title="{{'Pending inspection by the curator' | translate}}" [id]="exhibition_item._id" type="button"
                                style="border: none; color: orange; background: #fff;" disabled>
                                <span class="material-symbols-outlined"
                                    style="font-size: 22px; cursor: auto; margin-left: -34px; background: #fff;">
                                    timelapse
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="col" style="padding: 0px;text-align: right;">
                        <div *ngIf="isStatusExhibition2Visible">
                            <button class="status-approved" data-toggle="tooltip" data-placement="bottom" title="{{'Approved by the curator' | translate}}" [id]="exhibition_item._id" type="button"
                                style="border: none; color: #00FF00; background: #fff;" disabled>
                                <span class="material-symbols-outlined"
                                    style="font-size: 22px; cursor: auto; margin-left: -34px; background: #fff;">
                                    check_circle
                                </span>
                            </button>
                        </div>
                    </div>

                    <div class="col" style="padding: 0px;text-align: right;">
                        <div *ngIf="isStatusExhibition3Visible">
                            <button class="status-rejected" data-toggle="tooltip" data-placement="bottom" title="{{'Rejected by the curator' | translate}}" [id]="exhibition_item._id" type="button"
                                style="border: none; color: red; background: #fff;" disabled>
                                <span class="material-symbols-outlined"
                                    style="font-size: 22px; cursor: auto; margin-left: -34px; background: #fff;">
                                    hide_source
                                </span>
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>