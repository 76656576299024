import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.css']
})
export class SearchResultsComponent implements OnInit {
  public isSearchCompleted: boolean = false;

  public isUsersOwnProfile_Rating2: boolean = false;

  searchText: string = '';

  work: any = {
    owner_id: ''
  };

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  _id: any;

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  public workofart_items: any = [];

  public isBookmarkVisible: boolean = true;
  public isRatethisVisible: boolean = true;
  public isRatebuttonVisible: boolean = true;
  public isVerticallineVisible: boolean = true;
  public isCheckboxVisible: boolean = true;
  public isSettings1Visible: boolean = true;
  public isSettings2Visible: boolean = true;
  public isWorkStatusVisible: boolean = true;
  public isStatus1Visible: boolean = true;
  public isStatus2Visible: boolean = true;
  public isStatus3Visible: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params['tag'] !== undefined) {
        this.searchText = params['tag'];
        this.getWorkTags();
      }

      this.subscription = this.dataLayerService.currentUser.subscribe(
        user => {
          if (user.length > 0) {
            this.firstname = user[0].firstname;
            this.lastname = user[0].lastname;
            this.user_id = user[0]._id;
            this.work.owner_id = this.user_id;

            this.isCheckboxVisible = false;
            this.isSettings1Visible = false;
            this.isSettings2Visible = false;
            this.isWorkStatusVisible = false;
            this.isStatus1Visible = false;
            this.isStatus2Visible = false;
            this.isStatus3Visible = false;
            this.isUsersOwnProfile_Rating2 = false;

            if (user[0]['role'] === 'artist') {
              this.isRatebuttonVisible = true;
              this.isRatethisVisible = true;
              this.isVerticallineVisible = true;
              this.isBookmarkVisible = true;
            }

            if (user[0]['role'] === 'curator') {
              this.isBookmarkVisible = false;
              this.isRatebuttonVisible = false;
              this.isRatethisVisible = false;
              this.isVerticallineVisible = false;
            }

            this.getWorkTags();

          }

          else {
            this.authenticated_user = false;

            this.isCheckboxVisible = false;
            this.isSettings1Visible = false;
            this.isSettings2Visible = false;
            this.isBookmarkVisible = false;
            this.isWorkStatusVisible = false;
            this.isStatus1Visible = false;
            this.isStatus2Visible = false;
            this.isStatus3Visible = false;
            this.isRatebuttonVisible = true;
            this.isRatethisVisible = true;
            this.isVerticallineVisible = true;

            this.getWorkTags();

          }

        });
    });

  }

  RatingUpdate(e: { rating_update: any }) {
    this.getWorkTags();
  }

  BookmarkUpdated(e: { bookmark_updated: any }) {
    this.getWorkTags();
  }

  //synarthsh gia handle event modal completed
  ModalCompleted(e: { modal_updated: any }) {
    this.getWorkTags();
  }

  onSearchTextEntered(searchValue: string) {
    this.searchText = searchValue;
    /* console.log(this.searchText); */
  }

  getWorkTags() {
    this.spinnerService.show();

    this.workofart_items = [];

    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.userService.getWorkTags(this.searchText)
            .pipe()
            .subscribe({
              next: data => {

                let workofart_items_tmp = data["_items"];

                // Get favourites of user
                this.userService.getFavouritesOfArtist(this.user_id)
                  .pipe()
                  .subscribe({
                    next: data => {

                      // create favourites_ids
                      let favourites_ids = [];
                      const artist_favourites_items = data['_items'];

                      // Add work ids in favourites
                      for (let j = 0; j < artist_favourites_items.length; j++) {
                        if (artist_favourites_items[j].work) {
                          favourites_ids.push(artist_favourites_items[j].work._id);
                        }
                      }

                      // Set bookmark for favourites
                      for (let i = 0; i < workofart_items_tmp.length; i++) {
                        if (favourites_ids.includes(workofart_items_tmp[i]._id)) {
                          workofart_items_tmp[i].bookmark_selected = true;
                        } else {
                          workofart_items_tmp[i].bookmark_selected = false;
                        }
                      }

                      this.workofart_items = workofart_items_tmp;

                      // Sort based on artwork created(new first)
                      this.workofart_items = workofart_items_tmp.sort((obj1, obj2) => {
                        return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                      });

                     /*  console.log("Works of art:", this.workofart_items); */

                      if (this.workofart_items.length < 1) {
                        this.isSearchCompleted = true;
                      }

                      else {
                        this.isSearchCompleted = false;
                      }

                      // Hide spinner
                      this.spinnerService.hide();
                    },
                    error: error => {
                      /* console.log("Handle Error: ", error); */
                      // Handle Error Here
                    }
                  });

              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

        }

        else {
          this.spinnerService.show();

          this.userService.getWorkTags(this.searchText)
            .pipe()
            .subscribe({
              next: data => {
                this.workofart_items = data["_items"];

                // Sort based on artwork created(new first)
                this.workofart_items = this.workofart_items.sort((obj1, obj2) => {
                  return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                });

                /* console.log("Works of art:", this.workofart_items); */

                if (this.workofart_items.length < 1) {
                  this.isSearchCompleted = true;
                }

                else {
                  this.isSearchCompleted = false;
                }

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

        }

      });
  }
}
