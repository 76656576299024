import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'angular-src';

  constructor(translate: TranslateService,
    public router: Router) {
    translate.addLangs(['en', 'el']);
    translate.setDefaultLang('el');
  
   /*  For Google Analytics */
   this.router.events.subscribe((event) => {
    if (event instanceof NavigationEnd) {
      gtag('config', 'G-M2Z4FN3K3X', { 'page_path': event.urlAfterRedirects });
    }      
  })
  }
}
