import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../services/user.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-work',
  templateUrl: './delete-work.component.html',
  styleUrls: ['./delete-work.component.css']
})
export class DeleteWorkComponent implements OnInit {
  @Input('workofart_item_id') public workofart_item_id: string = "";
  @Input('workofart_item') public workofart_item: any = "";

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  _id: any;
  role: string = "";

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  work: any = {
    owner_id: '',
  };

  constructor(private router: Router,
    private toastr: ToastrService,
    private dataLayerService: DataLayerService,
    public modalService: BsModalService,
    private spinnerService: NgxSpinnerService,
    private userService: UserService,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.authenticated_user = true;
        this.firstname = user[0].firstname;
        this.lastname = user[0].lastname;
        this.user_id = user[0]._id;
        this.work.owner_id = this.user_id;
        this.role = user[0].role;
      }
    });

    /* console.log("_id που περναει στο confirm modal:", this.workofart_item_id); */
    /* console.log("workofart_item που περναει στο confirm modal:", this.workofart_item); */
    /* console.log("role", this.role); */
  }

  onConfirmSubmitNo() {
    this.triggerCloseModalEvent();
  }

  /*
 * Triger close event to the parent component
 */
  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }

  onSubmit() {
    const work_id = this.workofart_item_id;

    this.spinnerService.show();

    this.userService.deleteWork(work_id).pipe().subscribe({
      next: data => {
        this.translate.get('Artwork removed!').subscribe(res => {
          this.toastr.success(res);
        });

        if (this.authenticated_user && this.role === 'artist') {
          this.router.navigate(['/profile']);
        }
        if (this.authenticated_user && this.role === 'curator') {
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate(['/profile-curator']);
        }

        // Hide spinner
        this.spinnerService.hide();
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });
    this.triggerCloseModalEvent();
  }
}
