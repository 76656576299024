<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">

<div class="container" class="container-preview">

    <div id="carouselExampleControls" class="carousel slide" data-bs-interval="false">
        <div class="carousel-inner">

            <!-- ngFor starts here -->

            <div [ngClass]="{'carousel-item': true, 'active': item._id === workofart_item._id}"
                *ngFor="let item of workofart_items; let i = index" [id]="workofart_item._id">

                <div class="row" style="margin-left: 0px;">
                    <button class="close-button-preview" (click)="ngOnDestroy()"><i class="fa fa-close"></i>{{'CLOSE' | translate}}</button>
                </div>

                <div class="row image-row" *ngIf="item._id === workofart_item._id">
                    <div class="col">
                        <img [src]="sanitize('data:image/jpg;base64, ' + arrayBufferToBase64(item.workPic))" alt="..." class="workofart-image-preview">
                    </div>
                </div>

                <div class="row big-row-after-image">

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-7 preview-descrip-row">
                        <p class="preview-descrip-title">{{'Artwork description:' | translate}}</p>
                        <p class="preview-descrip">{{item.description}}</p>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4">

                        <div class="row row-card-body-preview">

                            <div class="card-body" style="padding: 15px 0px 0px 0px;">
                                <p class="card-title" style="color: white; font-size: 20px; margin-left: -2px;">
                                    {{item.title}}</p>

                                <div class="row" style="margin-top: -5px;">
                                    <div class="col-8">
                                        <a class="card-subtitle mb-2" style="color: white; cursor: pointer;"
                                            (click)="openUserProfile()">{{item.owner_id.firstname}}
                                            {{item.owner_id.lastname}}</a>
                                    </div>
                                </div>

                                <p class="card-subtitle mb-2" style="color: white; margin-left: 0px; margin-top: 3px;">
                                    {{item.date}}</p>
                                <p class="card-text" style="color: white; font-size: 12px;">{{item.tags}}</p>
                                <div class="row"
                                    style="width: 210px;margin: 0px; display: grid; grid-template-columns: 7px 100px 4px 35px 30px 0px;">

                                    <div class="col" style="padding: 0px 0px 0px 10px;">
                                        <div *ngIf="isStarRatingVisible">
                                            <form [formGroup]="ratingStarsForm">
                                                <ngx-star-rating
                                                    style="position: absolute; padding-top: 7px; color: white;"
                                                    formControlName="rating" [id]="'carousel_' + item._id">
                                                </ngx-star-rating>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="col" style="padding: 0px;">
                                        <div *ngIf="isVerticallineVisible">
                                            <div class="vl" style="border-left: 1px solid #8e8c84; height: 35px; margin-top: -3px; color: white;"></div>
                                        </div>
                                    </div>

                                    <div class="col" style="padding: 0px;">
                                        <div *ngIf="isRatebuttonVisible">
                                            <button class="buttonstar" (click)="submitRating()" data-toggle="tooltip" data-placement="bottom" title="{{'Rate' | translate}}"
                                                type="button"  style="color: white;"> 
                                                <img src="assets/images/outline-star-32-preview_workofart.png" style="width: 30px;">
                                            </button>
                                        </div>
                                    </div>

                                    <div class="col" style="padding: 0px 0px 0px 10px;">
                                        <div *ngIf="isStarRatingVisible">
                                        </div>
                                    </div>

                                    <img src="assets/images/outline-star-32-preview_workofart.png" class="starsProfile" style="position: absolute; width: 15px; margin-left: 20px; padding-top: 8px;">

                                </div>
                            </div>

                        </div>

                        <div class="row row-rate-preview">
                            <p class="rate" style="font-size: 14px; display: flex; margin-top: -1px;">
                                ({{item.ratings_count}} {{'votes' | translate}})</p>
                        </div>

                        <div class="row row-share-title-preview">
                            <p style="font-size: 18px;">{{'Share options:' | translate}}</p>
                        </div>
                        <div class="row row-share-icons-preview">
                            <div id="fb-root"></div>
                            <script async defer crossorigin="anonymous"
                                src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v15.0"
                                nonce="MMAcF4E4"></script>

                            <!-- Your share button code -->
                            <div class="fb-share-button" data-href="https://2gether-museum.gr"
                                data-layout="button_count" data-size="large">
                                <a target="_blank"
                                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse"
                                    class="fb-xfbml-parse-ignore"><i class="fa fa-facebook"
                                        style="font-size: 25px; color: #4267B2; padding-bottom: 10px;"></i></a>
                                <a class="twitter-share-button" target="_blank" class="fb-xfbml-parse-ignore"
                                    href="https://twitter.com/intent/tweet?text=Hello%20world"><i class="fa fa-twitter"
                                    style="font-size: 25px; color: #00acee; padding-bottom: 10px; padding-left: 10px;"></i></a>
                            </div>
                        </div>

                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-1 full-screen-preview-row">
                        <!-- <a>
                            <span class='bi bi-zoom-in' style="font-size: 25px;"></span>
                        </a> -->
                        <a>
                            <span class='bi bi-fullscreen full-icon' (click)="fullscreen()"></span>
                        </a>
                    </div>

                </div>

            </div>

        </div>

    </div>

    <button class="btn-carousel btn-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev"
        (keyup.enter)="onPrevClick()" (click)="onPrevClick()">
        <div class="arrow-prev">
            <i class="carousel-control-prev-icon"></i>
        </div>
    </button>

    <button class="btn-carousel btn-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next"
        (keyup.enter)="onNextClick()" (click)="onNextClick()">
        <div class="arrow-next">
            <i class="carousel-control-next-icon" aria-hidden="true"></i>
        </div>
    </button>

</div>
