import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DataLayerService } from '../services/data-layer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmExhibitionComponent } from '../confirm-exhibition/confirm-exhibition.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-exhibition5',
  templateUrl: './create-exhibition5.component.html',
  styleUrls: ['./create-exhibition5.component.css']
})
export class CreateExhibition5Component implements OnInit {
  @Output() goToPreviousStep = new EventEmitter<{ step: number }>();
  @Output() exhibitionWorksCreated = new EventEmitter<{ works: [] }>();
  @Input('exhibition_obj') public exhibition_obj: any = [];

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;

  public work_ids_arr: any = [];

  work: any = {
    owner_id: ''
  };

  exhibition: any = {
    works: [],
    owner_id: ''
  };

  firstname: string = "";
  lastname: string = "";
  user_id: string = "";
  _id: any;

  public exhibition_id: any;

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: any;

  public isBookmarkVisible: boolean = true;
  public isRatethisVisible: boolean = true;
  public isRatebuttonVisible: boolean = true;
  public isVerticallineVisible: boolean = true;
  public isSettings1Visible: boolean = true;
  public isSettings2Visible: boolean = true;

  public isUsersOwnProfile_Rating2: boolean = false;

  public SelectedWorkid: string;

  public exhibition_items: any = [];
  public workofart_items: any = [];

  public exhibitionStepFiveForm: FormGroup;

  public isForAdd: boolean = false;
  public isForEdit: boolean = false;

  constructor(
    public authService: AuthService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    public modalService: BsModalService,
    public translate: TranslateService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.work_ids_arr = this.exhibition_obj.works;

    this.exhibitionStepFiveForm = this.fb.group({
      works: [this.exhibition_obj.works, Validators.compose([Validators.required])]
    });

    this.subscription = this.dataLayerService.currentUser.subscribe(
      user => {
        if (user.length > 0) {
          this.firstname = user[0].firstname;
          this.lastname = user[0].lastname;
          this.user_id = user[0]._id;
          this.exhibition.owner_id = this.user_id;

          //check if for upload exhibition or for edit
          this.spinnerService.show();

          this.userService.getExhibitionOfArtist(this.user_id)
            .pipe()
            .subscribe({
              next: data => {
                this.exhibition_items = data["_items"];
                /* console.log('My exhibition:', this.exhibition_items); */

                if (this.exhibition_items.length === 0) {
                  this.isForAdd = true;
                  this.isForEdit = false;
                }
                else {
                  this.exhibition_id = data["_items"][0]._id;
                  /*  console.log('My exhibition:', this.exhibition_items); */
                  /* console.log('Exhibition _id:', this.exhibition_id); */
                  this.isForAdd = false;
                  this.isForEdit = true;
                }

                /* console.log("ADD: ", this.isForAdd);
                console.log("EDDIT: ", this.isForEdit); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

          this.isBookmarkVisible = true;
          this.isRatethisVisible = true;
          this.isRatebuttonVisible = true;
          this.isVerticallineVisible = true;
          this.isSettings1Visible = false;
          this.isSettings2Visible = false;

          this.workofart_items = [];

          this.spinnerService.show();

          this.userService.getWorkOfAllArtists()
            .pipe()
            .subscribe({
              next: data => {
                let workofart_items_tmp = data["_items"];

                // Get favourites of user
                this.userService.getFavouritesOfArtist(this.user_id)
                  .pipe()
                  .subscribe({
                    next: data => {

                      // create favourites_ids
                      let favourites_ids = [];
                      const artist_favourites_items = data['_items'];

                      // Add work ids in favourites
                      for (let j = 0; j < artist_favourites_items.length; j++) {
                        if (artist_favourites_items[j].work) {
                          favourites_ids.push(artist_favourites_items[j].work._id);
                        }
                      }

                      // Set bookmark for favourites
                      for (let i = 0; i < workofart_items_tmp.length; i++) {
                        if (favourites_ids.includes(workofart_items_tmp[i]._id)) {
                          workofart_items_tmp[i].bookmark_selected = true;
                        }
                        else {
                          workofart_items_tmp[i].bookmark_selected = false;
                        }
                      }

                      this.workofart_items = workofart_items_tmp;

                      // Sort based on artwork created(new first)
                      this.workofart_items = workofart_items_tmp.sort((obj1, obj2) => {
                        return new Date(obj2._created).getTime() - new Date(obj1._created).getTime();
                      });

                      this.workofart_items = this.workofart_items.filter((obj) => {
                        if (this.exhibition_obj.works.includes(obj._id)) {
                          return obj;
                        }
                      });

                      // Set true/false for checkbox
                      for (let i = 0; i < this.workofart_items.length; i++) {
                        if (this.exhibition_obj.works.includes(this.workofart_items[i]._id)) {
                          this.workofart_items[i].checkbox_selected = true;
                        }
                      }
                      /*  console.log("Works of art:", this.workofart_items); */
                    },
                    error: error => {
                      /* console.log("Handle Error: ", error); */
                      // Handle Error Here
                    }
                  });

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });
        }
      });
  }

  openModalConfirm() {
    const initialState = {
      title: this.exhibition_obj.title,
      works: this.work_ids_arr,
      owner_id: this.exhibition_obj.owner_id,
      positions: this.exhibition_obj.positions,
      id_exhibition: this.exhibition_id,
      isForEdit: this.isForEdit,
      isForAdd: this.isForAdd
    };

    this.modalRef = this.modalService.show(ConfirmExhibitionComponent, { initialState });
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  StepfiveSubmit() {
    this.exhibitionWorksCreated.emit({ works: this.work_ids_arr });
  }

  GoBackToStepFour() {
    this.goToPreviousStep.emit({ step: 4 });
  }

  onCheckboxSelected(e: { is_selected: string }) {
    if (this.work_ids_arr.includes(e.is_selected)) {
      this.work_ids_arr = this.work_ids_arr.filter(
        (work_id: string) => work_id !== e.is_selected
      );
    }
    else {
      this.work_ids_arr.push(e.is_selected);
    }
  }

}
