import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Output()
  searchTextChanged: EventEmitter<string> = new EventEmitter<string>();

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;

  public searchForm: FormGroup;

  enteredSearchValue: string = '';

  constructor(private router: Router,
    private toastr: ToastrService,
    public modalService: BsModalService,
    public translate: TranslateService,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      enteredSearchValue: ['', Validators.compose([Validators.required])]
    });
  }

  openSearchResults() {
    this.modalRef = this.modalService.show(SearchComponent);
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  sendData(event: any) {
    this.enteredSearchValue = event.target.value;
    this.onSearchSubmit();
  }

  onSearchSubmit() {
    if (this.enteredSearchValue != '') {
      this.router.navigate(['/search-results'], { queryParams: { tag: this.enteredSearchValue } });
    }
    else {
      this.translate.get('Υou have to type something to search').subscribe(res => {
        /* console.log(res); */
        this.toastr.error(res);
      });
    }
    this.triggerCloseModalEvent();
  }

  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }
}
