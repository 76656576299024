<div class="container">
    <form [formGroup]="searchForm">
        <div class="justify-content-center start">
            <input type="text" (keyup.enter)="sendData($event)" class="inputtext"
                placeholder="{{'To search type and hit enter...' | translate}}" formControlName="enteredSearchValue">
        </div>
        <!-- if we need search as a button -->
        <!-- <div class="search-icon">
            <button class="buttonsearch" type="button" (click)="onSearchSubmit()">
                <span class="material-icons">
                    search
                </span>
            </button>
        </div> -->
    </form>
</div>