<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />

<div class="momus-container">
    <img src="assets/images/momus_logo.png" alt="Momus logo" width="150" height="32">
</div>

<div class="footer footer-expand-lg fixed-bottom footer-dark">
    <div class="container">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <ul class="navbar-nav navbar-center">
            <div class="row justify-content-center">
                <div class="footer-col">
                    <li class="nav-item">
                        <a class="nav-link top" [routerLink]="['/']"
                            [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">{{'Home' | translate}}</a>
                    </li>
                </div>
                <div class="footer-col">
                    <li class="nav-item">
                        <a class="nav-link top" [routerLink]="['/worksofart']"
                            [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">{{'Artworks' | translate}}</a>
                    </li>
                </div>
                <div class="footer-col">
                    <li class="nav-item">
                        <a class="nav-link top" [routerLink]="['/e-exhibitions']"
                            [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact:true}">{{'Digital Exhibitions' | translate}}</a>
                    </li>
                </div>
                <div class="footer-col">
                    <li class="nav-item">
                        <a class="nav-link top_vr" [routerLink]="['/vrpage']" [routerLinkActive]="['active']"
                            [routerLinkActiveOptions]="{exact:true}">{{'MOMus VR' | translate}}</a>
                    </li>
                </div>
            </div>
        </ul>

        <p class="paragraph1">{{'Metropolitan Organisation of Museums of Visual Arts of Thessaloniki (MOMus)' | translate}}</p>
        <p class="paragraph2">{{'Address:' | translate}} <a class="light"> {{'21st Kolokotroni Str, Moni Lazariston, 56430, Stavroupoli, Thessaloniki' | translate}}</a></p>
        <p class="paragraph3">{{'Secretary:' | translate}} <a class="light"> {{'t.' | translate}} (+30) 2310 589141, info@momus.gr</a></p>
        <p class="paragraph4">{{'Department of Administrative, Financial & Technical Services:' | translate}} <a class="light"> {{'t.' | translate}} (+30) 2310 589142,</a></p>
        <p class="paragraph5">{{'Department of Communication and Public Relations:' | translate}} <a class="light">{{'t.' | translate}} (+30) 2310566716, pr@momus.gr</a></p>


        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <ul class="socials">
            <div class="footer-col">
                <li class="social-li">
                    <a type="button" href="https://www.facebook.com/MomusMuseums/" target="_blank">
                        <i class="fa fa-facebook"></i>
                    </a>
                </li>
            </div>
            <div class="footer-col">
                <li class="social-li">
                    <a type="button" href="https://www.instagram.com/momus_museums/" target="_blank">
                        <i class="fa fa-instagram" style="font-size:28px; margin-top: 0px"></i>
                    </a>
                </li>
            </div>
            <div class="footer-col">
                <li class="social-li">
                    <a type="button" href="https://www.linkedin.com/company/momus-museums/" target="_blank">
                        <i class="fa fa-linkedin-square"></i>
                    </a>
                </li>
            </div>
            <div class="footer-col">
                <li class="social-li">
                    <a type="button" href="https://www.youtube.com/channel/UChYHMYjCS-J0nyKVQMLwbLw" target="_blank">
                        <i class="fa fa-youtube-play" style="font-size:28px; margin-top: 0px"></i>
                    </a>
                </li>
            </div>
            <div class="footer-col">
                <li class="social-li">
                    <a type="button" href="#">
                        <i class="material-icons">
                            call
                        </i>
                    </a>
                </li>
            </div>
        </ul>


        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <ul class="navbar-nav navbar-center">
            <div class="row justify-content-center">
                <!-- <div class="footer-col">
                    <li class="nav-item">
                        <a class="nav-link bottom" [routerLinkActive]="['active']"
                            [routerLinkActiveOptions]="{exact:true}">{{'Privacy policy' | translate}}</a>
                    </li>
                </div> -->
                <div class="footer-col">
                    <li class="nav-item">
                        <a class="nav-link bottom" [routerLink]="['/terms-of-use']" [routerLinkActive]="['active']"
                            [routerLinkActiveOptions]="{exact:true}">{{'Terms of Use' | translate}}</a>
                    </li>
                </div>
            </div>

        </ul>
    </div>
</div>