import { Component, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-preview-curator',
  templateUrl: './preview-curator.component.html',
  styleUrls: ['./preview-curator.component.css']
})
export class PreviewCuratorComponent implements OnInit {
  @Input('workofart_item') public workofart_item: any;
  @Input('workofart_items') public workofart_items: any;
  @Input('isAvgRatingVisible') public isAvgRatingVisible: boolean = true;
  @Input('isStarRatingVisible') public isStarRatingVisible: boolean = true;
  @Input('isVerticallineVisible') public isVerticallineVisible: boolean = true;
  @Input('isRatebuttonVisible') public isRatebuttonVisible: boolean = true;
  @Input('isRatethisVisible') public isRatethisVisible: boolean = true;
  @Input('isBookmarkVisible') public isBookmarkVisible: boolean = true;
  @Input('isBookMarkChecked') public isBookMarkChecked: boolean = false;

  @Output('RatingUpdate') public RatingUpdate = new EventEmitter<{ rating_update: any }>();
  @Output('SelectedFavouriteId') public SelectedFavouriteId = new EventEmitter<{ is_selected_fav: string }>();
  @Output('ModalCompleted') public ModalCompleted = new EventEmitter<{ modal_update: boolean }>();
  @Output('BookmarkUpdated') public BookmarkUpdated = new EventEmitter<{ bookmark_updated: any }>();

  public event: EventEmitter<any> = new EventEmitter();

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: boolean = false;

  public upload_date: string = "";
  public upload_date_carousel: string = "";
  public upload_date_carousel_arr: any = [];

  user_id: string = "";
  role: string = "";

  public approved_works_info: any = [];

  public check_rate: boolean = false;

  pic: string = "";

  public workPic: string = "";

  public ratingStarsForm: FormGroup;

  rating: number;

  pic_casousel: string = "";

  public workPicCarousel: string = "";
  public pic_carousel_arr: any = [];

  public bookmarkForm: FormGroup;

  public selectedIndex_arr: any = [];

  preview_index: number = 0;
  selectedIndex: number = 0;
  selectedWork: any;

  automaticallyUpdated = false;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    public translate: TranslateService,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.selectedWork = this.workofart_items[0];

    const created_dt = new Date(this.workofart_item._created);
    this.upload_date = created_dt.toLocaleDateString();

    /*  console.log("PREVIEW ΕΡΓΟ:", this.workofart_item); */

    for (let i = 0; i < this.workofart_items.length; i++) {
      const created_dt_carousel = new Date(this.workofart_items[i]._created);
      this.upload_date_carousel = created_dt_carousel.toLocaleDateString();
      this.upload_date_carousel_arr.push(this.upload_date_carousel);
      this.workofart_items[i].date = this.upload_date_carousel;
      /* console.log("The upload date of each work", this.upload_date_carousel_arr); */

      this.pic_casousel = this.workofart_items[i].pic;
      this.pic_carousel_arr.push(this.pic_casousel);

      this.spinnerService.show();

      this.userService.loadWorkPhoto(this.pic_casousel).pipe().subscribe({
        next: data => {
          this.workPicCarousel = data;
          this.workofart_items[i].workPic = this.workPicCarousel;

          // Hide spinner
          this.spinnerService.hide();
        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
        }
      });
      /* console.log("The pic of each work", this.pic_carousel_arr); */
    }

    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.authenticated_user = true;
        this.user_id = user[0]._id;

        if (user[0]['role'] === 'curator') {
          this.isBookmarkVisible = false;
          this.isRatebuttonVisible = true;
          this.isRatethisVisible = true;
          this.isVerticallineVisible = true;
        }
        if (user[0]['role'] === 'artist') {
          this.isBookmarkVisible = false;
        }
      }

      else {
        this.authenticated_user = false;
        this.isBookmarkVisible = false;
        this.isVerticallineVisible = true;
        this.isRatebuttonVisible = true;
        this.isRatethisVisible = true;
      }

      this.rating = 0;
      this.createForm();

      for (let i = this.selectedIndex; i < this.workofart_items.length; i++) {
        this.selectedIndex = i;
        this.selectedIndex_arr.push(this.selectedIndex);
        this.workofart_items[i].preview_index = this.selectedIndex;
        this.selectedIndex = this.workofart_items[i].preview_index;
        this.selectedWork = this.workofart_items[i];
      }

      this.selectedIndex = this.workofart_item.preview_index;

      /* console.log("The index of each work", this.selectedIndex_arr); */

      /* console.log("SELECTED INDEX", this.selectedIndex); */

      /* console.log("all works of arts in each preview: ", this.workofart_items); */

    });
  }

  onPrevClick() {
    this.ModalCompleted.emit({ modal_update: true });
    if (this.selectedIndex === 0) {
      this.selectedIndex = this.workofart_items.length - 1;
    }
    else {
      this.selectedIndex--;
    }
    /* console.log("SELECTED INDEX", this.selectedIndex); */
    this.selectedWork = this.workofart_items[this.selectedIndex];
    /* console.log("ΕΠΙΛΕΓΜΕΝΟ ΕΡΓΟ", this.selectedWork); */
    this.workofart_item = this.selectedWork;
    this.automaticallyUpdated = this.selectedWork.bookmark_selected;
    this.bookmarkForm.get("bookmark").setValue(this.selectedWork.bookmark_selected);
    this.createForm();
  }

  onNextClick() {
    this.ModalCompleted.emit({ modal_update: true });
    if (this.selectedIndex === this.workofart_items.length - 1) {
      this.selectedIndex = 0;
    }
    else {
      this.selectedIndex++;
    }
    /*  console.log("SELECTED INDEX", this.selectedIndex); */
    this.selectedWork = this.workofart_items[this.selectedIndex];
    /* console.log("ΕΠΙΛΕΓΜΕΝΟ ΕΡΓΟ", this.selectedWork); */
    this.workofart_item = this.selectedWork;
    this.automaticallyUpdated = this.selectedWork.bookmark_selected;
    this.bookmarkForm.get("bookmark").setValue(this.selectedWork.bookmark_selected);
    this.createForm();
  }

  submitRating() {
    const ratingBodyUsers = {
      work: this.workofart_item._id,
      rating: this.ratingStarsForm.value.rating,
      owner_id: this.user_id
    }

    const ratingBody = {
      work: this.workofart_item._id,
      rating: this.ratingStarsForm.value.rating
    }

    // submit rating for artists
    if (this.authenticated_user && this.role === 'artist') {
      if (this.approved_works_info.includes(ratingBodyUsers.work)) {
        this.check_rate = true;
      }

      if (!this.check_rate) {
        this.userService.postRatingUsers(ratingBodyUsers).pipe().subscribe({
          next: data => {
            /* console.log("Successful response: ", data); */
            this.RatingUpdate.emit({ rating_update: ratingBodyUsers });

            this.translate.get('Artwork was rated!').subscribe(res => {
              /* console.log(res); */
              this.toastr.success(res);
            });

          },
          error: error => {
            /* console.log("Handle Error: ", error); */
            // Handle Error Here
            if (error.status === 422 && !this.check_rate) {
              this.translate.get('You have already rated this work').subscribe(res => {
                /* console.log(res); */
                this.toastr.error(res);
              });
            }
          }
        });
      }

      if (this.check_rate) {
        this.translate.get('Υou cannot rate your own artwork').subscribe(res => {
          /* console.log(res); */
          this.toastr.error(res);
        });
      }
    }

    // submit rating for curator
    else if (this.authenticated_user && this.role === 'curator') {
      this.userService.postRatingUsers(ratingBodyUsers).pipe().subscribe({
        next: data => {
          /* console.log("Successful response: ", data); */
          this.RatingUpdate.emit({ rating_update: ratingBodyUsers });

          this.translate.get('Artwork was rated!').subscribe(res => {
            /* console.log(res); */
            this.toastr.success(res);
          });

        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
          if (error.status === 422 && !this.check_rate) {
            this.translate.get('You have already rated this work').subscribe(res => {
              /* console.log(res); */
              this.toastr.error(res);
            });
          }
        }
      });
    }
  }

  createForm() {
    this.ratingStarsForm = this.fb.group({
      rating: [Math.floor((this.selectedIndex && this.workofart_items[this.selectedIndex].avg_rating)) || Math.floor(this.workofart_item.avg_rating), Validators.compose([Validators.required])]
    })
  }

  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnDestroy() {
    this.ModalCompleted.emit({ modal_update: true });
    this.triggerCloseModalEvent();
  }

  /*
  * Navigate to user's profile
  */
  openUserProfile() {
    this.triggerCloseModalEvent();
    this.router.navigate(['/profile/' + this.workofart_item.owner_id._id]);
  }

  fullscreen() {
    window.open('/preview-full-image/' + this.workofart_item._id, '_blank').focus();
  }
}
