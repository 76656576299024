<div class='container'>

<div class="row">
    <div class="column">
      <p class="title">{{'Terms of Use' | translate}}
      </p>
    </div>
</div>

<div class="row">
    <div class="column">
      <p class="paragraph">{{'The users of the digital platform “2gether-museum.gr” have the right to upload to it pictures of artistic content, which originate exclusively from their own works. These images are approved or rejected by art historians of the MOMus curatorial team within 3 working days. In any case, the users warrant that all the uploaded works (or parts thereof) have been created by themselves and they are not encumbered with, nor limited by, the rights of third persons. However, if it is proven that specific works are not original (i.e. they constitute “copies” of prior works) or that the users of this platform infringe, via their posts, the copyright of other authors/artists (for example, because they omitted or failed to obtain the necessary prior written consent), the former are obliged to restore any property loss or moral harm caused to the authors/copyright – owners due to their own illegal conduct (acts or omissions), whereas MOMus is discharged from any liability, of any kind or extent.' | translate}}
      </p>
    </div>
</div>

<div class="row">
    <div class="column">
      <p class="paragraph">{{'Moreover, by uploading their works to the platform “2gether-museum.gr”, the users/authors grant MOMus the right to further use and communicate these works to the public (possibly concerning exhibitions, research, purchase of artworks, promotion) and by other users exclusively within the platform according to the terms which govern its operation and are accessible to everyone.' | translate}}
      </p>
    </div>
</div>

<div class="row">
    <div class="column">
      <p class="paragraph">{{'Finally, subscribing to the digital platform “2gether-museum.gr”, the users confirm that they are adults (over the age of 18) and accept the terms of its use and function and explicitly consent in the maintenance and processing of their personal data by the Metropolitan Orgnanization of Thessaloniki’s Visual Arts Museums (MOMus), which sits in Stavroupoli (Thessaloniki), no. 21, Kolokotroni str., PC 546 30 (tel. +30.2310589141/2, e-mail: info@momus.gr) and operates this particular platform. The personal data, which are kept in the relevant context, are solely the users’ full names and e-mails.' | translate}}
      </p>
    </div>
</div>

<div class="row">
    <div class="column">
      <p class="paragraph">{{'MOMus declares and warrants that it processes the above mentioned personal data of the platform-users in compliance with Regulation (EU) 2016/679 (GDPR) and, more generally, with the national and EU legislation “for the protection of personal data”, which is -each time- in force, and that it is does not unlawfully service or transmit the above-mentioned data to any third person, for any purpose. According to Reg. (EU) 2016/679 (and L. 4624/2019 for the enforcement of GDPR in Greece), the users of the platform “2gether-museum.gr” are entitled to exercise all their relevant rights and, more specifically, the right to revoke their consent, the right to be informed, to have acess to their data, to request their correction or erasure (right to be forgotten), the right to restriction of processing and to data portability, the right to object the processing, submitting a request, as well as the right to file a complaint with the national competent authority (Personal Data Protection Authority – www.dpa.gr).' | translate}}
      </p>
    </div>
</div>

<div class="row">
    <div class="column">
      <p class="paragraph">{{'For any information or question regarding the above or the exercise of their rights provided for, platform-users may send an e-mail to: gdpr@momus.gr or phone at: +30.2310.589142.' | translate}}
      </p>
    </div>
</div>

</div>
