<div class='container'>
  <div class="row">
    <p class="exhi-artist">{{firstname}} {{lastname}}</p>
  </div>

  <div class="row">
    <p class="exhi-title">{{title_exhi}}</p>
  </div>

  <div class="row">
    <iframe src="https://2gether-museum.gr/WebMuseumUpdtd/" height="1080" width="1920" allowfullscreen frameBorder="0"></iframe>
  </div>
</div>