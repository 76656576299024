<div class="container">
    <h1 class="title">{{'Εdit your account details' | translate}}</h1>
    <form #userForm="ngForm" (NgSubmit)="userForm.form.valid && onEditProfileSubmit(userForm)">
        <div class="row">
            <div class="col">
                <h6 class="firstname">{{'First name:' | translate}}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label for="exampleInputFirstName1" class="form-label"></label>
                <input type="text" name="firstname" [(ngModel)]="artist.firstname" #firstname="ngModel"
                    class="form-control firstname-input" id="exampleInputFirstName1" required>
                <small class="smallclass" *ngIf="firstname.touched && !firstname.valid">{{'Enter your first name!' |
                    translate}}</small>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h6 class="lastname">{{'Last name:' | translate}}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label for="exampleInputLastName1" class="form-label"></label>
                <input type="text" name="lastname" [(ngModel)]="artist.lastname" #lastname="ngModel"
                    class="form-control lastname-input" id="exampleInputLastName1" required>
                <small class="smallclass" *ngIf="lastname.touched && !lastname.valid">{{'Enter your last name!' |
                    translate}}</small>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h6 class="password">{{'Password:' | translate}}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <label for="exampleInputPassword1" class="form-label"></label>
                <input type="password" name="password" [(ngModel)]="artist.password" #password="ngModel" minlength="6"
                    class="form-control pass-input" id="exampleInputPassword1" required>
                <small class="smallclass" *ngIf="password.touched && !password.valid">{{'Enter your password! (Password should be at least 6 characters long)' | translate}}</small>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h6 class="birthday">{{'Birthday:' | translate}}</h6>
            </div>
        </div>
        <div class="row">
            <div class="col col_date">
                <select [(ngModel)]="artist.born.day" #born.day="ngModel" name="day" type="date" class="selectday"
                    required>
                    <option *ngFor="let day of dayRange" value={{day}}>
                        {{day}}
                    </option>
                </select>
                <select [(ngModel)]="artist.born.month" #born.month="ngModel" name="month" type="date"
                    class="selectmonth" required>
                    <option *ngFor="let month of monthRange" value={{month}}>
                        {{month}}
                    </option>
                </select>
                <select [(ngModel)]="artist.born.year" #born.year="ngModel" name="year" type="date" class="selectyear"
                    required>
                    <option *ngFor="let year of yearRange" value={{year}}>
                        {{year}}
                    </option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col col_button">
                <button type="submit" class="btn btn-black" [disabled]="!userForm.valid" (click)="onEditProfileSubmit(userForm)">{{'Confirm' | translate}}</button>
            </div>
        </div>
    </form>

    <p class="paragraph-proteceted">{{'Protected by reCAPTCHA and subject to the Google Privacy' | translate}} <br>
        {{'Policy and Terms of Service' | translate}}</p>
</div>