import { Component, OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-preview-favourites-profile',
  templateUrl: './preview-favourites-profile.component.html',
  styleUrls: ['./preview-favourites-profile.component.css']
})
export class PreviewFavouritesProfileComponent implements OnInit {
  @Input('workofart_item') public workofart_item: any;
  @Input('workofart_items') public workofart_items: any;
  @Input('isAvgRatingVisible') public isAvgRatingVisible: boolean = true;
  @Input('isStarRatingVisible') public isStarRatingVisible: boolean = true;
  @Input('isVerticallineVisible') public isVerticallineVisible: boolean = true;
  @Input('isRatebuttonVisible') public isRatebuttonVisible: boolean = true;
  @Input('isRatethisVisible') public isRatethisVisible: boolean = true;
  @Input('isBookmarkVisible') public isBookmarkVisible: boolean = true;
  @Input('isBookMarkChecked') public isBookMarkChecked: boolean = false;

  @Output('SelectedFavouriteId') public SelectedFavouriteId = new EventEmitter<{ is_selected_fav: string }>();
  @Output('ModalCompleted') public ModalCompleted = new EventEmitter<{ modal_update: boolean }>();
  @Output('BookmarkUpdated') public BookmarkUpdated = new EventEmitter<{ bookmark_updated: any }>();

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;
  bsModalRef: BsModalRef;

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: boolean = false;

  public upload_date: string = "";
  public upload_date_carousel: string = "";
  public upload_date_carousel_arr: any = [];

  user_id: string = "";

  pic: string = "";

  public workPic: string = "";

  pic_casousel: string = "";

  public workPicCarousel: string = "";
  public pic_carousel_arr: any = [];

  public bookmarkForm: FormGroup;

  public selectedIndex_arr: any = [];

  preview_index: number = 0;
  selectedIndex: number = 0;
  selectedWork: any;

  automaticallyUpdated = false;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
    public translate: TranslateService,
    public modalService: BsModalService,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.selectedWork = this.workofart_items[0];

    const created_dt = new Date(this.workofart_item._created);
    this.upload_date = created_dt.toLocaleDateString();

    /* console.log("PREVIEW ΕΡΓΟ:", this.workofart_item); */

    for (let i = 0; i < this.workofart_items.length; i++) {
      const created_dt_carousel = new Date(this.workofart_items[i]._created);
      this.upload_date_carousel = created_dt_carousel.toLocaleDateString();
      this.upload_date_carousel_arr.push(this.upload_date_carousel);
      this.workofart_items[i].date = this.upload_date_carousel;
      /* console.log("The upload date of each work", this.upload_date_carousel_arr); */

      this.pic_casousel = this.workofart_items[i].pic;
      this.pic_carousel_arr.push(this.pic_casousel);

      this.spinnerService.show();

      this.userService.loadWorkPhoto(this.pic_casousel).pipe().subscribe({
        next: data => {
          this.workPicCarousel = data;
          this.workofart_items[i].workPic = this.workPicCarousel;

          // Hide spinner
          this.spinnerService.hide();
        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
        }
      });
      /* console.log("The pic of each work", this.pic_carousel_arr); */
    }

    this.dataLayerService.currentUser.subscribe(user => {
      if (user.length > 0) {
        this.authenticated_user = true;
        this.user_id = user[0]._id;

        if (user[0]['role'] === 'curator') {
          this.isBookmarkVisible = true;
          this.isRatebuttonVisible = false;
          this.isRatethisVisible = false;
          this.isVerticallineVisible = false;
        }

        if (user[0]['role'] === 'artist') {
          this.isBookmarkVisible = true;
        }
      }

      else {
        this.authenticated_user = false;
        this.isBookmarkVisible = false;
        this.isVerticallineVisible = true;
        this.isRatebuttonVisible = true;
        this.isRatethisVisible = true;
      }

      this.bookmarkForm = this.fb.group({
        bookmark: [this.workofart_item.bookmark_selected, Validators.compose([Validators.required])]
      });

      this.bookmarkForm.valueChanges.subscribe(value => {

        if (value.bookmark) {
          this.putInFavourites()
        }
        else if (this.user_id && this.user_id !== "" && this.workofart_item && this.workofart_item._id && this.workofart_item._id !== "") {
          this.userService.getFavouriteId(this.user_id, this.workofart_item._id).subscribe({
            next: data => {
              /* console.log("IN"); */
              if (data && data._items && data._items.length > 0) {
                const favourite_id = data["_items"][0]._id;
                const work_id = data["_items"][0].work;

                this.removeFavourite(favourite_id, work_id);
              }
            },
            error: error => {
              /* console.log("Handle Error: ", error) */
              // Handle Error Here
            }
          });
        }

      });

      for (let i = this.selectedIndex; i < this.workofart_items.length; i++) {
        this.selectedIndex = i;
        this.selectedIndex_arr.push(this.selectedIndex);
        this.workofart_items[i].preview_index = this.selectedIndex;
        this.selectedIndex = this.workofart_items[i].preview_index;
        this.selectedWork = this.workofart_items[i];
      }

      this.selectedIndex = this.workofart_item.preview_index;

      /* console.log("The index of each work", this.selectedIndex_arr); */

      /* console.log("SELECTED INDEX", this.selectedIndex); */

      /* console.log("all works of arts in each preview: ", this.workofart_items); */

    });
  }

  onPrevClick() {
    this.ModalCompleted.emit({ modal_update: true });
    if (this.selectedIndex === 0) {
      this.selectedIndex = this.workofart_items.length - 1;
    }
    else {
      this.selectedIndex--;
    }
    /* console.log("SELECTED INDEX", this.selectedIndex); */
    this.selectedWork = this.workofart_items[this.selectedIndex];
    /* console.log("ΕΠΙΛΕΓΜΕΝΟ ΕΡΓΟ", this.selectedWork); */
    this.workofart_item = this.selectedWork;
    this.automaticallyUpdated = this.selectedWork.bookmark_selected;
    this.bookmarkForm.get("bookmark").setValue(this.selectedWork.bookmark_selected);
  }

  onNextClick() {
    this.ModalCompleted.emit({ modal_update: true });
    if (this.selectedIndex === this.workofart_items.length - 1) {
      this.selectedIndex = 0;
    }
    else {
      this.selectedIndex++;
    }
    /* console.log("SELECTED INDEX", this.selectedIndex); */
    this.selectedWork = this.workofart_items[this.selectedIndex];
    /* console.log("ΕΠΙΛΕΓΜΕΝΟ ΕΡΓΟ", this.selectedWork); */
    this.workofart_item = this.selectedWork;
    this.automaticallyUpdated = this.selectedWork.bookmark_selected;
    this.bookmarkForm.get("bookmark").setValue(this.selectedWork.bookmark_selected);
  }

  putInFavourites() {
    if (this.automaticallyUpdated) {
      this.automaticallyUpdated = false;
      return
    }

    const favouriteBody = {
      owner_id: this.user_id,
      work: this.workofart_item._id
    }

    this.spinnerService.show();

    this.userService.postFavourites(favouriteBody).pipe().subscribe({
      next: data => {
        console.log("Successful response: ", data);
        this.BookmarkUpdated.emit({ bookmark_updated: favouriteBody });
        /* console.log("item._id:", this.workofart_item); */

        this.translate.get('Added to favourites!').subscribe(res => {
          /* console.log(res); */
          this.toastr.success(res);
        });

        // Hide spinner
        this.spinnerService.hide();
      },
      error: error => {
        /* console.log("Handle Error: ", error) */
        // Handle Error Here
      }
    });
  }

  removeFavourite(favourite_id: string, work_id: string) {
    this.spinnerService.show();

    // Remove from back-end
    this.userService.deleteFavourite(favourite_id).pipe().subscribe({
      next: data => {
        this.SelectedFavouriteId.emit({ is_selected_fav: work_id });
        this.BookmarkUpdated.emit({ bookmark_updated: work_id });

        this.translate.get('Removed from favourites!').subscribe(res => {
          /* console.log(res); */
          this.toastr.success(res);
        });

        // Hide spinner
        this.spinnerService.hide();
      },
      error: error => {
        /* console.log("Handle Error: ", error); */
        // Handle Error Here
      }
    });
  }

  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  ngOnDestroy() {
    this.ModalCompleted.emit({ modal_update: true });
    this.triggerCloseModalEvent();
  }

  /*
  * Navigate to user's profile
  */
  openUserProfile() {
    this.triggerCloseModalEvent();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/profile/' + this.workofart_item.owner_id._id]);
  }

  fullscreen() {
    window.open('/preview-full-image/' + this.workofart_item._id, '_blank').focus();
  }
}
