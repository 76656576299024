import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-edit-exhibition',
  templateUrl: './edit-exhibition.component.html',
  styleUrls: ['./edit-exhibition.component.css']
})
export class EditExhibitionComponent implements OnInit {
  public exhibition_obj: any = [];
  public isForEdit: boolean = true;

  constructor(
    public authService: AuthService,
    private userService: UserService,
    public toastr: ToastrService,
    private spinnerService: NgxSpinnerService,
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const exhibition_id = params.get("id");
      
      this.spinnerService.show();
      this.userService.getSpecificExhibition(exhibition_id).pipe().subscribe({
        next: data => {
          this.exhibition_obj = {
            title: '',
            works: [],
            _id: data["_items"][0]._id
          };

          /* console.log(data); */
          // Hide spinner
          this.spinnerService.hide();
        },
        error: error => {
          /* console.log("Handle Error: ", error); */
          // Handle Error Here
        }
      });
    });
  }
}
