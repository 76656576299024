import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor(
    private authService: AuthService,
    private http: HttpClient
  ) { }

  /*
  * Create new work
  */
  createWork(work: any) {

    const token = this.authService.loadToken();

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': token
    });

    return this.http.post<any>(environment.apiUrl + '/works', work, { headers: headers }).pipe();
  }

  /*
  * Update specific work pic
  */
  updatePhotoWork(imgURL: any, _id: string) {

    const token = this.authService.loadToken();

    const workPhoto = this.dataURItoBlob(imgURL);

    const reqFormData = new FormData();
    reqFormData.append('pic', workPhoto);

    let headers = new HttpHeaders({
      'Authorization': token
    });

    return this.http.patch<AuthService>(environment.apiUrl + '/works/' + _id, reqFormData, { headers: headers }).pipe(
      map(resp => {
        return resp;
      })
    );
  }

  /*
  * Convert data URI to blob - used in image upload
  */
  dataURItoBlob(dataURI: any) {
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
  }
}