import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SignupComponent } from '../signup/signup.component';
import { ForgotpasswordComponent } from '../forgotpassword/forgotpassword.component';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  artist: any = {
    email: '',
    password: ''
  };

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private router: Router,
    public modalService: BsModalService,
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
  }

  openModalForgot() {
    this.modalRef = this.modalService.show(ForgotpasswordComponent);
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      this.modalRef.hide();
    });
  }

  openModalSignup() {
    this.modalRef = this.modalService.show(SignupComponent);
    this.event.emit('CLOSE');

    this.modalRef.content.event.subscribe(res => {
      console.log(res)
      this.modalRef.hide();
    });
  }

  onLoginSubmit(userForm: NgForm) {
    this.authService.authenticateUser(this.artist)
      .pipe(first())
      .subscribe({
        next: data => {
          /* console.log(this.artist); */
          /* console.log(data); */
          /* console.log(data.artist); */
          this.translate.get('You are now logged in').subscribe(res => {
            /* console.log(res); */
            this.toastr.success(res);
          });
          this.authService.storeUserData(data.token, data.artist);

          this.authService.getUserRoleFromToken();

          /* console.log(this.authService.getUserRoleFromToken()); */

          if (this.authService.getUserRoleFromToken() === 'curator') {
            this.userService.getProfileCurator()
              .pipe()
              .subscribe({
                next: res => {
                  /* console.log("User profile: ", res); */
                  this.dataLayerService.addUser(res);

                  this.triggerCloseModalEvent();

                  if (res['role'] === 'curator') {
                    this.triggerCloseModalEvent();
                    this.router.navigate(['/profile-curator']);
                  }

                },
                error: error => {
                  if (error.status === 401) {
                    this.translate.get('Unauthorized').subscribe(res => {
                      /* console.log(res); */
                      this.toastr.error(res);
                    });
                  }
                }
              });
          }

          if (this.authService.getUserRoleFromToken() === 'artist') {
            // Get User Profile
            this.userService.getProfile()
              .pipe()
              .subscribe({
                next: res => {
                  /* console.log("User profile: ", res); */
                  this.dataLayerService.addUser(res);

                  this.triggerCloseModalEvent();

                  if (res['role'] === 'artist') {
                    this.triggerCloseModalEvent();
                    this.router.navigate(['/profile']);
                  }

                },
                error: error => {
                  if (error.status === 401) {
                    this.translate.get('Unauthorized').subscribe(res => {
                      /* console.log(res); */
                      this.toastr.error(res);
                    });
                  }
                }
              });
          }

        },
        error: error => {
          if (error.status === 401) {
            this.translate.get('Invalid email address or password').subscribe(res => {
              /* console.log(res); */
              this.toastr.error(res);
            });
          }
          else {
            this.translate.get('Something went wrong. Please try again.').subscribe(res => {
              /* console.log(res); */
              this.toastr.error(res);
            });
          }
        }
      });
  }

  /*
  * Triger close event to the parent component
  */
  triggerCloseModalEvent() {
    this.event.emit('CLOSE');
  }
}
