import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataLayerService } from '../services/data-layer.service';
import { UserService } from '../services/user.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-preview-full-image',
  templateUrl: './preview-full-image.component.html',
  styleUrls: ['./preview-full-image.component.css']
})
export class PreviewFullImageComponent implements OnInit {
  @Input('workofart_item') public workofart_item: any = [];
  @Input('workofart_items') public workofart_items: any = [];
  @Input('isBookmarkVisible') public isBookmarkVisible: boolean = true;
  @Input('isRatethisVisible') public isRatethisVisible: boolean = true;
  @Input('isRatebuttonVisible') public isRatebuttonVisible: boolean = true;
  @Input('isVerticallineVisible') public isVerticallineVisible: boolean = true;
  @Input('isAvgRatingVisible') public isAvgRatingVisible: boolean = true;
  @Input('isStarRatingVisible') public isStarRatingVisible: boolean = true;
  @Input('isBookMarkChecked') public isBookMarkChecked: boolean = false;

  @Output('ModalUpdated') public ModalUpdated = new EventEmitter<{ modal_updated: boolean }>();

  public event: EventEmitter<any> = new EventEmitter();

  modalRef?: BsModalRef;
  bsModalRef: BsModalRef;

  firstname: string = "";
  lastname: string = "";
  work_user_id: string = "";
  work_id: string = "";

  user_id: string = "";

  work: any = {
    pic: "",
    title: '',
    description: '',
    password: '',
    tags: ["", "", "", "", ""],
    status: ''
  };

  public imageUrl: string;

  public subscription: Subscription;
  public user_info: any = [];
  public authenticated_user: boolean = false;

  constructor(
    private dataLayerService: DataLayerService,
    private userService: UserService,
    public activatedRoute: ActivatedRoute,
    public translate: TranslateService,
    public modalService: BsModalService,
    private spinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      const workID = params.get("id");
      this.work_id = workID;

      this.subscription = this.dataLayerService.currentUser.subscribe(
        user => {
          if (user.length > 0) {
            this.authenticated_user = true;

            this.spinnerService.show();

            this.userService.getSpecificWork(this.work_id).pipe().subscribe({
              next: data => {
                this.work_user_id = data["_items"][0].owner_id;
                this.firstname = data["_items"][0].owner_id.firstname;
                this.lastname = data["_items"][0].owner_id.lastname;

                this.imageUrl = 'data:image/gif;base64,' + data["_items"][0].thumbnail;

                const workIDVR = data["_items"][0]._id;
                this.work_id = workIDVR;

                /* console.log(data); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

          }

          else {
            this.authenticated_user = false;

            this.spinnerService.show();

            this.userService.getSpecificWork(this.work_id).pipe().subscribe({
              next: data => {
                this.work_user_id = data["_items"][0].owner_id;
                this.firstname = data["_items"][0].owner_id.firstname;
                this.lastname = data["_items"][0].owner_id.lastname;

                this.imageUrl = 'data:image/gif;base64,' + data["_items"][0].thumbnail;

                const workIDVR = data["_items"][0]._id;
                this.work_id = workIDVR;

                /* console.log(data); */

                // Hide spinner
                this.spinnerService.hide();
              },
              error: error => {
                /* console.log("Handle Error: ", error); */
                // Handle Error Here
              }
            });

          }
        });
    });
  }

  OnBack() {
    window.close();
  }
}
